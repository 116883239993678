// @flow
import { firebaseApp } from 'src/config/firebase'
import userModel from '../Models/UserModel'

const _getRecordsNumFromStudy = (data) => {
  return new Promise((resolve) => {
    firebaseApp
      .database()
      .ref(`/records/${data.userId}/${data.databaseId}/${data.studyId}`)
      .once('value')
      .then((snapshot) => {
        resolve(snapshot.numChildren())
      })
  })
}

export const getRecordsNumFromStudy = (studyId: string): Promise<> => {
  return _getRecordsNumFromStudy({
    userId: userModel.userId,
    databaseId: userModel.databaseId,
    studyId,
  })
}

export const getDataFromShareId = (shareId: string): Promise<> => {
  return new Promise((resolve) => {
    firebaseApp
      .database()
      .ref(`/shareStudies/${userModel.organisationId}/${shareId}`)
      .once('value')
      .then((snapshot) => {
        resolve(snapshot.val())
      })
  })
}

export const getRecordsNumFromShareId = async (shareId: string): Promise<> => {
  const data = await getDataFromShareId(shareId)

  return _getRecordsNumFromStudy({
    userId: data.userId,
    databaseId: data.databaseId,
    studyId: data.study,
  })
}

export const getStudiesFromCollection = (collectionId: string): Promise<> => {
  return new Promise((resolve) => {
    firebaseApp
      .database()
      .ref(`/studies/${userModel.userId}/${userModel.databaseId}`)
      .once('value')
      .then((snapshot) => {
        const studies = []

        snapshot.forEach((childSnapshot) => {
          const childData = childSnapshot.val()
          const collection = childData.belongsToCollection

          if (collection && collection === collectionId) {
            studies.push(childSnapshot.key)
          }
        })

        resolve(studies)
      })
  })
}

export const getRecordsNumFromCollection = async (
  collectionId: string
): Promise<> => {
  const studies = await getStudiesFromCollection(collectionId)
  let result = 0

  const getRecordsNumFromStudyAndAddToResult = async (studyId) => {
    result += await getRecordsNumFromStudy(studyId)
  }

  const promises = studies.map(getRecordsNumFromStudyAndAddToResult)
  return Promise.all(promises).then(() => result)
}

export const deleteStudy = (studyId: string) => {
  const promises = [
    firebaseApp
      .database()
      .ref(`/variables/${userModel.userId}/${userModel.databaseId}/${studyId}`)
      .remove(),
    firebaseApp
      .database()
      .ref(`/batches/${userModel.userId}/${userModel.databaseId}/${studyId}`)
      .remove(),
    firebaseApp
      .database()
      .ref(
        `/batchRecords/${userModel.userId}/${userModel.databaseId}/${studyId}`
      )
      .remove(),
    firebaseApp
      .database()
      .ref(
        `/batchVariables/${userModel.userId}/${userModel.databaseId}/${studyId}`
      )
      .remove(),
    firebaseApp
      .database()
      .ref(`/records/${userModel.userId}/${userModel.databaseId}/${studyId}`)
      .remove(),
    firebaseApp
      .database()
      .ref(`/studies/${userModel.userId}/${userModel.databaseId}/${studyId}`)
      .remove(),
  ]

  return Promise.all(promises)
}

export const deleteStudies = (studies: string[]) => {
  const promises = studies.map(deleteStudy)
  return Promise.all(promises)
}

export const deleteCollectionSafe = (collectionId: string) => {
  return new Promise((resolve) => {
    firebaseApp
      .database()
      .ref(
        `/collections/${userModel.userId}/${userModel.databaseId}/${collectionId}`
      )
      .remove()
      .then(resolve)
  })
}

export const deleteCollectionsSafe = (collections: string[]) => {
  const promises = collections.map(deleteCollectionSafe)
  return Promise.all(promises)
}

export const deleteCollectionWithAllStudies = async (collectionId: string) => {
  const studies = await getStudiesFromCollection(collectionId)
  await deleteStudies(studies)
  await deleteCollectionSafe(collectionId)
}

export const deleteCollectionsWithAllData = (collections: string[]) => {
  const promises = collections.map(deleteCollectionWithAllStudies)
  return Promise.all(promises)
}

export const moveSelectedStudiesFromCollection = (studies: string[]) => {
  const reference = firebaseApp
    .database()
    .ref(`/studies/${userModel.userId}/${userModel.databaseId}`)
  const promises = studies.map((studyId) =>
    reference.child(`${studyId}/belongsToCollection`).remove()
  )
  return Promise.all(promises)
}

export const moveStudiesFromCollection = async (collectionId: string) => {
  const studies = await getStudiesFromCollection(collectionId)
  return moveSelectedStudiesFromCollection(studies)
}

export const moveStudiesFromCollectionAndDelete = async (
  collectionId: string
) => {
  await moveStudiesFromCollection(collectionId)
  await deleteCollectionSafe(collectionId)
}

export const moveStudiesFromCollectionsAndDelete = (collections: string[]) => {
  const promises = collections.map(moveStudiesFromCollectionAndDelete)
  return Promise.all(promises)
}
