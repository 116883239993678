import { observable, transaction, computed } from 'mobx'
import { firebaseApp } from 'src/config/firebase'

import userModel from '../UserModel'

class AccountModel {
  @observable _firstName = false
  @observable _lastName = false
  @observable _loaded = false

  @computed get isLoaded() {
    return this._loaded
  }

  @computed get firstNameGet() {
    return this._firstName
  }

  @computed get lastNameGet() {
    return this._lastName
  }

  nameSet(fn, ln) {
    transaction(() => {
      this._firstName = fn
      this._lastName = ln
    })
  }

  update() {
    this._loaded = false

    return firebaseApp
      .database()
      .ref('/users/' + userModel.userId)
      .once('value')
      .then((snapshot) => {
        let val = snapshot.val()

        transaction(() => {
          this._firstName = val.firstName
          this._lastName = val.lastName
          this._loaded = true
        })
      })
  }
}

export default AccountModel
