import React, { Component } from 'react'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { observer } from 'mobx-react'
import Divider from '@material-ui/core/Divider'
import Dialog from '@material-ui/core/Dialog'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import { firebaseApp } from 'src/config/firebase'
import { Button as SemanticButton, Ref } from 'semantic-ui-react'

import CollectionListModel from '../../../Models/Collection/ListModel'
import userModel from '../../../Models/UserModel'
import { DialogTitle, DialogActions, DialogContent } from '@material-ui/core'

@observer
class SelectedComponent extends Component {
  constructor(props) {
    super(props)

    this.state = {
      openCollectionMenu: false,
      errorText: '',
      open: false,
      value: '',
    }

    this.addToCollectionRef = React.createRef()
  }

  addToCollection = (collectionId) => {
    let updates = {}

    this.props.selectedItems.forEach(function (value) {
      updates[value + '/belongsToCollection'] = collectionId
    })

    firebaseApp
      .database()
      .ref('/studies/' + userModel.userId + '/' + userModel.databaseId)
      .update(updates)

    CollectionListModel.update()
    this.props.listModel.update()
  }

  handleOpen = () => {
    this.setState({ open: true })
  }

  handleClose = () => {
    this.setState({
      errorText: '',
      open: false,
      value: '',
    })
  }

  handleChange = (event) => {
    this.setState({
      errorText: '',
      value: event.target.value,
    })
  }

  handleAdd = (collectionId) => {
    this.addToCollection(collectionId)
    this.handleClose()
  }

  handleCreate = () => {
    if (!this.state.value.length) {
      this.setState({
        errorText: 'You should enter this field',
      })
      return
    }

    let newCollectionRef = firebaseApp
      .database()
      .ref('/collections/' + userModel.userId + '/' + userModel.databaseId)
      .push()
    newCollectionRef
      .set({
        dateCreate: new Date().getTime(),
        name: this.state.value,
      })
      .then(() => {
        this.addToCollection(newCollectionRef.key)
      })

    this.handleClose()
  }

  render() {
    let collections = [
      <MenuItem
        key="new"
        onClick={() => {
          this.handleOpen()
        }}
      >
        New Collection
      </MenuItem>,
      <Divider key="divider" />,
    ]
    Object.keys(CollectionListModel.collections).forEach((key) => {
      const item = CollectionListModel.collections[key]
      collections.push(
        <MenuItem
          key={item.key}
          onClick={() => {
            this.handleAdd(item.key)
          }}
        >
          {item.name}
        </MenuItem>
      )
    })

    return (
      <div style={{ display: 'inline' }}>
        <Ref innerRef={this.addToCollectionRef}>
          <SemanticButton
            inverted
            onClick={() => this.setState({ openCollectionMenu: true })}
          >
            Add to Collection
          </SemanticButton>
        </Ref>

        <Menu
          open={this.state.openCollectionMenu}
          anchorEl={this.addToCollectionRef?.current || null}
          onClose={() => this.setState({ openCollectionMenu: false })}
        >
          {collections}
        </Menu>
        <Dialog open={this.state.open} onClose={this.handleClose}>
          <DialogTitle>Enter name of new collection</DialogTitle>
          <DialogContent>
            <TextField
              errorText={this.state.errorText}
              hintText="Collection name"
              onChange={this.handleChange}
              value={this.state.value}
            />
          </DialogContent>

          <DialogActions>
            <Button color="primary" onClick={this.handleClose}>
              Cancel
            </Button>
            <Button color="primary" onClick={this.handleCreate}>
              Create
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

export default SelectedComponent
