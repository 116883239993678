import React, { Component } from 'react'
import { observer } from 'mobx-react'
import OrganizationComponent from '../OrganizationComponent'
import CircularProgress from '@material-ui/core/CircularProgress'
import UserModel from '../../../Models/UserModel'
import OrganizationUserModel from '../../../Models/Organization/OrganizationUserModel'
import { firebaseApp } from 'src/config/firebase'

export default
@observer
class EditComponent extends Component {
  handleSave() {
    return firebaseApp
      .database()
      .ref('/organisations/' + UserModel.organisationId)
      .update(this._data)
  }

  render() {
    if (!OrganizationUserModel.isLoaded) return <CircularProgress />

    return (
      <div>
        <OrganizationComponent handleSave={this.handleSave} isEdit={true} />
      </div>
    )
  }
}
