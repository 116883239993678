/**
 * Transaction type for the creating manual transactions.
 */
class TransactionType {
  manualCC = {
    id: 0,
    title: 'Manual CC'
  };

  check = {
    id: 1,
    title: 'Check'
  };

  getAll() {
    return [
      'manualCC',
      'check'
    ];
  }

  getTypeById(id)
  {
    switch(id)
    {
      case 0:
        return 'manualCC';
      case 1:
        return 'check';
      default:
        return 'manualCC';
    }
  }

  getTitleById(id)
  {
    const type = this.getTypeById(id);
    return this[type].title;
  }
}

export default new TransactionType();