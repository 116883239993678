import React, { Component } from 'react'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { observer } from 'mobx-react'
import { Link } from 'react-router-dom'
import { history } from 'src/config/routing/history'
import role from '../../config/role'
import CircularProgress from '@material-ui/core/CircularProgress'
import * as Promise from 'promise/src/es6-extensions'
import ReCAPTCHA from 'react-google-recaptcha'
import AuthModel from 'src/Models/AuthModel'
import { firebaseApp } from 'src/config/firebase'

/**
 * User registration component.
 * Used to register new users in system.
 */
@observer
class RegisterComponent extends Component {
  constructor(props) {
    super(props)

    this.submitStateDefault = {
      label: 'Register',
      disabled: false,
    }

    this.state = {
      organisationName: {
        value: '',
        error: '',
      },
      organisationAddress: {
        value: '',
        error: '',
      },
      email: {
        value: '',
        error: '',
      },
      password: {
        value: '',
        error: '',
      },
      retypePassword: {
        value: '',
        error: '',
      },
      submit: this.submitStateDefault,
    }

    this.captcha = null
  }

  /**
   * Handles organisation name input change.
   *
   * @param event
   */
  handleOrganisationNameChange = (event) => {
    this.setState({
      organisationName: this.emptyCheck(
        event.target.value,
        'Organization name is required'
      ),
    })
  }

  /**
   * Handles organisation address input change.
   *
   * @param event
   */
  handleOrganisationAddressChange = (event) => {
    this.setState({
      organisationAddress: this.emptyCheck(
        event.target.value,
        'Organization address is required'
      ),
    })
  }

  /**
   * Handles email input change.
   *
   * @param event
   */
  handleEmailChange = (event) => {
    this.setState({
      email: this.emptyCheck(event.target.value, 'Email is required'),
    })
  }

  /**
   * Handles password input change.
   *
   * @param event
   */
  handlePasswordChange = (event) => {
    this.setState({
      password: this.emptyCheck(event.target.value, 'Password is required'),
    })
  }

  /**
   * Handles retype password input change.
   *
   * @param event
   */
  handleRetypePasswordChange = (event) => {
    this.setState({
      retypePassword: this.emptyCheck(
        event.target.value,
        'Password is required'
      ),
    })
  }

  /**
   * Check given value for emptiness.
   *
   * @param {String} value Value to check.
   * @param {string} [errorText] Error text to override default value.
   * @returns {{error: string, value: *}} Object to use in state.
   */
  emptyCheck(value, errorText) {
    if (typeof errorText === 'undefined') errorText = 'This field is required'

    const state = {
      error: '',
      value: value,
    }

    if (!value.length) state.error = errorText

    return state
  }

  /**
   * Handles credentials form submit.
   * Sends request to create new user in firebaseApp.
   *
   * @param event
   */
  handleSubmit = (event) => {
    const isPromo = this.props.location.pathname.includes('/register-promo')

    event.preventDefault()

    this.captcha.execute()

    this.setState({
      submit: {
        label: <CircularProgress size={30} color="#fff" />,
        disabled: true,
      },
    })

    if (this.state.password.value !== this.state.retypePassword.value) {
      const state = this.emptyCheck('', "Passwords doesn't match")

      this.setState({
        password: state,
        retypePassword: state,
      })
    }

    this.setState({
      email: this.emptyCheck(this.state.email.value),
      password: this.emptyCheck(this.state.password.value),
      retypePassword: this.emptyCheck(this.state.retypePassword.value),
      organisationName: this.emptyCheck(this.state.organisationName.value),
      organisationAddress: this.emptyCheck(
        this.state.organisationAddress.value
      ),
    })

    const email = this.state.email.value
    const password = this.state.password.value

    const unsubscribe = AuthModel.onAuthStateChanged((user) => {
      if (user !== null && !user.emailVerified) {
        user.sendEmailVerification()

        const signUpPromises = []

        signUpPromises.push(
          firebaseApp.database().ref('/users').child(user.uid).set({
            devices: '',
            email: email,
            role: role.owner.id,
            organisation: user.uid,
          })
        )

        signUpPromises.push(
          firebaseApp
            .database()
            .ref('organisations')
            .child(user.uid)
            .set({
              name: this.state.organisationName.value,
              address: this.state.organisationAddress.value,
              users: {
                [user.uid]: true,
              },
            })
        )

        Promise.all(signUpPromises).then(() => {
          AuthModel.signOut().then(() => {
            // this.firebaseApp.delete()
            history.push(
              isPromo ? '/register-promo/complete' : '/register/complete'
            )
          })
        })

        unsubscribe()
      }
    })

    AuthModel.signUp(email, password, firebaseApp).catch((error) => {
      this.setState({
        submit: this.submitStateDefault,
      })

      if (!!error.code) {
        if (error.code.indexOf('email') !== -1) {
          this.setState({
            email: {
              error: error.message,
              value: this.state.email.value,
            },
          })
        } else {
          this.setState({
            password: {
              error: error.message,
              value: this.state.password.value,
            },
          })
        }
      }
    })
  }

  onChangeCaptcha() {
    // Does nothing.
  }

  render() {
    const isPromo = this.props.location.pathname.includes('/register-promo')
    if (isPromo) document.body.style.backgroundColor = '#f7feff'

    const divStyle = {
      width: '300px',
      height: '670px',

      position: 'absolute',
      top: '0',
      bottom: '0',
      left: '0',
      right: '0',

      margin: 'auto',
    }

    const inputStyle = {
      margin: '8px 0',
    }

    const logoStyle = {
      position: 'relative',
      left: '-50px',
    }

    return (
      <form onSubmit={this.handleSubmit}>
        <ReCAPTCHA
          ref={(el) => {
            this.captcha = el
          }}
          size="invisible"
          sitekey="6LctqCEUAAAAAKSGGL3JMzlLNevEbK4wyv4IcG8N"
          onChange={this.onChangeCaptcha}
        />
        <div style={divStyle}>
          <img
            style={logoStyle}
            src="logo.png"
            width="400"
            height="244"
            alt="Logo"
          />
          <div>
            <TextField
              fullWidth
              label="Organization name"
              error={!!this.state.organisationName.error}
              helperText={this.state.organisationName.error}
              onBlur={this.handleOrganisationNameChange}
              onChange={this.handleOrganisationNameChange}
              defaultValue={this.state.organisationName.value}
              style={inputStyle}
            />
          </div>
          <div>
            <TextField
              fullWidth
              label="Organization address"
              error={!!this.state.organisationAddress.error}
              helperText={this.state.organisationAddress.error}
              onBlur={this.handleOrganisationAddressChange}
              onChange={this.handleOrganisationAddressChange}
              defaultValue={this.state.organisationAddress.value}
              style={inputStyle}
            />
          </div>
          <div>
            <TextField
              fullWidth
              label="Email"
              error={!!this.state.email.error}
              helperText={this.state.email.error}
              onBlur={this.handleEmailChange}
              onChange={this.handleEmailChange}
              defaultValue={this.state.email.value}
              style={inputStyle}
            />
          </div>
          <div>
            <TextField
              fullWidth
              type="password"
              label="Password"
              error={!!this.state.password.error}
              onChange={this.handlePasswordChange}
              onBlur={this.handlePasswordChange}
              defaultValue={this.state.password.value}
              helperText={this.state.password.error}
              style={inputStyle}
            />
          </div>
          <div>
            <TextField
              fullWidth
              type="password"
              label="Re-type Password"
              error={!!this.state.retypePassword.error}
              onChange={this.handleRetypePasswordChange}
              onBlur={this.handleRetypePasswordChange}
              defaultValue={this.state.retypePassword.value}
              helperText={this.state.retypePassword.error}
              style={inputStyle}
            />
          </div>
          <div>
            <Button
              fullWidth
              size="large"
              color="primary"
              variant="contained"
              disabled={this.state.submit.disabled}
              type="submit"
              className="button-submit"
              style={inputStyle}
            >
              {this.state.submit.label}
            </Button>
          </div>
          <div style={{ paddingTop: '10px', textAlign: 'center' }}>
            {isPromo || <Link to="/login">Login</Link>}
          </div>
        </div>
      </form>
    )
  }
}

export default RegisterComponent
