import React from 'react'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import { Label } from 'semantic-ui-react'
import Checkbox from '@material-ui/core/Checkbox'
import RecordsNumFeature from '../RecordsNum'
import StudyShareButtons from '../StudyShareButtons/index'
import UserModel from 'src/Models/UserModel'
import { Tooltip } from '@material-ui/core'

const isNotSync = (n) => {
  const isUserSync = UserModel.isSync
  return !isUserSync || (n.hasOwnProperty('syncable') && !n.syncable)
}

const getStyles = (n) => {
  const styles = {}
  if (isNotSync(n)) {
    styles.background = '#cccccc'
    styles.color = '#cccccc'
  }
  return styles
}

const getTooltip = (n) => {
  if (n.version !== 2) {
    return 'To open this study, update your copy of DCSLinkstream to 1.9 or later.'
  }
  return isNotSync(n)
    ? 'LinkStream Sync is disabled. Please go back to the Study List on the Data page and Enable LinkStream Sync switch in order to see your data'
    : ''
}

const StudyRow = (props) => (
  <Tooltip title={getTooltip(props.n)} style={{ fontSize: 16 }}>
    <TableRow
      onClick={(event) => props.handleClick(event, props.n.key)}
      onKeyDown={(event) => props.handleKeyDown(event, props.n.key)}
      role="checkbox"
      aria-checked={props.isSelected}
      tabIndex={-1}
      key={props.n.key}
      selected={props.isSelected}
      style={getStyles(props.n)}
    >
      <TableCell padding="checkbox">
        <Checkbox checked={props.isSelected} />
      </TableCell>
      <TableCell
        onClick={() => {
          if (props.n.version === 2) {
            props.handleClickCell(props.n.key)
          }
        }}
      >
        {props.n.name}
        {props.n.version !== 2 && (
          <Label style={{ marginLeft: 8 }} basic color="red">
            Requires update
          </Label>
        )}
      </TableCell>
      <TableCell>
        <RecordsNumFeature studyId={props.n.key} />
      </TableCell>
      {props.canShare && (
        <TableCell>
          <StudyShareButtons study={props} />
        </TableCell>
      )}
    </TableRow>
  </Tooltip>
)

export default StudyRow
