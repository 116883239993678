import React, { Component } from 'react'
import { observer } from 'mobx-react'
import Button from '@material-ui/core/Button'
import CollectionListModel from '../../../Models/Collection/ListModel'
import { moveSelectedStudiesFromCollection } from '../../helpers'

@observer
class SelectedRemoveComponent extends Component {
  handleOpen = () => {
    moveSelectedStudiesFromCollection(this.props.selectedItems).then(() => {
      CollectionListModel.update()
      this.props.listModel.update()
    })
  }

  render() {
    return (
      <div style={{ display: 'inline' }}>
        <Button
          inverted
          onClick={() => {
            this.handleOpen()
          }}
        >
          Remove from collection
        </Button>
      </div>
    )
  }
}

export default SelectedRemoveComponent
