// @flow
import {observable, computed, transaction} from 'mobx';
import AbstractStore from "../AbstractStore";
import { getRecordsNumFromShareId } from '../helpers';

class RecordsNumStudyShareStore extends AbstractStore
{
  @observable _recordsNum : number = 0;
  _shareId : string;

  constructor(shareId : string) {
    super();
    this._shareId = shareId;
  }

  @computed
  get recordsNum() : number {
    return this._recordsNum;
  }

  async update() {
    this.isLoading = true;

    const num = await getRecordsNumFromShareId(this._shareId);

    transaction(() => {
      this.isLoading = false;
      this._recordsNum = num;
    });
  }
}

export default RecordsNumStudyShareStore;
