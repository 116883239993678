/**
 * Component to display device details
 */
import React, { useEffect } from 'react'
import { Grid, Header } from 'semantic-ui-react'
import CircularProgress from '@material-ui/core/CircularProgress'
import DevicesListModel from 'src/Models/Device/ListModel'
import InfoComponent from '../Payment/InfoComponent'
import UserModel from 'src/Models/UserModel'
import { observer } from 'mobx-react'
import { Dimmer, Loader } from 'semantic-ui-react'
import SubscriptionComponent from './Subscription/SubscriptionComponent'
import HistoryComponent from '../Payment/HistoryComponent'
import DeviceInfo from '../Admin/User/Edit/Device/DeviceInfo'

export const DeviceDetailsComponent = observer(({ match }) => {
  useEffect(() => {
    DevicesListModel.update()
  }, [])

  if (!DevicesListModel.isLoaded) return <CircularProgress />

  const { device } = match.params

  const deviceData = DevicesListModel.getDevice(device)

  let subscription = null
  if (deviceData && deviceData.hasOwnProperty('subscriptionId')) {
    subscription = UserModel.stripeCustomer.getSubscriptionById(
      deviceData.subscriptionId
    )
  }

  if (deviceData && subscription === null) {
    const deviceInfo = new DeviceInfo(deviceData.activationCode, deviceData.id)

    if (
      deviceInfo.until_activated &&
      deviceInfo.until_activated > new Date().getTime()
    ) {
      subscription = {
        current_period_end: deviceInfo.until_activated / 1000,
        status: 'Active',
        plan: {
          interval: 'Manual',
        },
      }
    }
  }

  return (
    <div style={{ margin: '20px' }}>
      <Grid columns={2}>
        <Grid.Column>
          <Header as="h1">DCSLinkStream License Summary</Header>
          <InfoComponent subscription={subscription} />
        </Grid.Column>
        <Grid.Column>
          <Header as="h1">DCSLinkStream License Plans</Header>
          <SubscriptionComponent
            deviceId={device}
            subscription={subscription}
          />
        </Grid.Column>
      </Grid>
      <div style={{ marginTop: '20px' }}>
        <HistoryComponent subscription={subscription} disallowManual={true} />
      </div>

      <Dimmer active={!UserModel.stripeCustomer.isLoaded} page>
        <Loader>Updating subscription data.</Loader>
      </Dimmer>
    </div>
  )
})
