/**
 * @flow
 *
 * Array of menu items for admin part of system.
 * @type Array.<MenuItemObject>
 */
const adminMenu = [
  {
    path: '/admin/users',
    text: 'Users'
  },
  {
    path: '/admin/org',
    text: 'Organizations'
  },
  {
    path: '/admin/promo',
    text: 'Promo-codes'
  },
  {
    path: '/logout',
    text: 'Log Out'
  }
];

export default adminMenu;
