import React, { Component } from 'react'
import ListComponent from 'src/Components/Licenses/ListComponent'

export default class LicensesComponent extends Component {
  render() {
    return (
      <div style={{ margin: '20px' }}>
        <ListComponent />
        {this.props.children}
      </div>
    )
  }
}
