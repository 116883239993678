import { observable, computed } from 'mobx'
import UserModel from '../../UserModel'
import { firebaseApp } from 'src/config/firebase'
import {
  getStripeCustomer,
  getCustomerSubscriptions,
} from 'src/services/stripe'

/**
 * Represents Stripe customer object.
 *
 * @see http://stripe.com
 */
class CustomerModel {
  /**
   * Whether data was loaded form Stripe.
   *
   * @type {boolean}
   * @private
   */
  @observable _loaded = false

  /**
   * Stripe subscriptions object for current customer.
   *
   * @type {{}|null}
   * @private
   * @see https://stripe.com/docs/api#subscription_object
   */
  _subscriptionList = null

  /**
   * Stripe customer data object.
   *
   * @type {{}|null}
   * @private
   * @see https://stripe.com/docs/api#customer_object
   */
  _customerData = null

  /**
   * Manual transaction for the current customer.
   *
   * @type {{}|null}
   * @private
   */
  _manualTransaction = null

  /**
   * Loads customer data from Stripe and manual transactions from the firebaseApp.
   *
   * @returns {!firebaseApp.Promise.<*>}
   */
  async loadData(stripeCustomerId = UserModel.stripeCustomerId) {
    this._loaded = false

    try {
      const snapshot = await firebaseApp
        .database()
        .ref('/users/' + UserModel.userId + '/transactions/')
        .once('value')

      const now = new Date().getTime()

      const transactionSnapshot = snapshot.val()
      for (const transactionId in transactionSnapshot) {
        if (!transactionSnapshot.hasOwnProperty(transactionId)) continue

        const childData = transactionSnapshot[transactionId]

        if (childData.startDate < now && childData.endDate > now)
          this._manualTransaction = childData
      }

      if (stripeCustomerId) {
        const { data: customer } = await getStripeCustomer(stripeCustomerId)
        const { data: subscriptions } = await getCustomerSubscriptions(
          stripeCustomerId
        )

        this._subscriptionList = subscriptions
        this._customerData = customer
      }
    } catch (err) {
      console.error(err)
      return Promise.reject(err.message)
    } finally {
      this._loaded = true
    }
  }

  /**
   * List of subscriptions for current customer.
   *
   * @returns {[{}]}
   * @see https://stripe.com/docs/api#subscription_object
   */
  get subscriptionList() {
    if (this._subscriptionList === null) return []
    else return this._subscriptionList.data
  }

  /**
   * Returns the current subscription.
   *
   * @returns {{}|null}
   */
  get currentSubscription() {
    const subscription = this.getSubscriptionById(UserModel.subscriptionId)

    if (subscription) return subscription

    if (this.hasManualTransaction) {
      const manual = this._manualTransaction

      return {
        current_period_end: manual.endDate / 1000,
        status: 'Active',
        plan: {
          interval: 'Manual',
        },
      }
    }

    return null
  }

  /**
   * Determines current customer has active account (with active subscription or manual payment).
   *
   * @returns {boolean}
   */
  get hasActiveAccount() {
    // TODO temporary promo
    return true
    // return this.hasManualTransaction || this.hasActiveSubscription;
  }

  /**
   * Whether current customer has any subscription in 'active' state.
   *
   * @returns {boolean}
   */
  get hasActiveSubscription() {
    if (
      UserModel.subscriptionId !== null &&
      this.subscriptionList.length !== 0
    ) {
      const subscription = this.getSubscriptionById(UserModel.subscriptionId)

      return subscription && subscription.status === 'active'
    }

    return false
  }

  getSubscriptionById(subscriptionId) {
    const subscription = this.subscriptionList.find((subscriptionData) => {
      return subscriptionData.id === subscriptionId
    })

    return subscription || null
  }

  /**
   * Determines current customer has active account with manual transaction.
   *
   * @returns {boolean}
   */
  get hasManualTransaction() {
    return !!this._manualTransaction
  }

  /**
   * Whether current subscription awaits cancellation at the end of period.
   *
   * @return {Boolean}
   */
  get cancelPending() {
    if (this.hasActiveSubscription)
      return this.currentSubscription['cancel_at_period_end']

    return false
  }

  /**
   * Resets loaded flag
   * This was added to reset _loaded flag before any acync API calls, to implement
   * dimmer overlay while data is loading. Regular data load is called after several API calls and it
   * freezes the page without any activity and cause bad customer experience.
   */
  reset() {
    this._loaded = false
  }

  /**
   * Current Stripe customer data.
   *
   * @returns {{}|null}
   * @see https://stripe.com/docs/api#customer_object
   */
  get info() {
    return this._customerData
  }

  /**
   * Whether data was loaded form Stripe.
   *
   * @returns {boolean}
   */
  @computed get isLoaded() {
    return this._loaded
  }
}

export default CustomerModel
