import React, { Component } from 'react';
import {Table, Button, Modal, Form, Label, Input} from 'semantic-ui-react'
import {observer} from 'mobx-react';

@observer
class ListComponent extends Component
{
  state = { open: false };

  show = () => this.setState({open: true });
  close = () => this.setState({ open: false });

  formData = {};

  render()
  {
    const {data,model} = this.props;

    this.formData = {
      code: data.code,
      discountAmount: data.discountAmount,
      discountPercent: data.discountPercent,
    };

    return (
      <Table.Row>
        <Table.Cell>
          {data.code}
        </Table.Cell>
        <Table.Cell>
          {data.discountAmount}
        </Table.Cell>
        <Table.Cell>
          {data.discountPercent}
        </Table.Cell>
        <Table.Cell>
          <Button onClick={this.show} color='blue'>Edit</Button>
          <Modal dimmer="inverted" open={this.state.open}>
            <Modal.Header>Edit promo</Modal.Header>
            <Modal.Content image>
              <Form>
                <Form.Field inline>
                  <input
                    type='text'
                    placeholder='Promocode'
                    defaultValue={data.code}
                    onChange={(event) => this.formData.code = event.target.value}
                  />
                  <Label pointing='left'>Promo-code</Label>
                </Form.Field>
                <Form.Field inline>
                  <Input
                    defaultValue={data.discountAmount}
                    label={{ basic: true, content: '$' }}
                    labelPosition='right'
                    placeholder='Discount'
                    onChange={(event) => this.formData.discountAmount = event.target.value}
                  />
                  <Label pointing='left'>Discount in $</Label>
                </Form.Field>
                <Form.Field inline>
                  <Input
                    defaultValue={data.discountPercent}
                    label={{ basic: true, content: '%' }}
                    labelPosition='right'
                    placeholder='Discount'
                    onChange={(event) => this.formData.discountPercent = event.target.value}
                  />
                  <Label pointing='left'>Discount in %</Label>
                </Form.Field>
              </Form>
            </Modal.Content>
            <Modal.Actions>
              <Button onClick={this.close}>Cancel</Button>
              <Button
                onClick={() => model.change(data.key,this.formData)}
                positive
                icon='save'
                labelPosition='right'
                content='Save'
              />
            </Modal.Actions>
          </Modal>
        </Table.Cell>
      </Table.Row>
    );
  }
}

export default ListComponent;