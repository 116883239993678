import React, { Component } from 'react'
import { Step } from 'semantic-ui-react'
import dateFormat from 'dateformat'
import { observer } from 'mobx-react'

/**
 * Displays information about current subscription.
 */

export default
@observer
class InfoComponent extends Component {
  render() {
    const currentSubscription = this.props.subscription
    let statusText = <div>Purchase a plan to Activate your Account</div>

    let subscription = {
      status: {
        text: 'Inactive',
        icon: 'ban',
      },
      package: {
        text: 'Not available',
        icon: 'briefcase',
      },
      period: {
        text: 'Not available',
        icon: 'delete calendar',
      },
    }

    if (currentSubscription !== null) {
      let periodText = dateFormat(
        new Date(currentSubscription['current_period_end'] * 1000),
        'dd mmmm yyyy'
      )
      statusText = ''

      if (currentSubscription['cancel_at_period_end']) {
        periodText += ' (Will be cancelled)'
      }

      subscription = {
        status: {
          text: currentSubscription.status,
          icon: 'checkmark',
        },
        package: {
          text: currentSubscription.plan.interval,
          icon: 'briefcase',
        },
        period: {
          text: periodText,
          icon: 'checked calendar',
        },
      }
    }

    let boxes = [
      <Step
        key="status"
        style={{ textTransform: 'capitalize' }}
        icon={subscription.status.icon}
        title={subscription.status.text}
        description={
          <div>
            <div>Status</div>
            {statusText}
          </div>
        }
      />,
    ]

    if (currentSubscription !== null) {
      boxes.push(
        <Step
          key="package"
          style={{ textTransform: 'capitalize' }}
          icon={subscription.package.icon}
          title={subscription.package.text}
          description="Current Plan"
        />
      )
      boxes.push(
        <Step
          key="period"
          style={{ textTransform: 'capitalize' }}
          icon={subscription.period.icon}
          title={subscription.period.text}
          description="Plan Expiration"
        />
      )
    }

    return (
      <div>
        <Step.Group fluid vertical>
          {boxes}
        </Step.Group>
      </div>
    )
  }
}
