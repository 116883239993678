import React, { Component } from 'react'
import { Button, Card, Image, Header } from 'semantic-ui-react'
import { observer } from 'mobx-react'
import CircularProgress from '@material-ui/core/CircularProgress'
import DevicesListModel from '../../../Models/Device/ListModel'
import { useHistory } from 'react-router'

const deviceImage = {
  ANDROID: 'D10.png',
  MF1: '10MF1.png',
  MF2: '10MF2.png',
  DCS: 'DCS.png',
  DCSP: 'DCS.png',
}

const deviceName = {
  ANDROID: 'D10',
  MF1: '10MF1',
  MF2: '10MF2',
  DCS: 'DCS',
  DCSP: 'DCS.PIT',
}

function CardComponent({ device }) {
  const image = deviceImage[device.type] || 'D10.png'
  const name = deviceName[device.type] || 'D10'
  const history = useHistory()

  return (
    <Card>
      <Card.Content>
        <Image src={'device/' + image} />
        <Card.Header>{name}</Card.Header>
        <Card.Meta>ID: {device.id}</Card.Meta>
        <Card.Description>Database: {device.databaseId}</Card.Description>
      </Card.Content>
      <Card.Content extra>
        <div className="ui two buttons">
          <Button
            basic
            color="blue"
            onClick={() => {
              if (device.type === 'ANDROID') {
                history.push('/devices/' + device.key)
              }
            }}
          >
            Detail
          </Button>
          <Button basic color="red">
            Remove
          </Button>
        </div>
      </Card.Content>
    </Card>
  )
}

export default
@observer
class ListComponent extends Component {
  componentDidMount() {
    DevicesListModel.update()
  }

  render() {
    if (!DevicesListModel.isLoaded) return <CircularProgress />

    const deviceList = DevicesListModel.devices

    const boardList = []

    boardList.push({
      databaseId: 'test database 1',
      id: '0123456789987654321',
      key: 'MF1',
      type: 'MF1',
    })

    boardList.push({
      databaseId: 'test database 2',
      id: '0123456789987654321',
      key: 'MF2',
      type: 'MF2',
    })

    boardList.push({
      databaseId: 'test database 3',
      id: '0123456789987654321',
      key: 'DCS',
      type: 'DCS',
    })

    boardList.push({
      databaseId: 'test database 4',
      id: '0123456789987654321',
      key: 'DCSP',
      type: 'DCSP',
    })

    const devices = deviceList.map((item) => {
      return <CardComponent device={item} key={item.key} />
    })

    const boards = boardList.map((item) => {
      return <CardComponent device={item} key={item.key} />
    })

    return (
      <div style={{ margin: 24 + 'px' }}>
        {devices.length > 0 && <Header as="h1">Devices</Header>}
        <Card.Group>{devices}</Card.Group>
        <Header as="h1">Boards</Header>
        <Card.Group>{boards}</Card.Group>
      </div>
    )
  }
}
