import React, { Component } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import { observer } from 'mobx-react'
import InvitationModel from 'src/Models/Account/InvitationModel'
import { firebaseApp } from 'src/config/firebase'
import RoleSelectComponent from './RoleSelectComponent'
import Snackbar from '@material-ui/core/Snackbar'
import { Confirm } from 'semantic-ui-react'

import role from 'src/config/role'
import Button from '@material-ui/core/Button'
import UserModel from 'src/Models/UserModel'

class RowComponent extends Component {
  state = { open: false }

  show = () => this.setState({ open: true })

  handleConfirm = () => {
    this.setState({ open: false })
    this.props.handleRemove(this.props.item.key)
  }

  handleCancel = () => this.setState({ open: false })

  render() {
    const { item, handleRoleChange } = this.props

    let removeButton = ''
    let roleSelect = role.getTitleById(item.role)
    if (item.email !== UserModel.userEmail) {
      if ((item.role !== 0 && item.role !== 1) || UserModel.isOwner) {
        removeButton = (
          <Button color="secondary" onClick={this.show}>
            Remove user from organization
          </Button>
        )

        roleSelect = (
          <RoleSelectComponent
            id={item.key}
            value={item.role}
            onChange={handleRoleChange}
          />
        )
      }
    }

    return (
      <TableRow hover>
        <TableCell style={{ width: '250px' }}>{roleSelect}</TableCell>
        <TableCell>{item.email}</TableCell>
        <TableCell>{removeButton}</TableCell>
        <Confirm
          open={this.state.open}
          onCancel={this.handleCancel}
          onConfirm={this.handleConfirm}
          confirmButton="Remove"
          content={`Are you sure to remove ${item.email} from organization?`}
        />
      </TableRow>
    )
  }
}

/**
 * Displays invited users.
 */
@observer
class ListComponent extends Component {
  constructor(props) {
    super(props)

    this.state = {
      snackbar: {
        open: false,
        message: '',
      },
    }

    this.listModel = new InvitationModel()
    this.handleRoleChange = this.handleRoleChange.bind(this)
    this.handleRemove = this.handleRemove.bind(this)
  }

  componentDidMount() {
    this.listModel.update()
  }

  /**
   * Closes modal dialog for adding new invitation.
   */
  handleSnackbarClose = () => {
    this.setState({
      snackbar: {
        open: false,
        message: '',
      },
    })
  }

  handleRemove = (uid) => {
    this.listModel.remove(uid)
  }

  /**
   * Handles user role change.
   *
   * @param id User ID to whom role change is applied.
   * @param event Event.
   * @param key The index of the selected menu item.
   * @param value The value of the selected menu item.
   */
  handleRoleChange(id, value) {
    const roleSetPromise = firebaseApp
      .database()
      .ref('users')
      .child(id)
      .child('role')
      .set(value)
    roleSetPromise.then(() => {
      this.setState({
        snackbar: {
          open: true,
          message: 'User role was successfully changed.',
        },
      })
    })

    roleSetPromise.catch((error) => {
      this.setState({
        snackbar: {
          open: true,
          message: error.message,
        },
      })
    })
  }

  render() {
    let content

    if (!this.listModel.isLoaded) return <CircularProgress />

    const rows = []
    Object.keys(this.listModel.records).forEach((key) => {
      const item = this.listModel.records[key]

      rows.push(
        <RowComponent
          key={item.key}
          item={item}
          handleRoleChange={this.handleRoleChange}
          handleRemove={this.handleRemove}
        />
      )
    })

    if (rows.length !== 0) {
      content = (
        <div>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ width: '250px' }}>Role</TableCell>
                <TableCell>Email</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{rows}</TableBody>
          </Table>
          <Snackbar
            open={this.state.snackbar.open}
            message={this.state.snackbar.message}
            autoHideDuration={4000}
            onClose={this.handleSnackbarClose}
          />
        </div>
      )
    }

    return <div>{content}</div>
  }
}

export default ListComponent
