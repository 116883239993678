import 'mobx-react-lite/batchingForReactDom'
import React from 'react'
import ReactDOM from 'react-dom'
import { Switch } from 'react-router'
import { Router } from 'react-router-dom'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import authModel from './Models/AuthModel'
import Application from './Components/ApplicationComponent'
import DateFnsUtils from '@date-io/dayjs'
import { history } from 'src/config/routing/history'
import { AdminRoutes } from './config/routing/AdminRoutes'
import { UserRoutes } from './config/routing/UserRoutes'
import { UnauthenticatedRoutes } from './config/routing/UnauthenticatedRoutes'
import { observer } from 'mobx-react'

const App = observer(() => {
  return (
    <Router history={history}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Application
          initialized={authModel.initialized}
          isLoggedIn={authModel.isLoggedIn}  
          isAdmin={authModel.isAdmin}
        >
          <Switch>
            {authModel.isLoggedIn && authModel.isAdmin && <AdminRoutes />}
            {authModel.isLoggedIn && !authModel.isAdmin && <UserRoutes />}
            {!authModel.isLoggedIn && <UnauthenticatedRoutes />}
          </Switch>
          </Application>
      </MuiPickersUtilsProvider>
    </Router>
  )
})

ReactDOM.render(<App />, document.getElementById('root'))
