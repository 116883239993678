import React from 'react'
import { observer } from 'mobx-react'
import { withStyles } from '@material-ui/core/styles'
import keycode from 'keycode'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import TableFooter from '@material-ui/core/TableFooter'
import TablePagination from '@material-ui/core/TablePagination'

import Paper from '@material-ui/core/Paper'
import Checkbox from '@material-ui/core/Checkbox'
//import { Table } from 'semantic-ui-react';

class EnhancedTableHead extends React.Component {
  createSortHandler = (property) => (event) => {
    this.props.onRequestSort(event, property)
  }

  render() {
    const {
      columnData,
      onSelectAllClick,
      order,
      orderBy,
      numSelected,
      rowCount,
    } = this.props

    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={numSelected > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          </TableCell>
          {columnData.map((column) => {
            return (
              <TableCell key={column.id}>
                <TableSortLabel
                  active={orderBy === column.id}
                  direction={order}
                  onClick={column.hasSort && this.createSortHandler(column.id)}
                >
                  {column.label}
                </TableSortLabel>
              </TableCell>
            )
          }, this)}
        </TableRow>
      </TableHead>
    )
  }
}

const styles = (theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  tableWrapper: {
    overflowX: 'auto',
  },
})

@observer
class EnhancedTable extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      order: 'asc',
      orderBy: 'name',
      selected: [],
      page: 0,
      rowsPerPage: 25,
    }
  }

  handleRequestSort = (event, property) => {
    const orderBy = property
    let order = 'desc'

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc'
    }

    this.setState({ order, orderBy })
  }

  sortData() {
    const orderBy = this.state.orderBy
    return this.state.order === 'desc'
      ? this.props.data.sort((a, b) => (b[orderBy] < a[orderBy] ? -1 : 1))
      : this.props.data.sort((a, b) => (a[orderBy] < b[orderBy] ? -1 : 1))
  }

  handleSelectAllClick = (event) => {
    if (event.target.checked) {
      this.setState({ selected: this.props.data.map((n) => n.key) })
      return
    }
    this.setState({ selected: [] })
  }

  handleKeyDown = (event, id) => {
    if (keycode(event) === 'space') {
      this.handleClick(event, id)
    }
  }

  handleClick = (event, id) => {
    const { selected } = this.state
    const selectedIndex = selected.indexOf(id)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )
    }

    this.setState({ selected: newSelected })
  }

  handleChangePage = (event, page) => {
    this.setState({ page })
  }

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      const selectedStillExists = this.props.data.find((study) =>
        this.state.selected.includes(study.key)
      )
      if (!selectedStillExists) {
        this.setState({
          selected: [],
        })
      }
    }
  }

  isSelected = (id) => this.state.selected.indexOf(id) !== -1

  render() {
    const classes = this.props.classes
    const { order, orderBy, selected, rowsPerPage, page } = this.state
    const {
      data,
      columnData,
      canShare,
      enhancedTableToolbar,
      listModel,
      CustomRow,
      handleClickRow,
    } = this.props

    const EnhancedTableToolbar = enhancedTableToolbar

    const sortedData = this.sortData(data)

    return (
      <Paper className={classes.root}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          selected={selected}
          listModel={listModel}
        />

        <div className={classes.tableWrapper}>
          <Table>
            <EnhancedTableHead
              columnData={columnData}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={this.handleSelectAllClick}
              onRequestSort={this.handleRequestSort}
              rowCount={data.length}
            />
            <TableBody>
              {sortedData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((n) => {
                  const isSelected = this.isSelected(n.key)
                  return (
                    <CustomRow
                      key={n.key}
                      handleClick={this.handleClick.bind(this)}
                      handleKeyDown={this.handleKeyDown.bind(this)}
                      isSelected={isSelected}
                      n={n}
                      handleClickCell={handleClickRow}
                      canShare={canShare}
                    />
                  )
                })}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  count={data.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </div>
      </Paper>
    )
  }
}

export default withStyles(styles)(EnhancedTable)
