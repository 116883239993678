import React, { Component } from 'react'

import UserModel from '../../../../Models/UserModel'
import Snackbar from '@material-ui/core/Snackbar'
import Dialog from '@material-ui/core/Dialog'
import Button from '@material-ui/core/Button'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Typography from '@material-ui/core/Typography'
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart'
import StripeCheckout from 'react-stripe-checkout'
import { createStripeCustomer, createSubscription } from 'src/services/stripe'

export default class CreateComponent extends Component {
  constructor(props) {
    super(props)

    this.state = {
      dialog: false,
      snackbar: {
        open: false,
        message: '',
      },
    }
  }

  /**
   * Handles received token from Stripe payment processor and perform customer subscription.
   *
   * @param {Object} token Token object
   */
  handleCustomerCreate = async (token) => {
    try {
      const { data } = await createStripeCustomer(token.id, UserModel.userEmail)
      // Updates stripe customer id. In case when used is newly registered there is no customer id
      // loaded from firebase, so on subscription creation we need to update it manually to get rid
      // of useless data reload form firebaseApp.
      UserModel.stripeCustomerId = data.id
      await this.handleSubscriptionCreate()
    } catch (err) {
      this.setState({
        dialog: false,
        snackbar: {
          open: true,
          message: 'error creating new customer',
        },
      })
    }
  }

  handleSubscriptionCreate = async () => {
    UserModel.stripeCustomer.reset()
    this.handleSubscriptionCreateClose()

    try {
      const { data: subscription } = await createSubscription(
        UserModel.stripeCustomerId,
        this.props.planId
      )

      this.props.callback(subscription.id)
    } catch (err) {
      this.setState({
        snackbar: {
          open: true,
          message: 'there was an error creating a subscription',
        },
      })
    }
  }

  handleSubscriptionCreateOpen = () => {
    this.setState({ dialog: true })
  }

  handleSubscriptionCreateClose = () => {
    this.setState({ dialog: false })
  }

  /**
   * Handles snackbar action button is touchtap.
   */
  handleActionClick = () => {
    this.setState({
      snackbar: {
        open: false,
        message: '',
      },
    })
  }

  render() {
    const snackBar = (
      <Snackbar
        action={
          <Button color="primary" onClick={this.handleActionClick}>
            Close
          </Button>
        }
        open={this.state.snackbar.open}
        message={this.state.snackbar.message}
        autoHideDuration={10000}
      />
    )

    if (UserModel.stripeCustomerId === null) {
      return (
        <div>
          <Typography variant="h5" style={{ marginBottom: 8 }}>
            {this.props.header}
          </Typography>
          <StripeCheckout
            email={UserModel.userEmail}
            token={this.handleCustomerCreate}
            stripeKey={process.env.REACT_APP_STRIPE_PUBLIC_KEY}
            currency="USD"
          >
            {this.props.children}
          </StripeCheckout>
          {snackBar}
        </div>
      )
    } else {
      return (
        <div>
          <Typography variant="h5">{this.props.header}</Typography>
          <Button
            size="large"
            variant="contained"
            color="primary"
            style={{ margin: '13px' }}
            startIcon={<AddShoppingCartIcon />}
            onClick={this.handleSubscriptionCreateOpen}
          >
            Purchase Subscription
          </Button>
          {snackBar}

          <Dialog
            onClose={this.handleSubscriptionUpdateClose}
            open={this.state.dialog}
          >
            <DialogTitle>Subscription purchase</DialogTitle>
            <DialogContent>
              Are you sure you want to purchase a subscription?
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleSubscriptionCreateClose}>
                Close
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={this.handleSubscriptionCreate}
              >
                OK
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      );
    }
  }
}
