import { observable, transaction, computed } from 'mobx'
import { firebaseApp } from 'src/config/firebase'

import userModel from '../UserModel'

class ShareListModel {
  @observable _loaded = false
  @observable _studies = []

  @computed get isLoaded() {
    return this._loaded
  }

  @computed get studies() {
    return this._studies.slice()
  }

  update() {
    this._loaded = false

    firebaseApp
      .database()
      .ref('/shareStudies/' + userModel.organisationId)
      .once('value')
      .then((snapshot) => {
        const sharedList = snapshot.val()
        if (!sharedList) {
          this._loaded = true
          return
        }

        const shareStudies = []
        snapshot.forEach((childSnapshot) => {
          const item = childSnapshot.val()
          if (
            item.userId !== userModel.userId &&
            item.databaseId !== userModel.databaseId
          ) {
            shareStudies.push({
              ...item,
              shareKey: childSnapshot.key,
            })
          }
        })

        const promises = []
        const studiesList = []
        shareStudies.forEach((item) => {
          promises.push(
            firebaseApp
              .database()
              .ref(
                '/studies/' +
                  item.userId +
                  '/' +
                  item.databaseId +
                  '/' +
                  item.study
              )
              .once('value')
              .then((snap) => {
                const studyData = snap.val()
                if (studyData) {
                  studyData.key = snap.key
                  studyData.shareKey = item.shareKey
                  studyData.isShareEdit = item.isShareEdit
                  studiesList.push(studyData)
                }
              })
          )
        })

        Promise.all(promises).then(() => {
          transaction(() => {
            this._studies = studiesList
            this._loaded = true
          })
        })
      })
  }
}

export default ShareListModel
