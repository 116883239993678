/**
 * Access for the user without active subscription.
 */
class FreeUserAccess {
  /**
   * Amount of collections.
   *
   * @type {number}
   */
  collections = 2;

  /**
   * Amount of studies.
   *
   * @type {number}
   */
  studies = 5;

  /**
   * Amount of records.
   *
   * @type {number}
   */
  records = 25;
}

export default new FreeUserAccess();