import { observable, transaction, computed } from 'mobx'
import { firebaseApp } from 'src/config/firebase'

import userModel from '../UserModel'

class OrganizationUserModel {
  @observable _loaded = false
  _users = []
  _orgName = ''

  @computed get isLoaded() {
    return this._loaded
  }

  get users() {
    return this._users
  }

  get orgName() {
    return this._orgName
  }

  update() {
    this._loaded = false

    if (userModel.organisationId === null) {
      this._loaded = true
      return Promise.resolve()
    }

    return firebaseApp
      .database()
      .ref('/organisations/' + userModel.organisationId)
      .once('value')
      .then((snapshot) => {
        const org = snapshot.val()
        const users = Object.keys(org.users)
        const usersResult = users.map((item) =>
          item === 'null' ? userModel.organisationId : item
        )

        transaction(() => {
          this._users = usersResult
          this._orgName = org.name
          this._loaded = true
        })
      })
  }
}

export default new OrganizationUserModel()
