import React, { Component } from 'react'
import Typography from '@material-ui/core/Typography'
import { observer } from 'mobx-react'
import userModel from 'src/Models/UserModel'
import EditComponent from 'src/Components/Account/Edit/EditComponent'

/**
 * Displays invited users list from database in table view.
 */
@observer
class ViewComponent extends Component {
  render() {
    return (
      <div style={{ margin: '16px 0 0 16px' }}>
        <Typography variant="h4" style={{ marginBottom: 16 }}>
          Owner: {userModel.userEmail}
        </Typography>
        <EditComponent />
        {/* <LicensesComponent /> */}
      </div>
    )
  }
}

export default ViewComponent
