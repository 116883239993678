import React, { Component } from 'react'
import { Header, Table, Label, Breadcrumb, Button } from 'semantic-ui-react'
import ListModel from '../../../Models/Variable/ListModel'
import CircularProgress from '@material-ui/core/CircularProgress'
import Snackbar from '@material-ui/core/Snackbar'
import { observer } from 'mobx-react'
import { Input, Select } from 'semantic-ui-react'
import { firebaseApp } from 'src/config/firebase'
import { Redirect } from 'react-router-dom'
import BatchesVariableModel from '../../../Models/Batches/BatchesVariableModel'
import ShareStudyModel from '../../../Models/Study/ShareStudyModel'
import { history } from 'src/config/routing/history'

class RowComponent extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isSaving: false,
    }
  }

  render() {
    const {
      variableKey,
      variableKeyValue,
      variableItem,
      record,
      saveToData,
      fetchData,
    } = this.props
    const valueCurrent = record[variableKeyValue]
    saveToData(variableKey, valueCurrent)

    let input
    if (variableItem.selections) {
      let options = variableItem.selections.map((value) => {
        return { text: value, value: value }
      })

      options.unshift({ text: 'Select option', value: '' })

      input = (
        <Select
          options={options}
          onChange={(event, select) => {
            if (valueCurrent !== select.value) {
              saveToData(variableKey, select.value)
            }
          }}
          defaultValue={valueCurrent}
          placeholder="Select option"
          disabled={!fetchData.isShareEdit}
          fluid
        />
      )
    } else {
      input = (
        <Input
          defaultValue={valueCurrent}
          onChange={(event, inputProps) => {
            saveToData(variableKey, inputProps.value)
          }}
          disabled={!fetchData.isShareEdit}
          fluid
        />
      )
    }

    return (
      <Table.Row>
        <Table.Cell width="1">
          <Label color={variableItem.variableType?.toLowerCase()} size="large">
            {variableItem.inputType?.substr(0, 3)}
          </Label>
        </Table.Cell>
        <Table.Cell width="4">
          <Header as="h4">
            <Header.Content>{variableItem.displayedName}</Header.Content>
          </Header>
        </Table.Cell>
        <Table.Cell width="8">{input}</Table.Cell>
      </Table.Row>
    )
  }
}

export default
@observer
class ViewComponent extends Component {
  constructor(props) {
    super(props)

    this.shareStudyModel = new ShareStudyModel()
    this.shareStudyModel.shareId = props.match.params.shareId
      ? props.match.params.shareId
      : null
    this.shareStudyModel.studyId = props.match.params.study

    this.batchesVariableModel = new BatchesVariableModel()

    this.variableListModel = new ListModel()
    this.variableListModel._isBatchFields = true

    this.handleClickStudy = this.handleClickStudy.bind(this)
    this.handleClickStudies = this.handleClickStudies.bind(this)
    this.saveToData = this.saveToData.bind(this)
    this.getFromData = this.getFromData.bind(this)
    this.saveAllData = this.saveAllData.bind(this)

    this._data = {}

    this.state = {
      isAlert: false,
      redirectTo: null,
    }
  }

  saveAllData() {
    const fetchData = this.shareStudyModel.data
    const batchId = this.props.match.params.batchId

    firebaseApp
      .database()
      .ref(
        '/batchVariables/' +
          fetchData.userId +
          '/' +
          fetchData.databaseId +
          '/' +
          fetchData.study +
          '/' +
          batchId
      )
      .update(this._data)
      .then(() => {
        this.handleClickStudy()
      })
  }

  saveToData(key, value) {
    this._data[key] = value || ''
  }

  getFromData(key) {
    return this._data[key]
  }

  handleClickStudy() {
    history.goBack()
  }

  handleClickStudies = () =>
    this.setState({ ...this.state, redirectTo: '/studies' })

  handleAlertOpen = () => {
    this.setState({
      isAlert: true,
    })
  }

  handleAlertClose = () => {
    this.setState({
      isAlert: false,
    })
  }

  componentDidMount() {
    this.dataRecords = null

    this.shareStudyModel.update().then(() => {
      this.batchesVariableModel.fetchData = this.shareStudyModel.data
      this.variableListModel.fetchData = this.shareStudyModel.data

      this.batchesVariableModel.update()
      this.variableListModel.update()
    })
  }

  render() {
    if (!this.batchesVariableModel.isLoaded || !this.variableListModel.isLoaded)
      return <CircularProgress />

    const batchVariablesKeys = Object.keys(
      this.batchesVariableModel.batchVariables
    )
    const batchVariablesKey = batchVariablesKeys.find(
      (i) => i.toString() === this.props.match.params.batchId.toString()
    )
    const variableListModelVariables = this.variableListModel.variables

    const batchVariablesItem =
      this.batchesVariableModel.batchVariables[batchVariablesKey]
    const batchVariablesItemKeys = Object.keys(batchVariablesItem)
    const record = {}

    batchVariablesItemKeys.forEach((batchVariablesItemKey) => {
      const variableItem = variableListModelVariables.find(
        (itemV) => itemV.key === batchVariablesItemKey.toString()
      )
      if (variableItem) {
        record[variableItem.name] =
          batchVariablesItem[batchVariablesItemKey.toString()]
      }
    })

    let variables = {}

    for (let i = 0; i < variableListModelVariables.length; i++) {
      const element = variableListModelVariables[i]
      variables[element.name] = element
    }

    const variableSelected = this.variableListModel.selected
    const variableKeysAll = Object.keys(variableSelected).filter(
      (item) => item !== 'Batch'
    )

    const columns = variableKeysAll.sort((item_a, item_b) => {
      if (variableSelected[item_a] === variableSelected[item_b]) return 0
      return variableSelected[item_a] ? -1 : 1
    })

    const columnsOrder = this.variableListModel.getOrderByColumns(columns)

    const rows = columnsOrder.map((key) => {
      const variableItem = variableListModelVariables.find(
        (i) => i.name === key
      )

      return (
        <RowComponent
          key={key}
          record={record}
          variableKey={variableItem.key}
          variableKeyValue={key}
          variableItem={variables[key]}
          studyID={this.shareStudyModel.data.study}
          batchId={this.props.match.params.batchId}
          handleAlertOpen={this.handleAlertOpen}
          saveToData={this.saveToData}
          getFromData={this.getFromData}
          fetchData={this.shareStudyModel.data}
        />
      )
    })

    return (
      <div style={{ margin: 24 + 'px' }}>
        {this.state.redirectTo && <Redirect push to={this.state.redirectTo} />}

        <Snackbar
          open={this.state.isAlert}
          message="Changes have been saved"
          autoHideDuration={4000}
          onClose={this.handleAlertClose}
        />
        <Breadcrumb size="massive">
          <Breadcrumb.Section link onClick={this.handleClickStudies}>
            Data
          </Breadcrumb.Section>
          <Breadcrumb.Divider icon="right chevron" />
          <Breadcrumb.Section link onClick={this.handleClickStudy}>
            Study
          </Breadcrumb.Section>
          <Breadcrumb.Divider icon="right chevron" />
          <Breadcrumb.Section active>Batch fields details</Breadcrumb.Section>
        </Breadcrumb>
        {this.shareStudyModel.data.isShareEdit && (
          <Button
            style={{ marginLeft: '35px' }}
            icon="save"
            content="Save all fields and back"
            color="blue"
            labelPosition="right"
            onClick={this.saveAllData}
          />
        )}
        <Table basic="very" celled collapsing>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Type</Table.HeaderCell>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>Value</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>{rows}</Table.Body>
        </Table>
        {this.shareStudyModel.data.isShareEdit && (
          <Button
            icon="save"
            content="Save all fields and back"
            color="blue"
            labelPosition="right"
            onClick={this.saveAllData}
          />
        )}
      </div>
    )
  }
}
