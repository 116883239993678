import React, { Component } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { observer } from 'mobx-react'
import { Button, Icon } from 'semantic-ui-react'
import ShareModel from './ShareModel'

@observer
class StudyShareButtons extends Component {
  state = {
    exporting: false,
  }

  constructor(props) {
    super(props)
    this.shareModel = new ShareModel()
    this.shareModel.study = props.study
  }

  componentDidMount() {
    this.shareModel.update()
  }

  handleShareViewClick(e) {
    this.shareModel.setShare(false)
    e.stopPropagation()
  }

  handleShareEditClick(e) {
    this.shareModel.setShare(true)
    e.stopPropagation()
  }

  // async exportStudy(e) {
  //   e.stopPropagation()
  //   this.setState({ exporting: true })
  //   // console.log(this.props.study.n)
  //   const userId = firebaseApp.auth().currentUser.uid

  //   const recordSnapshots = await firebaseApp
  //     .database()
  //     .ref(
  //       `/records/${userId}/${UserModel.databaseId}/${this.props.study.n.suid}`
  //     )
  //     .once('value')

  //   const variableSnapshots = await firebaseApp
  //     .database()
  //     .ref(
  //       `/variables/${userId}/${UserModel.databaseId}/${this.props.study.n.suid}`
  //     )
  //     .once('value')

  //   // list of batch level records
  //   const batchVariableSnapshots = await firebaseApp
  //     .database()
  //     .ref(
  //       `/batchVariables/${userId}/${UserModel.databaseId}/${this.props.study.n.suid}`
  //     )
  //     .once('value')

  //   const variables = []
  //   // maps variable id to variable name
  //   // this is needed when we get the batch fields and need the variable name
  //   const variableNameMap = new Map()
  //   const studyVariables = []
  //   const batchVariables = []
  //   const recordVariables = []
  //   const data = []
  //   const defaultColumn = {}

  //   variableSnapshots.forEach((variableSnap) => {
  //     const variable = variableSnap.val()
  //     variables.push(variable)
  //     variableNameMap.set(variableSnap.key, variable.displayedName)
  //     defaultColumn[variable.displayedName] = ' '
  //     variable.displayedName === 'Date of Tow' && console.log(variable)
  //     if (!variable.forBatch && !variable.recorded) {
  //       studyVariables.push(variable.displayedName)
  //     }
  //     if (variable.forBatch && !variable.recorded) {
  //       batchVariables.push(variable.displayedName)
  //     }
  //     if (!variable.forBatch && variable.recorded) {
  //       recordVariables.push(variable.displayedName)
  //     }
  //   })
  //   console.log(studyVariables)
  //   console.log(batchVariables)

  //   console.log(recordVariables)

  //   const systemVariables = [
  //     'Order_ID',
  //     'RUID',
  //     'Date',
  //     'Time',
  //     'Count',
  //     'Tag Action Message',
  //     'Tag Note',
  //     'SUID',
  //     ...studyVariables,
  //     'Batch_Name',
  //     'BUID',
  //     ...batchVariables,
  //     ...recordVariables,
  //   ]

  //   systemVariables.forEach((v) => {
  //     defaultColumn[v] = ''
  //   })

  //   recordSnapshots.forEach((recordSnap) => {
  //     const record = recordSnap.val()
  //     const recordData = []
  //     systemVariables.forEach((variable) => {
  //       recordData.push(record.values[variable] || '')
  //     })
  //     data.push(recordData)
  //   })

  //   const studySettings = variables
  //     .filter((variable) => !variable.recorded)
  //     .map((variable) => ({
  //       ...defaultColumn,
  //       ...variable,
  //     }))

  //   const batchFields = []
  //   batchVariableSnapshots.forEach((batchVarSnap) => {
  //     const batchField = batchVarSnap.val()

  //     const batchRecord = {}
  //     Object.keys(batchField).forEach((variableId) => {
  //       const variableValue = batchField[variableId]
  //       const variableName = variableNameMap.get(variableId)
  //       batchRecord[variableName] = variableValue
  //     })
  //     batchFields.push({ ...defaultColumn, ...batchRecord })
  //   })

  //   // fetch individual records and store
  //   const individualRecords = []
  //   recordSnapshots.forEach((recordSnap) => {
  //     const record = recordSnap.val()
  //     individualRecords.push({ ...defaultColumn, ...record.values })
  //   })

  //   const flatObject = studySettings
  //     .concat(batchFields)
  //     .concat(individualRecords)

  //   // jsonexport(flatObject, (err, csv) => {
  //   //   const csvContent = 'data:text/csv;charset=utf-8,' + csv
  //   //   const encodedUri = encodeURI(csvContent)
  //   //   const link = document.createElement('a')

  //   //   link.setAttribute('href', encodedUri)
  //   //   link.setAttribute('download', this.props.study.n.name)
  //   //   link.click()
  //   // })

  //   this.setState({ exporting: false })
  // }

  render() {
    if (!this.shareModel.isLoaded) return <CircularProgress />

    return (
      <Button.Group basic size="small">
        <Button
          toggle
          active={this.shareModel.isShareView}
          onClick={this.handleShareViewClick.bind(this)}
        >
          <Icon name="share" /> Share: View Only
        </Button>
        <Button
          toggle
          active={this.shareModel.isShareEdit}
          onClick={this.handleShareEditClick.bind(this)}
        >
          <Icon name="share" /> Share: Allow Edits
        </Button>

        {/* <Button
          loading={this.state.exporting}
          onClick={this.exportStudy.bind(this)}
        >
          <Icon name="upload" /> Export Study
        </Button> */}
      </Button.Group>
    )
  }
}

export default StudyShareButtons
