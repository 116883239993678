import { observable, transaction, computed } from 'mobx'
import { firebaseApp } from 'src/config/firebase'

class BatchesModel {
  _study = null
  _localKey = ''
  @observable _loaded = false
  @observable _batchesList = []
  _batchesRecord = []
  @observable _selectedBatches = []
  @observable _version = 0
  fetchData = {}

  @computed
  get version() {
    return this._version
  }

  @computed
  get isLoaded() {
    return this._loaded
  }

  @computed
  get batchesList() {
    return this._batchesList.slice()
  }

  set study(value) {
    this._study = value
  }

  set localKey(value) {
    this._localKey = value
  }

  recordsToShow() {
    const selected = this.selectedBatches

    return this._batchesRecord.reduce(function (current, element) {
      if (!selected.includes(element.key) && !selected.includes('Select All'))
        return current

      return current.concat(element.value)
    }, [])
  }

  batchByRecord(recordId) {
    const batchRecord = this._batchesRecord.find((item) =>
      item.value.includes(recordId)
    )
    if (!batchRecord) {
      return ''
    }
    return batchRecord.key
  }

  @computed
  get selectedBatches() {
    return this._selectedBatches.slice()
  }

  set selectedBatches(value) {
    this._selectedBatches = value
  }

  getRecords() {
    firebaseApp
      .database()
      .ref(
        `/batchRecords/${this.fetchData.userId}/${this.fetchData.databaseId}/${this.fetchData.study}`
      )
      .on('value', (snapshot) => {
        const list = []

        snapshot.forEach((childSnapshot) => {
          const records = []
          childSnapshot.forEach((recordSnapshot) => {
            const val = recordSnapshot.val()
            if (val) records.push(recordSnapshot.key)
          })

          list.push({
            key: childSnapshot.key,
            value: records,
          })
        })
        
        this._batchesRecord = list
        this._version++
      })

    return firebaseApp
      .database()
      .ref(
        `/batchRecords/${this.fetchData.userId}/${this.fetchData.databaseId}/${this.fetchData.study}`
      )
      .once('value')
      .then((snapshot) => {
        const list = []

        snapshot.forEach((childSnapshot) => {
          const records = []
          childSnapshot.forEach((recordSnapshot) => {
            const val = recordSnapshot.val()
            if (val) records.push(recordSnapshot.key)
          })

          list.push({
            key: childSnapshot.key,
            value: records,
          })
        })
        
        this._batchesRecord = list
        this._version++
      })
  }

  async update() {
    this._loaded = false

    await this.getRecords()

    firebaseApp
      .database()
      .ref(
        `/batches/${this.fetchData.userId}/${this.fetchData.databaseId}/${this.fetchData.study}`
      )
      .on('value', (snapshot) => {
        const list = []
        let selected = []

        snapshot.forEach((childSnapshot) => {
          let childData = childSnapshot.val()
          if (childData.selected) selected.push(childData.buid.toString())

          list.push(childData)
        })

        const last = this.getFromStorage()
        if (last) {
          selected = last
        }
        transaction(() => {
          this._batchesList = list
          this._selectedBatches = selected
          this._loaded = true
        })
      })
  }

  saveToStorage(data) {
    localStorage.setItem(
      `BatchesModel-selected${this._localKey}::${this.fetchData.study}`,
      JSON.stringify(data)
    )
  }

  getFromStorage() {
    const data = localStorage.getItem(
      `BatchesModel-selected${this._localKey}::${this.fetchData.study}`
    )
    return JSON.parse(data)
  }

  static change(userId, databaseId, studyId, key, data) {
    firebaseApp
      .database()
      .ref(`/batches/${userId}/${databaseId}/${studyId}/${key}`)
      .update(data)
  }

  static create(userId, databaseId, studyId, data) {
    const ref = firebaseApp
      .database()
      .ref(`/batches/${userId}/${databaseId}/${studyId}`)
      .push()

    return ref
      .set({
        ...data,
        id: ref.key,
      })
      .then(() => ref.key)
  }

  static getDefault() {
    return {
      id: 0,
      name: '<name>',
      selected: true,
    }
  }
}

export default BatchesModel
