import { observable, transaction, computed } from 'mobx'
import { firebaseApp } from 'src/config/firebase'
import { get } from 'lodash'

import userModel from '../UserModel'
import FreeUserAccess from '../../config/freeUserAccess'

class ListModel {
  @observable _loaded = false
  @observable _collections = []

  @computed get isLoaded() {
    return this._loaded
  }

  @computed get collections() {
    return this._collections.slice()
  }

  update() {
    this._loaded = false

    firebaseApp
      .database()
      .ref('/studies/' + userModel.userId + '/' + userModel.databaseId)
      .on('value', (snapshot) => {
        let collectionStatistic = {}

        snapshot.forEach((childSnapshot) => {
          const childData = childSnapshot.val()
          const collection = childData.belongsToCollection

          if (!collection) return

          if (!collectionStatistic[collection]) {
            collectionStatistic[collection] = {
              numStudies: 0,
            }
          }

          collectionStatistic[collection]['numStudies']++
        })

        firebaseApp
          .database()
          .ref('/collections/' + userModel.userId + '/' + userModel.databaseId)
          .once('value')
          .then((snapshot) => {
            let collectionList = []

            snapshot.forEach((childSnapshot) => {
              let childData = childSnapshot.val()
              childData.key = childSnapshot.key
              childData.numStudies = get(
                collectionStatistic,
                [childData.key, 'numStudies'],
                0
              )
              collectionList.push(childData)
            })

            if (!userModel.stripeCustomer.hasActiveAccount) {
              collectionList = collectionList.slice(
                0,
                FreeUserAccess.collections
              )
            }

            transaction(() => {
              this._collections = collectionList
              this._loaded = true
            })
          })
      })
  }
}

export default new ListModel()
