import { observable, transaction, computed } from 'mobx'
import { firebaseApp } from 'src/config/firebase'
import * as Promise from 'promise/src/es6-extensions'
import userModel from '../UserModel'

/**
 * Model for managing user invitations.
 */
class InvitationModel {
  /**
   * Flag to determine whether data was loaded from database.
   *
   * @type {boolean}
   * @private
   */
  @observable _loaded = false

  /**
   * Existing invitation records served from database.
   *
   * @type {Array}
   * @private
   */
  @observable _records = []

  /**
   * Whether data was loaded from database.
   *
   * @returns {boolean}
   */
  @computed get isLoaded() {
    return this._loaded
  }

  /**
   * Gets existing invitations.
   *
   * @returns {Array.<*>}
   */
  @computed get records() {
    return this._records.slice()
  }

  /**
   * Adds new user invitation.
   *
   * @param uid Firebase user ID.
   */
  create(uid) {
    const invitationUpdate = {}

    invitationUpdate[
      '/users/' + userModel.userId + '/invitations/' + uid
    ] = true
    invitationUpdate[
      '/organisations/' + userModel.organisationId + '/users/' + uid
    ] = true

    firebaseApp.database().ref().update(invitationUpdate)
  }

  /**
   * Removes user from organisation.
   *
   * @param uid Firebase user ID.
   */
  remove(uid) {
    firebaseApp
      .database()
      .ref('/users/' + userModel.userId + '/invitations/' + uid)
      .remove()
    firebaseApp
      .database()
      .ref('/users/' + uid + '/organisation/')
      .remove()
    firebaseApp
      .database()
      .ref('/users/' + uid + '/role/')
      .remove()
    firebaseApp
      .database()
      .ref('/organisations/' + userModel.organisationId + '/users/' + uid)
      .remove()
  }

  /**
   * Updates existing invitation list from database.
   */
  update() {
    this._loaded = false

    const invitationsRef = firebaseApp
      .database()
      .ref('/organisations/' + userModel.organisationId + '/users')
    invitationsRef.on('value', (snapshot) => {
      const recordsList = []
      const userDataPromise = []

      snapshot.forEach((childSnapshot) => {
        const dataPromise = firebaseApp
          .database()
          .ref('users')
          .child(childSnapshot.key)
          .once('value', (userSnapshot) => {
            const record = userSnapshot.val()
            if (record !== null) {
              record.key = childSnapshot.key
              recordsList.push(record)
            }
          })

        userDataPromise.push(dataPromise)
      })

      Promise.all(userDataPromise).then(() => {
        transaction(() => {
          this._records = recordsList
          this._loaded = true
        })
      })
    })
  }
}

export default InvitationModel
