import React, { Component } from 'react'
import { Feed, Table, Header } from 'semantic-ui-react'
import InvoiceModel from 'src/Models/Payment/Stripe/InvoiceModel'
import CircularProgress from '@material-ui/core/CircularProgress'
import dateFormat from 'dateformat'
import { observer } from 'mobx-react'

/**
 * Displays invoice history for current user.
 * Subscriptions have no charges but have invoices.
 */

export default
@observer
class HistoryComponent extends Component {
  componentDidMount() {
    const { subscription } = this.props

    let subscriptionId = null

    if (subscription !== null && subscription.hasOwnProperty('id')) {
      subscriptionId = subscription.id
    }

    InvoiceModel.subscriptionId = subscriptionId
    InvoiceModel.loadData()
  }

  render() {
    const rows = []
    const hasManual =
      InvoiceModel.manualTransactionList.length > 0 &&
      !this.props.disallowManual
    if (!InvoiceModel.isLoaded) {
      rows.push(
        <Table.Row key="0">
          <Table.Cell colSpan="5" textAlign="center">
            <CircularProgress />
          </Table.Cell>
        </Table.Row>
      )
    } else if (InvoiceModel.invoiceList.length === 0 && !hasManual) {
      rows.push(
        <Table.Row key="0">
          <Table.Cell colSpan="5" textAlign="center">
            No invoices.
          </Table.Cell>
        </Table.Row>
      )
    } else {
      const invoiceStripe = InvoiceModel.invoiceList
      const invoiceUpcoming = InvoiceModel.upcomingInvoiceList
      const manualTransactions = hasManual
        ? InvoiceModel.manualTransactionList
        : []

      const invoice = manualTransactions.concat(
        invoiceUpcoming.concat(invoiceStripe)
      )

      invoice.sort((a, b) => a.created - b.created)

      invoice.forEach((invoiceData, index) => {
        // manual transactions have date field, invoices have created field
        const invoiceDate = invoiceData.date || invoiceData.created
        const date = new Date(Math.round(invoiceDate * 1000))

        let interval = 'manual'
        if (invoiceData.hasOwnProperty('customer')) {
          if (invoiceData.lines.data.length !== 0) {
            interval = invoiceData.lines.data['0'].plan?.interval
          } else {
            interval = ''
          }
        }

        rows.push(
          <Table.Row key={index}>
            <Table.Cell>{invoiceData.paid ? 'Paid' : 'Upcoming'}</Table.Cell>
            <Table.Cell>{interval}</Table.Cell>
            <Table.Cell singleLine>
              {date ? dateFormat(date, 'dd mmmm yyyy') : 'n/a'}
            </Table.Cell>
            <Table.Cell>
              {invoiceData.amount_due ? '$' + invoiceData.amount_due / 100 : ''}
            </Table.Cell>
            <Table.Cell>{invoiceData.type || 'Auto-Pay CC'}</Table.Cell>
          </Table.Row>
        )
      })
    }

    return (
      <div>
        <Header as="h1">Payment history</Header>
        <Feed>
          <Table celled padded>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Status</Table.HeaderCell>
                <Table.HeaderCell>Plan</Table.HeaderCell>
                <Table.HeaderCell>Payment Date</Table.HeaderCell>
                <Table.HeaderCell>Amount</Table.HeaderCell>
                <Table.HeaderCell>Payment Type</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>{rows}</Table.Body>
          </Table>
        </Feed>
      </div>
    )
  }
}
