import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { DragSource } from 'react-dnd'
import { Icon } from 'semantic-ui-react'

const style = {
  border: '1px dashed gray',
  backgroundColor: 'white',
  padding: '0.7rem 0.8rem',
  cursor: 'move',
  width: '42px',
  height: '42px',
}

const boxSource = {
  beginDrag(props) {
    return {
      name: props.name,
    }
  },

  endDrag(props, monitor) {
    const item = monitor.getItem()
    const dropResult = monitor.getDropResult()

    if (dropResult) {
      const isSwap = window.confirm('Do you really want to swap this licenses?')
      if (isSwap) {
        props.editModel.swapSubscription(item.name, dropResult.name)
      }
    }
  },
}

class OldBoxComponent extends Component {
  static propTypes = {
    connectDragSource: PropTypes.func.isRequired,
    isDragging: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
  }

  render() {
    const { isDragging, connectDragSource } = this.props
    const opacity = isDragging ? 0.4 : 1

    return connectDragSource(
      <div style={{ ...style, opacity }}>
        <Icon name="circle" />
      </div>
    )
  }
}

export default DragSource('BOX', boxSource, (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  isDragging: monitor.isDragging(),
}))(OldBoxComponent)
