import React, { useCallback } from 'react'
import { useLocation } from 'react-router'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'

import 'semantic-ui-css/semantic.min.css'
import { createUnityTheme } from 'src/config/theme'
import HeaderComponent from './Header/HeaderComponent'
import Typography from '@material-ui/core/Typography'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.palette.common.white,
    flexDirection: 'column',
  },
  loadingText: {
    marginTop: theme.spacing(2),
  },
}))

/**
 * Main application
 */
const Application = ({ initialized, isLoggedIn, isAdmin, children }) => {
  const styles = useStyles()
  const location = useLocation()
  const isPromo = location.pathname.includes('/register-promo')

  const renderLoader = useCallback(
    () => (
      <Backdrop open className={styles.backdrop}>
        <CircularProgress color="primary" size={65} />
        <Typography variant="h2" className={styles.loadingText}>
          Loading DCS Unity
        </Typography>
      </Backdrop>
    ),
    [styles.backdrop, styles.loadingText]
  )

  let theme = isPromo ? createMuiTheme() : createUnityTheme(isAdmin)
  const showHeader = isLoggedIn && !isPromo

  return (
    <ThemeProvider theme={theme}>
      <div>
        {showHeader && <HeaderComponent />}
        {initialized ? children : renderLoader()}
      </div>
    </ThemeProvider>
  )
}

export default Application
