import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Cell } from 'fixed-data-table-2';

export default
@observer
class CheckboxCell extends Component {
  render() {
    const { store, rowIndex, data, ...props } = this.props;
    const object = data.getObjectAt(rowIndex);

    return (
      <Cell {...props}>
        <input
          key={rowIndex}
          type="checkbox"
          checked={store.selectedGet(object.key).get()}
          value={object.key}
          onChange={(e) => {
            store.selectedSet(object.key, e.target.checked);
          }}
        />
      </Cell>
    );
  }
}
