import { observable, transaction, computed } from 'mobx'
import { firebaseApp } from 'src/config/firebase'

import userModel from '../UserModel'

class OrganizationModel {
  @observable _loaded = false
  @observable _name = ''
  @observable _address = ''
  @observable _contact = ''
  @observable _phone = ''

  @computed get isLoaded() {
    return this._loaded
  }

  @computed get addressGet() {
    return this._address
  }

  @computed get contactGet() {
    return this._contact
  }

  @computed get phoneGet() {
    return this._phone
  }

  @computed get nameGet() {
    return this._name
  }

  update(organisationId) {
    this._loaded = false

    const organisationIdCurrent = organisationId || userModel.organisationId

    if (null === organisationIdCurrent) {
      this._loaded = true
      this._name = ''
      this._address = ''
      this._contact = ''
      this._phone = ''

      return Promise.resolve()
    }

    return firebaseApp
      .database()
      .ref('/organisations/' + organisationIdCurrent)
      .once('value')
      .then((snapshot) => {
        let val = snapshot.val()
        if (!val) {
          this._loaded = true
          return
        }

        transaction(() => {
          this._address = val.address
          this._name = val.name
          this._contact = val.contact || ''
          this._phone = val.phone || ''
          this._loaded = true
        })
      })
  }
}

export default new OrganizationModel()
