import React, { Component } from 'react'
import { Header, Table, Label, Breadcrumb, Button } from 'semantic-ui-react'
import ListModel from '../../../Models/Variable/ListModel'
import StudyViewModel from '../../../Models/Study/ViewModel'
import CircularProgress from '@material-ui/core/CircularProgress'
import Snackbar from '@material-ui/core/Snackbar'
import { observer } from 'mobx-react'
import { Input, Select } from 'semantic-ui-react'
import { firebaseApp } from 'src/config/firebase'
import { history } from 'src/config/routing/history'
import ShareStudyModel from '../../../Models/Study/ShareStudyModel'

class RowComponent extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isSaving: false,
    }
  }

  render() {
    const {
      variableKey,
      variableItem,
      record,
      saveToData,
      fetchData,
    } = this.props
    const valueCurrent = record[variableKey]
    saveToData(variableKey, valueCurrent)

    if (!variableItem.variableType) {
      return null
    }

    let input
    if (variableItem.selections) {
      let options = variableItem.selections.map((value) => {
        return { text: value, value: value }
      })

      options.unshift({ text: 'Select option', value: '' })

      input = (
        <Select
          options={options}
          onChange={(event, select) => {
            if (valueCurrent !== select.value) {
              saveToData(variableKey, select.value)
            }
          }}
          defaultValue={valueCurrent}
          placeholder="Select option"
          disabled={!fetchData.isShareEdit}
          fluid
        />
      )
    } else {
      input = (
        <Input
          defaultValue={valueCurrent}
          onChange={(event, inputProps) => {
            saveToData(variableKey, inputProps.value)
          }}
          disabled={!fetchData.isShareEdit}
          fluid
        />
      )
    }

    return (
      <Table.Row>
        <Table.Cell width="1">
          <Label color={variableItem.variableType.toLowerCase()} size="large">
            {variableItem.inputType.substr(0, 3)}
          </Label>
        </Table.Cell>
        <Table.Cell width="4">
          <Header as="h4">
            <Header.Content>{variableItem.displayedName}</Header.Content>
          </Header>
        </Table.Cell>
        <Table.Cell width="8">{input}</Table.Cell>
      </Table.Row>
    )
  }
}

export default
@observer
class ViewComponent extends Component {
  constructor(props) {
    super(props)

    this.shareStudyModel = new ShareStudyModel()
    this.shareStudyModel.shareId = props.match.params.shareId
      ? props.match.params.shareId
      : null
    this.shareStudyModel.studyId = props.match.params.study

    this.viewModel = new StudyViewModel()

    this.variableListModel = new ListModel()

    this.handleClickStudy = this.handleClickStudy.bind(this)
    this.handleClickStudies = this.handleClickStudies.bind(this)
    this.saveToData = this.saveToData.bind(this)
    this.getFromData = this.getFromData.bind(this)
    this.saveAllData = this.saveAllData.bind(this)

    this._data = {}

    this.state = {
      isAlert: false,
    }
  }

  saveAllData() {
    const fetchData = this.shareStudyModel.data
    const recordID = this.props.match.params.record

    firebaseApp
      .database()
      .ref(
        '/records/' +
          fetchData.userId +
          '/' +
          fetchData.databaseId +
          '/' +
          fetchData.study +
          '/' +
          recordID +
          '/values'
      )
      .update(this._data)
      .then(() => {
        this.handleClickStudy()
      })
  }

  saveToData(key, value) {
    this._data[key] = value || ''
  }

  getFromData(key) {
    return this._data[key]
  }

  handleClickStudy() {
    const fetchData = this.shareStudyModel.data
    if (fetchData.shareId) {
      history.push('/studies-share/' + fetchData.shareId)
    } else {
      history.push('/studies/' + fetchData.study)
    }
  }

  handleClickStudies() {
    history.push('/studies')
  }

  handleAlertOpen = () => {
    this.setState({
      isAlert: true,
    })
  }

  handleAlertClose = () => {
    this.setState({
      isAlert: false,
    })
  }

  componentDidMount() {
    this.dataRecords = null

    this.shareStudyModel.update().then(() => {
      this.viewModel.fetchData = this.shareStudyModel.data
      this.variableListModel.fetchData = this.shareStudyModel.data

      this.viewModel.update()
      this.variableListModel.update()
    })
  }

  render() {
    if (!this.viewModel.isLoaded || !this.variableListModel.isLoaded)
      return <CircularProgress />

    const records = this.viewModel.records

    const recordsID = this.props.match.params.record

    const record = records.find((record) => record.recordId === recordsID)

    if (!record) return 'This record does not found.'

    const recordValues = record.values

    let variables = {}

    for (let i = 0; i < this.variableListModel.variables.length; i++) {
      const element = this.variableListModel.variables[i]
      variables[element.name] = element
    }

    const variableSelected = this.variableListModel.selected
    const variableKeysAll = Object.keys(variableSelected)
    const columns = variableKeysAll.sort((item_a, item_b) => {
      if (variableSelected[item_a] === variableSelected[item_b]) return 0
      return variableSelected[item_a] ? -1 : 1
    })

    const columnsOrder = this.variableListModel.getOrderByColumns(columns)

    const rows = columnsOrder.map((key) => {
      return (
        <RowComponent
          key={key}
          record={recordValues}
          variableKey={key}
          variableItem={variables[key]}
          studyID={this.shareStudyModel.data.study}
          recordID={recordsID}
          handleAlertOpen={this.handleAlertOpen}
          saveToData={this.saveToData}
          getFromData={this.getFromData}
          fetchData={this.shareStudyModel.data}
        />
      )
    })

    return (
      <div style={{ margin: 24 + 'px' }}>
        <Snackbar
          open={this.state.isAlert}
          message="Changes have been saved"
          autoHideDuration={4000}
          onClose={this.handleAlertClose}
        />
        <Breadcrumb size="massive">
          <Breadcrumb.Section link onClick={this.handleClickStudies}>
            Data
          </Breadcrumb.Section>
          <Breadcrumb.Divider icon="right chevron" />
          <Breadcrumb.Section link onClick={this.handleClickStudy}>
            Study
          </Breadcrumb.Section>
          <Breadcrumb.Divider icon="right chevron" />
          <Breadcrumb.Section active>Record details</Breadcrumb.Section>
        </Breadcrumb>
        {this.shareStudyModel.data.isShareEdit && (
          <Button
            style={{ marginLeft: '35px' }}
            icon="save"
            content="Save all fields and back"
            color="blue"
            labelPosition="right"
            onClick={this.saveAllData}
          />
        )}
        <Table basic="very" celled collapsing>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Type</Table.HeaderCell>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>Value</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>{rows}</Table.Body>
        </Table>
        {this.shareStudyModel.data.isShareEdit && (
          <Button
            icon="save"
            content="Save all fields and back"
            color="blue"
            labelPosition="right"
            onClick={this.saveAllData}
          />
        )}
      </div>
    )
  }
}
