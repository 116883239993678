import React, { Component } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import Dialog from '@material-ui/core/Dialog'
import Button from '@material-ui/core/Button'
import { observer } from 'mobx-react'
import { Redirect } from 'react-router-dom'
import RoleManager from 'src/config/role'
import UsersListModel from 'src/Models/Admin/User/ListModel'
import DeleteModel from 'src/Models/Admin/User/DeleteModel'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'

class ButtonDeleteComponent extends Component {
  confirmationText = ''
  isLeaderAndOneInOrganisation = false

  state = {
    openDialog: false,
    isLoading: false,
    redirectTo: null,
  }

  handleOpen = () => {
    this.setState({ openDialog: true })
  }

  handleClose = () => {
    this.setState({ openDialog: false })
  }

  handleOk = () => {
    this.setState({
      openDialog: false,
      isLoading: true,
    })

    let promiseList = []
    promiseList.push(this.deleteModel.deleteUser())

    if (this.props.item.organisation)
      promiseList.push(this.deleteModel.deleteFromOrganisation())

    Promise.all(promiseList).then(() => {
      this.setState({
        isLoading: false,
      })

      this.props.listModel.update()
    })
  }

  handleDelete(userId, organisationId) {
    if (this.state.isLoading) return

    this.deleteModel = new DeleteModel(userId, organisationId)

    let isLeader = false
    let isLeaderOne = false

    const isLeaderPromise = this.deleteModel.isLeaderPromise()
    isLeaderPromise.then((result) => {
      isLeader = result
    })

    const isLeaderOnePromise = this.deleteModel.isOrganisationWithSingleLeaderPromise()
    isLeaderOnePromise.then((result) => {
      isLeaderOne = result
    })

    Promise.all([isLeaderPromise, isLeaderOnePromise]).then(() => {
      this.isLeaderAndOneInOrganisation = isLeader && isLeaderOne

      if (this.isLeaderAndOneInOrganisation) {
        alert(
          'This is a last owner in organization. Please, select another owner.'
        )
      } else {
        this.confirmationText = 'Do you really want to delete this user?'
        this.handleOpen()
      }
    })
  }

  handleRemoveFromOrg(userId, organisationId) {
    if (this.state.isLoading) return

    this.deleteModel = new DeleteModel(userId, organisationId)

    this.setState({
      isLoading: true,
    })

    this.deleteModel.removeFromOrganisation().then(() => {
      this.setState({
        isLoading: false,
      })

      this.props.listModel.update()
    })
  }

  handleClick(userId) {
    this.setState({ ...this.state, redirectTo: `/admin/users/${userId}` })
  }

  render() {
    const item = this.props.item

    return (
      <div>
        <Dialog
          title="Confirmation"
          open={this.state.openDialog}
          onClose={this.handleClose}
        >
          <DialogTitle>Confirmation</DialogTitle>
          <DialogContent>{this.confirmationText}</DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose}>Cancel</Button>
            <Button
              color="secondary"
              onClick={this.handleOk}
              variant="contained"
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
        <Button
          color="primary"
          onClick={() => {
            this.handleClick(item.key)
          }}
        >
          Edit
        </Button>
        <Button
          loading={this.state.isLoading}
          color="secondary"
          onClick={() => {
            this.handleDelete(item.key, item.organisation)
          }}
        >
          Delete
        </Button>
        <Button
          disabled={!item.organisation}
          loading={this.state.isLoading}
          color="secondary"
          onClick={() => {
            this.handleRemoveFromOrg(item.key, item.organisation)
          }}
        >
          Remove from org
        </Button>
        {this.state.redirectTo && <Redirect push to={this.state.redirectTo} />}
      </div>
    )
  }
}

/**
 * Displays registered users from database.
 * Renders users list in MaterialUI table.
 */
@observer
class ListComponent extends Component {
  constructor(props) {
    super(props)

    this.listModel = new UsersListModel()
    this.listModel.collection = props.collection
  }

  componentDidMount() {
    this.listModel.update()
  }

  render() {
    if (!this.listModel.isLoaded) return <CircularProgress />

    let content = ''

    const rows = []
    const users = this.listModel.users
    const orgs = this.listModel.organization
    const usersKeys = Object.keys(users)

    usersKeys.forEach((key) => {
      const item = this.listModel.users[key]

      let orgName = ''
      if (item.organisation) {
        const orgCurrent = orgs.find(
          (itemOrg) => item.organisation === itemOrg.key
        )
        orgName = orgCurrent ? orgCurrent.name : ''
      }

      rows.push(
        <TableRow key={item.key}>
          <TableCell>{item.email}</TableCell>
          <TableCell>{orgName}</TableCell>
          <TableCell>
            {item.role !== undefined
              ? RoleManager.getTitleById(item.role)
              : 'User without organization'}
          </TableCell>
          <TableCell>{item.stripeCustomerId || ''}</TableCell>
          <TableCell>
            <ButtonDeleteComponent item={item} listModel={this.listModel} />
          </TableCell>
        </TableRow>
      )
    })

    if (rows.length > 0) {
      const sortLabel = this.listModel._desc ? '↑' : '↓'
      const captionEmail =
        (this.listModel._sort === 'email' ? sortLabel : '') + ' Email'
      const captionOrganization =
        (this.listModel._sort === 'organisation' ? sortLabel : '') +
        ' Organization'
      const captionRole =
        (this.listModel._sort === 'role' ? sortLabel : '') + ' Role'

      content = (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Button
                  onClick={() => {
                    this.listModel.sort('email')
                  }}
                >
                  {captionEmail}
                </Button>
              </TableCell>
              <TableCell>
                <Button
                  onClick={() => {
                    this.listModel.sort('organisation')
                  }}
                >
                  {captionOrganization}
                </Button>
              </TableCell>
              <TableCell>
                <Button
                  onClick={() => {
                    this.listModel.sort('role')
                  }}
                >
                  {captionRole}
                </Button>
              </TableCell>
              <TableCell>Stripe ID</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{rows}</TableBody>
        </Table>
      )
    }

    return <div>{content}</div>
  }
}

export default ListComponent
