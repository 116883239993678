import React, { Component } from 'react'
import { observer } from 'mobx-react'
import HeaderModel from 'src/Models/Header/Header'
import UserModel from 'src/Models/UserModel'
import { List, Drawer, ListItem, Typography } from '@material-ui/core'
import { Link } from 'react-router-dom'
import AuthModel from 'src/Models/AuthModel'

/**
 * Sidebar menu component.
 * Renders user sidebar menu.
 */
@observer
class MenuComponent extends Component {
  render() {
    const isCustomer = UserModel.stripeCustomer.hasActiveAccount

    const menuItemsFiltered = HeaderModel.menuItems.filter((item) => {
      return !item.requireAllMenu || UserModel.hasAllMenu
    })

    const menuItems = menuItemsFiltered.map((item) => {
      return (
        <ListItemLink
          to={item.path}
          disabled={!isCustomer && item.requirePay}
          onClick={(e) => {
            if (item.path === '/logout') {
              e.preventDefault()
              AuthModel.signOut()
            }
            HeaderModel.menuOpened = false
          }}
          key={item.text}
        >
          <Typography variant="h6">{item.text}</Typography>
        </ListItemLink>
      )
    })

    return (
      <div>
        <Drawer
          anchor="left"
          open={HeaderModel.menuOpened}
          onClose={() => (HeaderModel.menuOpened = false)}
        >
          {this.props.appBar}
          <List
            component="nav"
            value={HeaderModel.menuItemSelected}
            width={370}
          >
            {menuItems}
          </List>
          <ListItem style={{ color: '#a9a9a9' }}>Unity Version: 0.1.0</ListItem>
        </Drawer>
      </div>
    )
  }
}

function ListItemLink({ to, ...props }) {
  return (
    <Link to={to}>
      <ListItem
        tabIndex={-1}
        button
        {...props}
        style={{ padding: '12px 16px' }}
      />
    </Link>
  )
}

export default MenuComponent
