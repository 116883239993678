import React from 'react'
import { Button, Header, Icon, Modal } from 'semantic-ui-react'
import {
  deleteCollectionsWithAllData,
  moveStudiesFromCollectionsAndDelete,
  deleteCollectionsSafe,
  deleteStudies,
} from '../../helpers'

import CollectionListModel from '../../../Models/Collection/ListModel'

export default class DeleteFeature extends React.Component {
  state = {
    modalOpen: false,
    modalAdditionalOpen: false,
    isDeleting: false,
  }

  handleOpen = () => this.setState({ modalOpen: true })

  handleClose = () => this.setState({ modalOpen: false })

  handleAdditionalOpen = () => {
    this.setState({
      modalOpen: false,
      modalAdditionalOpen: true,
    })
  }

  handleAdditionalClose = () => {
    this.setState({
      modalOpen: false,
      modalAdditionalOpen: false,
    })
  }

  _deleteProcess = async (func) => {
    const { selectedItems, listModel } = this.props

    this.setState({
      modalOpen: false,
      modalAdditionalOpen: false,
      isDeleting: true,
    })

    await func(selectedItems)

    this.setState({ isDeleting: false })

    await listModel.update()
  }

  handleAdditionalMove = () => {
    this._deleteProcess(moveStudiesFromCollectionsAndDelete).then(() => {
      window.location.reload()
    })
  }

  handleAdditionalDelete = () => {
    this._deleteProcess(deleteCollectionsWithAllData)
  }

  handleDelete = () => {
    if (this.props.isCollectionList) {
      if (this.getCountOfStudiesInsideSelectedCollection() === 0) {
        this._deleteProcess(deleteCollectionsSafe)
      } else {
        this.handleAdditionalOpen()
      }
    } else {
      this._deleteProcess(deleteStudies)
    }
  }

  getCountOfStudiesInsideSelectedCollection = () => {
    const { selectedItems } = this.props
    const allCollections = CollectionListModel.collections

    return allCollections.reduce((acc, collectionItem) => {
      if (selectedItems.includes(collectionItem.key)) {
        return acc + collectionItem.numStudies
      }
      return acc
    }, 0)
  }

  renderMain() {
    return (
      <Modal
        trigger={
          <Button
            loading={this.state.isDeleting}
            inverted
            onClick={this.handleOpen}
          >
            Delete
          </Button>
        }
        open={this.state.modalOpen}
        onClose={this.handleClose}
        size="small"
      >
        <Header icon="trash" content="Delete" />
        <Modal.Content>
          <h3>Are you sure you want to delete selected items?</h3>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.handleClose}>Close</Button>
          <Button color="red" onClick={this.handleDelete} inverted>
            <Icon name="trash" /> Delete
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }

  renderMainWithAdditional() {
    return (
      <div>
        {this.renderMain()}
        <Modal
          open={this.state.modalAdditionalOpen}
          onClose={this.handleAdditionalClose}
          size="small"
        >
          <Header icon="question circle outline" content="Delete studies?" />
          <Modal.Content>
            <h3>
              One or more studies have been detected within the selection
              collection(s). Select action.
            </h3>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={this.handleAdditionalClose}>Close</Button>
            <Button color="red" onClick={this.handleAdditionalDelete}>
              <Icon name="trash" /> Delete Studies
            </Button>
            <Button color="yellow" onClick={this.handleAdditionalMove}>
              <Icon name="arrow left" /> Move Studies
            </Button>
          </Modal.Actions>
        </Modal>
      </div>
    )
  }

  render() {
    return this.props.isCollectionList
      ? this.renderMainWithAdditional()
      : this.renderMain()
  }
}
