import React, { Component } from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import Dialog from '@material-ui/core/Dialog'
import UserModel from '../../../../Models/UserModel'
import Button from '@material-ui/core/Button'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import { cancelSubscription } from 'src/services/stripe'

import Typography from '@material-ui/core/Typography'

export default class CancelComponent extends Component {
  constructor(props) {
    super(props)

    this.state = {
      dialog: false,
      snackbar: {
        open: false,
        message: '',
      },
    }
  }

  handleSubscriptionCancel = async () => {
    UserModel.stripeCustomer.reset()
    this.handleSubscriptionCancelClose()

    const { subscriptionId } = this.props

    try {
      await cancelSubscription(subscriptionId)
      this.props.callback(subscriptionId)
    } catch (err) {
      console.error(err)
      this.setState({
        snackbar: {
          open: true,
          message: `an error occurred while canceling this subscription.`,
        },
      })
    }
  }

  handleSubscriptionCancelOpen = () => {
    this.setState({ dialog: true })
  }

  handleSubscriptionCancelClose = () => {
    this.setState({ dialog: false })
  }

  /**
   * Handles snackbar action button is touchtap.
   */
  handleActionClick = () => {
    this.setState({
      snackbar: {
        open: false,
        message: '',
      },
    })
  }

  render() {
    return (
      <div>
        <Typography variant="h5" style={{ marginBottom: 8 }}>
          {this.props.header}
        </Typography>
        <Button
          variant="outlined"
          size="large"
          style={{ margin: '13px' }}
          onClick={this.handleSubscriptionCancelOpen}
        >
          Unsubscribe
        </Button>
        <Snackbar
          action={
            <Button color="primary" onClick={this.handleActionClick}>
              Close
            </Button>
          }
          open={this.state.snackbar.open}
          message={this.state.snackbar.message}
          autoHideDuration={10000}
        />

        <Dialog
          onClose={this.handleSubscriptionCancelClose}
          open={this.state.dialog}
        >
          <DialogTitle>Subscription Cancel</DialogTitle>
          <DialogContent>
            Are you sure you want to cancel current subscription?
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleSubscriptionCancelClose}>Close</Button>
            <Button color="primary" onClick={this.handleSubscriptionCancel}>
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}
