import React, { Component } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import NewBoxComponent from './NewBoxComponent'
import OldBoxComponent from './OldBoxComponent'

export default class Container extends Component {
  render() {
    const editModel = this.props.editModel
    const data = editModel.data
    const devicesKeys = Object.keys(data.devices)
    const devices = devicesKeys.map((key) => {
      const deviceData = data.devices[key]

      if (deviceData['subscriptionId']) {
        return <OldBoxComponent name={key} key={key} editModel={editModel} />
      } else {
        return <NewBoxComponent name={key} key={key} />
      }
    })

    return (
      <DndProvider backend={HTML5Backend}>
        <div style={{ overflow: 'hidden', clear: 'both' }}>{devices}</div>
      </DndProvider>
    )
  }
}
