import React, { Component } from 'react'
import { observer } from 'mobx-react'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import role from '../../../config/role'
import UserModel from '../../../Models/UserModel'
import { FormControl, InputLabel } from '@material-ui/core'

/**
 * Dropdown component with available roles.
 */
@observer
class RoleSelectComponent extends Component {
  constructor(props) {
    super(props)

    this.state = {
      userId: this.props.id,
      selectedRoleId: this.props.value,
    }
  }

  /**
   * Handles role switch.
   *
   * @param event Event.
   * @param key The index of the selected menu item.
   * @param value The value of the selected menu item.
   */
  handleRoleChange = (event) => {
    this.setState({
      selectedRoleId: event.target.value,
    })

    this.props.onChange &&
      this.props.onChange(this.props.id, event.target.value)
  }

  render() {
    const roles = Object.keys(role)

    const rolesAvailable = roles.filter((item) => {
      return (
        UserModel.isAdmin ||
        UserModel.isOwner ||
        (role[item].id !== 0 && role[item].id !== 1)
      )
    })
    const roleMenu = rolesAvailable.map((key) => {
      return (
        <MenuItem key={role[key].id} value={role[key].id}>
          {role[key].title}
        </MenuItem>
      )
    })

    const selectLabelId = `role-select-${this.state.userId}`

    return (
      <FormControl>
        <InputLabel id={selectLabelId}>Role</InputLabel>
        <Select
          id={this.state.userId}
          labelId={selectLabelId}
          value={this.state.selectedRoleId}
          onChange={this.handleRoleChange}
        >
          {roleMenu}
        </Select>
      </FormControl>
    )
  }
}

export default RoleSelectComponent
