// @flow

import React, { Component } from 'react'
import { observer } from 'mobx-react'
import OrganizationComponent from '../OrganizationComponent'
import { Header } from 'semantic-ui-react'
import CircularProgress from '@material-ui/core/CircularProgress'
import UserModel from '../../../Models/UserModel'
import OrganizationModel from '../../../Models/Organization/OrganizationModel'
import OrganizationUserModel from '../../../Models/Organization/OrganizationUserModel'
import { firebaseApp } from 'src/config/firebase'
import role from '../../../config/role'
import { history } from 'src/config/routing/history'

export default
@observer
class AddComponent extends Component {
  _data: {
    users: Object,
  }

  handleSave() {
    this._data['users'] = {
      [UserModel.userId]: true,
    }
    const organisationUpdate = {
      ['/users/' + UserModel.userId + '/organisation']: UserModel.userId,
      ['/users/' + UserModel.userId + '/role']: role.owner.id,
      ['/organisations/' + UserModel.userId]: this._data,
    }

    return firebaseApp
      .database()
      .ref()
      .update(organisationUpdate)
      .then(() => {
        UserModel.organisationId = UserModel.userId
        UserModel.role = role.owner.id

        OrganizationModel.update()
        OrganizationUserModel.update()

        history.push('/organization/edit')
      })
  }

  render() {
    if (!OrganizationUserModel.isLoaded) return <CircularProgress />

    return (
      <div>
        <Header as="h1">Add New Organization</Header>

        <OrganizationComponent handleSave={this.handleSave} />
      </div>
    )
  }
}
