import axios from 'src/config/axios'

/******************************************** CUSTOMERS ********************************************/

export const getStripeCustomer = (customerId) =>
  axios.get('/stripe/customers', { params: { customerId } })

export const createStripeCustomer = (tokenId, email) =>
  axios.post('/stripe/customers', { tokenId, email })

/******************************************** INVOICES ********************************************/

export const getCustomerInvoices = (customerId) =>
  axios.get('/stripe/invoices', { params: { customerId } })

export const getUpcomingCustomerInvoices = (customerId) =>
  axios.get('/stripe/invoices/upcoming', { params: { customerId } })

/******************************************** SUBSCRIPTIONS ********************************************/

export const getCustomerSubscriptions = (customerId) =>
  axios.get('/stripe/subscriptions', { params: { customerId } })

export const cancelSubscription = (subscriptionId) =>
  axios.delete('/stripe/subscriptions', { params: { subscriptionId } })

export const updateSubscription = (subscriptionId, priceId) =>
  axios.patch('/stripe/subscriptions', { subscriptionId, priceId })

export const createSubscription = (customerId, priceId) =>
  axios.post('/stripe/subscriptions', { customerId, priceId })
