import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'

export let firebaseApp

if (!firebase.apps.length) {
  firebaseApp = firebase.initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    databaseURL: process.env.REACT_APP_FIREBASE_DB_URL,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  })
} else {
  firebaseApp = firebaseApp.getInstance()
}
