// @flow

import React, { Component } from 'react'
import AppBar from '@material-ui/core/AppBar'
import { observer } from 'mobx-react'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import MenuComponent from './MenuComponent'
import header from 'src/Models/Header/Header'

import { Redirect } from 'react-router-dom'
import AuthModel from 'src/Models/AuthModel'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import { Grid, Popover, Typography } from '@material-ui/core'
import { history } from 'src/config/routing/history'
import { ProfilePopup } from 'src/Components/Header/PopupComponent'

@observer
class HeaderComponent extends Component {
  state = {
    redirect: null,
    profileAnchorEl: null,
  }

  handleMenuToggle = () => (header.menuOpened = !header.menuOpened)

  goToAccount = () => this.setState({ redirect: '/account' })

  render() {
    const content = (
      <>
        <Popover
          anchorEl={this.state.profileAnchorEl}
          open={!!this.state.profileAnchorEl}
          onClose={() => this.setState({ profileAnchorEl: null })}
        >
          <ProfilePopup
            isAdmin={AuthModel.isAdmin}
            logout={AuthModel.signOut}
            goToAccount={() => history.push('/account')}
          />
        </Popover>
        <AppBar
          position="static"
          color={AuthModel.isAdmin ? 'secondary' : 'primary'}
        >
          <Toolbar>
            <Grid container alignItems="center">
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={this.handleMenuToggle}
              >
                <MenuIcon />
              </IconButton>
              <img alt="logo" height="50" src="/logo-big.png" />
              {AuthModel.isAdmin && (
                <Typography variant="h5">Super Admin Panel </Typography>
              )}
            </Grid>

            <IconButton
              edge="start"
              color="inherit"
              aria-label="profile"
              onClick={(e) =>
                this.setState({ profileAnchorEl: e.currentTarget })
              }
            >
              <AccountCircleIcon fontSize="large" />
            </IconButton>
            <img alt="logo" width="45" height="45" src="/logo-bfs.png" />
          </Toolbar>
        </AppBar>
      </>
    )

    const menuAppBar = (
      <AppBar
        position="static"
        color={AuthModel.isAdmin ? 'secondary' : 'primary'}
      >
        <Toolbar>
          <Grid container>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={this.handleMenuToggle}
            >
              <MenuIcon />
            </IconButton>
            <img alt="logo" height="50" src="/logo-big.png" />
          </Grid>
        </Toolbar>
      </AppBar>
    )

    return (
      <div>
        {content}
        <MenuComponent appBar={menuAppBar} />
        {this.state.redirect && <Redirect push to={this.state.redirect} />}
      </div>
    )
  }
}

export default HeaderComponent
