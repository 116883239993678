import { observable, transaction, computed } from 'mobx'
import { firebaseApp } from 'src/config/firebase'

import userModel from '../UserModel'
import FreeUserAccess from '../../config/freeUserAccess'

class ViewModel {
  @observable _loaded = false
  _records = []
  @observable _version = 0
  _study = null
  _studyData = null
  fetchData = {}

  @computed get isLoaded() {
    return this._loaded
  }

  get records() {
    return this._records.slice()
  }

  get studyData() {
    return this._studyData
  }

  @computed get version() {
    return this._version
  }

  update() {
    this._loaded = false

    const ref = firebaseApp
      .database()
      .ref(
        `/records/${this.fetchData.userId}/${this.fetchData.databaseId}/${this.fetchData.study}`
      )
      .orderByChild('time')

    ref.on('value', (snapshot) => {
      let recordsList = []
      let count = 1

      snapshot.forEach((childSnapshot) => {
        let childData = childSnapshot.val()

        const isNew =
          this._records.length > 0 &&
          recordsList.length + 1 > this._records.length

        recordsList.push({
          ...childData,
          key: childSnapshot.key,
          recordId: childSnapshot.key,
          isNew,
          orderId: count++,
        })
      })

      if (!userModel.stripeCustomer.hasActiveAccount) {
        recordsList = recordsList.slice(0, FreeUserAccess.records)
      }

      firebaseApp
        .database()
        .ref(
          `/studies/${this.fetchData.userId}/${this.fetchData.databaseId}/${this.fetchData.study}`
        )
        .once('value')
        .then((snap) => {
          transaction(() => {
            this._version++
            this._records = recordsList
            this._studyData = snap.val()
            this._loaded = true
          })
        })
    })
  }

  addTestRecord(num) {
    this._records = this._records.map((item) => ({ ...item, isNew: false }))
    const record = this._records[this._records.length - 1]

    const nums = num || 1

    for (let i = 0; i < nums; i++) {
      this._records.push({
        ...record,
        recordId: record.recordId + i + 1,
        isNew: true,
      })
    }

    this._version++
  }
}

export default ViewModel
