import React, { Component } from 'react'
import { Segment, Divider } from 'semantic-ui-react'
import MonthlySubscription from './MonthlyComponent'
import YearlyComponent from './YearlyComponent'
import UserModel from '../../../Models/UserModel'
import { firebaseApp } from 'src/config/firebase'
import InvoiceModel from 'src/Models/Payment/Stripe/InvoiceModel'
import DevicesListModel from 'src/Models/Device/ListModel'
import DeviceInfo from '../../Admin/User/Edit/Device/DeviceInfo'

export default class SubscriptionComponent extends Component {
  handleSubscriptionChange = (subscriptionId) => {
    const { deviceId } = this.props

    DevicesListModel.setDeviceSubscription(deviceId, subscriptionId)

    // Load actual stripe customer data after subscription create.
    UserModel.stripeCustomer.loadData().then(() => {
      const subscription = UserModel.stripeCustomer.getSubscriptionById(
        subscriptionId
      )
      const device = DevicesListModel.getDevice(deviceId)
      const deviceInfo = new DeviceInfo(device.activationCode, device.id)

      deviceInfo.setData(
        subscription['current_period_end'] * 1000,
        deviceInfo.activation_date,
        deviceInfo.trial_date,
        true
      )

      const update = {
        activationCode: deviceInfo.activationCode,
        subscriptionId: subscriptionId,
      }

      firebaseApp
        .database()
        .ref('/users/' + UserModel.userId + '/devices/' + deviceId)
        .update(update)
        .then(() => {
          InvoiceModel.subscriptionId = subscriptionId
          // On subscription create there will be new invoice added so we need to reload data.
          InvoiceModel.loadData()
        })
    })
  }

  render() {
    const { subscription } = this.props

    return (
      <div>
        <Segment padded textAlign="center">
          <MonthlySubscription
            planId={
              process.env.REACT_APP_STRIPE_DCSLS_PRICE_ID_MONTHLY ??
              'android-app-monthly'
            }
            subscription={subscription}
            callback={this.handleSubscriptionChange}
          />
          <Divider horizontal>Or</Divider>
          <YearlyComponent
            planId={
              process.env.REACT_APP_STRIPE_DCSLS_PRICE_ID_YEARLY ??
              'android-app-yearly'
            }
            subscription={subscription}
            callback={this.handleSubscriptionChange}
          />
        </Segment>
      </div>
    )
  }
}
