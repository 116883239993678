// @flow
import { observable, computed, transaction } from 'mobx'
import { firebaseApp } from 'src/config/firebase'
import userModel from 'src/Models/UserModel'
import AbstractStore from '../AbstractStore'

class RecordsNumStudyStore extends AbstractStore {
  @observable _recordsNum: number = 0
  _studyId: string

  constructor(studyId: string) {
    super()
    this._studyId = studyId
  }

  @computed
  get recordsNum(): number {
    return this._recordsNum
  }

  update() {
    this.isLoading = true

    firebaseApp
      .database()
      .ref(
        `/records/${userModel.userId}/${userModel.databaseId}/${this._studyId}`
      )
      .on('value', (snapshot) => {
        transaction(() => {
          this.isLoading = false
          this._recordsNum = snapshot.numChildren()
        })
      })
  }
}

export default RecordsNumStudyStore
