import { observable, transaction, computed } from 'mobx'
import { firebaseApp } from 'src/config/firebase'
import userModel from '../UserModel'

class ListObjectModel {
  @observable _loaded = false
  @observable _devices = []

  @computed get isLoaded() {
    return this._loaded
  }

  @computed get devices() {
    return this._devices.slice()
  }

  /**
   * Resets loaded flag
   * This was added to reset _loaded flag before any acync API calls, to implement
   * dimmer overlay while data is loading. Regular data load is called after several API calls and it
   * freezes the page without any activity and cause bad customer experience.
   */
  reset() {
    this._loaded = false
  }

  getDevice(deviceId) {
    return this._devices.find((deviceData) => {
      return deviceData.key === deviceId
    })
  }

  setDeviceSubscription(deviceId, subscriptionId) {
    const device = this.getDevice(deviceId)
    device['subscriptionId'] = subscriptionId
  }

  update(userId = userModel.userId) {
    this._loaded = false

    firebaseApp
      .database()
      .ref('/users/' + userId + '/devices')
      .once('value')
      .then((snapshot) => {
        let devicesList = []

        snapshot.forEach((childSnapshot) => {
          let childData = childSnapshot.val()
          childData.key = childSnapshot.key
          devicesList.push(childData)
        })

        transaction(() => {
          this._devices = devicesList
          this._loaded = true
        })
      })
  }
}

export default ListObjectModel
