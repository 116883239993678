import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { Card, Grid, Header } from 'semantic-ui-react'
import OrganizationUserModel from 'src/Models/Organization/OrganizationUserModel'
import UserListModel from 'src/Models/Admin/User/ListModel'
import CircularProgress from '@material-ui/core/CircularProgress'
import Role from '../../config/role'
import { Button, Image } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import UserModel from 'src/Models/UserModel'
import AddComponent from '../../Components/Organization/Item/AddComponent'
import Identicon from 'identicon.js'
import CryptoJs from 'crypto-js'

export default
@observer
class ViewComponent extends Component {
  userListModel = null

  constructor(props) {
    super(props)
    this.userListModel = new UserListModel()
  }

  componentDidMount() {
    OrganizationUserModel.update()
    this.userListModel.update()
  }

  render() {
    if (!OrganizationUserModel.isLoaded || !this.userListModel.isLoaded)
      return <CircularProgress />

    if (UserModel.organisationId === null) return <AddComponent />

    const users = OrganizationUserModel.users
    const usersData = this.userListModel.users

    const elements = users.map((element) => {
      const dataCurrent = usersData.find((userDataCurrent) => {
        return userDataCurrent.key === element
      })

      const emailHash = CryptoJs.MD5(dataCurrent.email).toString()
      const dataImage = new Identicon(emailHash, 420).toString()

      const firstName = dataCurrent.firstName || ''
      const lastName = dataCurrent.lastName || ''
      const name = firstName + ' ' + lastName

      return (
        <Grid.Column key={dataCurrent.key}>
          <Card>
            <Image src={`data:image/png;base64,${dataImage}`} />
            <Card.Content>
              <Card.Header>{name.length > 1 ? name : 'Unnamed'}</Card.Header>
              <Card.Meta>
                <div>{dataCurrent.email}</div>
                <div>{Role.getTitleById(dataCurrent.role)}</div>
              </Card.Meta>
            </Card.Content>
            <Card.Content extra>
              <a href={`mailto:${dataCurrent.email}`}>Send email</a>
            </Card.Content>
          </Card>
        </Grid.Column>
      )
    })

    let editButton = null
    if (UserModel.canManageOrganization) {
      editButton = (
        <Link
          to="/organization/edit"
          style={{ marginBottom: '20px', display: 'inline-block' }}
        >
          <Button color="violet">Manage organization</Button>
        </Link>
      )
    }

    return (
      <div style={{ margin: '16px 0 0 16px' }}>
        <Header as="h1">
          <span>Organization: {OrganizationUserModel.orgName}</span>
        </Header>
        {editButton}
        <Grid columns={Math.min(16, Math.ceil(window.innerWidth / 250))}>
          {elements}
        </Grid>
      </div>
    )
  }
}
