import React from "react"
import { Route, Switch, Redirect } from "react-router"

import UsersViewComponent from '../../Components/Admin/User/View/ViewComonent'
import UserEditComponent from '../../Components/Admin/User/Edit/EditComponent'
import PromoListComponent from '../../Components/Admin/Promo/ListComponent'
import OrganizationListComponent from "../../Components/Admin/Organization/OrganizationListComponent"


export const AdminRoutes = () =>
  <Switch>
    <Route exact path="/admin/users" component={UsersViewComponent} />
    <Route path="/admin/users/:user" render={({ match }) =>
      <UserEditComponent user={match.params.user} />} />
    <Route path="/admin/promo" component={PromoListComponent} />
    <Route path="/admin/org" component={OrganizationListComponent} />
    <Redirect push path="/" to="/admin/users" />
  </Switch>
