import { firebaseApp } from 'src/config/firebase'

const FirebaseCollections = {
  STUDIES: 'studies',
  RECORDS: 'records',
  BATCHES: 'batches',
  BATCH_RECORDS: 'batchRecords',
}

export async function getBatchesForStudy({ userId, studyId, databaseId }) {
  const batchesSnapshot = await firebaseApp
    .database()
    .ref(FirebaseCollections.BATCHES)
    .child(`${userId}/${databaseId}/${studyId}`)
    .once('value')

  const batchesByBuid = Object.values(batchesSnapshot.val()).map((b) => [
    b.buid,
    {
      buid: b.buid,
      name: b.name,
      selected: b.selected,
    },
  ])

  return new Map(batchesByBuid)
}

export async function getRecordGroupedByBatch({ userId, studyId, databaseId }) {
  const recordSnapshot = await firebaseApp
    .database()
    .ref(FirebaseCollections.RECORDS)
    .child(`${userId}/${databaseId}/${studyId}`)
    .once('value')

  const batchesSnapshot = await firebaseApp
    .database()
    .ref(FirebaseCollections.BATCH_RECORDS)
    .child(`${userId}/${databaseId}/${studyId}`)
    .once('value')

  const recordsByRuid = Object.entries(recordSnapshot.val()).map(
    ([ruid, record]) => [
      ruid,
      {
        ruid: ruid,
        count: record.cound,
        time: record.time,
        history: record.history,
        values: record.values,
      },
    ]
  )

  const recordMap = new Map(recordsByRuid)

  const batchRecordMap = Object.entries(
    batchesSnapshot.val()
  ).map(([buid, records]) => [
    buid,
    Object.keys(records).map((ruid) => recordMap.get(ruid)),
  ])

  return new Map(batchRecordMap)
}

export async function loadStudyWithBatches({ userId, studyId, databaseId }) {
  const study = await loadStudy({ userId, studyId, databaseId })
  const batches = await getBatchesForStudy({ userId, studyId, databaseId })
  const records = await getRecordGroupedByBatch({ userId, studyId, databaseId })

  return {
    batches,
    records,
    study,
  }
}

export async function loadStudy({ userId, studyId, databaseId }) {
  const studySnapshot = await firebaseApp
    .database()
    .ref(FirebaseCollections.STUDIES)
    .child(`${userId}/${databaseId}/${studyId}`)
    .once('value')
  return studySnapshot.val()
}
