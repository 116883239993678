import Clipboard from 'clipboard'

import React, { Component } from 'react'
import { Button, Form, Input, Modal, Table } from 'semantic-ui-react'
import Device from './DeviceInfo'
import { DatePicker } from '@material-ui/pickers'
export default class DeviceElementComponent extends Component {
  state = {
    isSaving: false,
    isDeleteDBModalOpened: false,
    isGenCodeModalOpened: false,
  }

  formData = {}
  deviceObject = null

  constructor(props) {
    super(props)

    const { activationCode = '', id = '' } = props.value
    this.deviceObject = new Device(activationCode, id)
    this.value = props.value.id
    this.state = {
      ...this.state,
      untilActivated: new Date(this.deviceObject.until_activated),
      activationDate: new Date(this.deviceObject.activation_date),
      trialDate: new Date(this.deviceObject.trial_date),
      activated: this.deviceObject.activated,
    }
  }

  componentDidMount() {
    new Clipboard('.clipboard-container')
  }

  onSaveData() {
    this.deviceObject.setData(
      this.state.untilActivated.valueOf(),
      this.state.activationDate.valueOf(),
      this.state.trialDate.valueOf(),
      this.state.activated
    )
    this.props.editModel.setDeviceData(
      this.props.keyProp,
      this.deviceObject.activationCode,
      this.deviceObject.deviceId
    )
  }

  toggleGenCodeModal = () =>
    this.setState({
      ...this.state,
      isGenCodeModalOpened: !this.state.isGenCodeModalOpened,
    })

  toggleDeleteDBModal = () =>
    this.setState({
      ...this.state,
      isDeleteDBModalOpened: !this.state.isDeleteDBModalOpened,
    })

  onSaveId = () => {
    this.setState({ ...this.state, isSaving: true })
    this.deviceObject.setDeviceId(this.value)
    this.props.editModel
      .setDeviceData(
        this.props.keyProp,
        this.deviceObject.activationCode,
        this.deviceObject.deviceId
      )
      .then(() => {
        this.setState({
          isSaving: false,
        })
      })
  }

  deleteUserDb = () => this.props.editModel.deleteDevice(this.props.keyProp)

  render() {
    const { value } = this.props

    const options = [
      {
        key: 'true',
        text: 'True',
        value: 'true',
      },
      {
        key: 'false',
        text: 'False',
        value: 'false',
      },
    ]

    const activationCodeShort = value.activationCode
      ? value.activationCode.substr(0, 35) + '...'
      : ''

    return (
      <Table.Row>
        <Table.Cell width="8">{value.databaseId}</Table.Cell>
        <Table.Cell width="8">
          <Input
            action={{
              color: 'blue',
              labelPosition: 'right',
              icon: 'save',
              content: 'Save',
              loading: this.state.isSaving,
              onClick: this.onSaveId,
            }}
            onChange={(event, inputProps) => {
              this.value = inputProps.value
            }}
            defaultValue={this.value}
            fluid
            style={{ width: '400px' }}
          />
        </Table.Cell>
        <Table.Cell>
          <div style={{ width: '400px' }}>
            {activationCodeShort}
            <Button
              icon="clipboard"
              style={{ marginLeft: '10px' }}
              className="clipboard-container"
              data-clipboard-text={value.activationCode}
            />
          </div>
        </Table.Cell>
        <Table.Cell>
          <div style={{ width: '200px' }}>
            <Button onClick={this.toggleGenCodeModal} color="purple">
              Gen Code
            </Button>
            <Button onClick={this.toggleDeleteDBModal} color="red">
              Delete
            </Button>
            {/* Gen code  modal */}
            <Modal dimmer="inverted" open={this.state.isGenCodeModalOpened}>
              <Modal.Header>Generate activation code</Modal.Header>
              <Modal.Content image>
                <Form>
                  <Form.Field inline>
                    <DatePicker
                      format="MM/DD/YYYY"
                      label="Expiration Date"
                      onChange={(date) => {
                        this.setState({ untilActivated: date })
                      }}
                      value={this.state.untilActivated}
                    />
                  </Form.Field>
                  <Form.Field inline>
                    <DatePicker
                      format="MM/DD/YYYY"
                      label="Activation Date"
                      onChange={(date) => {
                        this.setState({ activationDate: date })
                      }}
                      value={this.state.activationDate}
                    />
                  </Form.Field>
                  <Form.Field inline>
                    <DatePicker
                      format="MM/DD/YYYY"
                      label="Trial Date"
                      onChange={(date) => {
                        this.setState({ trialDate: date })
                      }}
                      value={this.state.trialDate}
                    />
                  </Form.Field>
                  <Form.Field inline>
                    <Form.Select
                      label="Advanced"
                      options={options}
                      placeholder="Advanced"
                      defaultValue={this.state.activated ? 'true' : 'false'}
                      onChange={(event, select) =>
                        this.setState({ activated: select.value === 'true' })
                      }
                    />
                  </Form.Field>
                </Form>
              </Modal.Content>
              <Modal.Actions>
                <Button onClick={this.toggleGenCodeModal}>Cancel</Button>
                <Button
                  onClick={() => this.onSaveData()}
                  positive
                  icon="save"
                  labelPosition="right"
                  content="Generate"
                />
              </Modal.Actions>
            </Modal>
            {/* DB Delete confirm modal */}
            <Modal open={this.state.isDeleteDBModalOpened}>
              <Modal.Header>Delete user's database</Modal.Header>
              <Modal.Content>
                Are you sure you want to delete this user's database? This
                action cannot be undone.
              </Modal.Content>
              <Modal.Actions>
                <Button onClick={this.toggleDeleteDBModal}>Cancel</Button>
                <Button
                  onClick={() => {
                    this.deleteUserDb()
                    this.toggleDeleteDBModal()
                  }}
                  negative
                  content="Delete"
                />
              </Modal.Actions>
            </Modal>
          </div>
        </Table.Cell>
      </Table.Row>
    )
  }
}
