// @flow

import React, { Component } from 'react'
import { Table } from 'semantic-ui-react'
import { observer } from 'mobx-react'
import OrganizationModel from '../../../../Models/Organization/OrganizationModel'
import CircularProgress from '@material-ui/core/CircularProgress'

import EditModel from '../../../../Models/Admin/User/EditModel'
import RoleSelectComponent from '../../../Account/List/RoleSelectComponent'

type Props = {
  editModel: EditModel,
}

@observer
class InfoComponent extends Component {
  editModel: EditModel

  constructor(props: Props) {
    super(props)

    this.editModel = props.editModel
  }

  componentDidMount() {
    const data = this.editModel.data
    OrganizationModel.update(data.organisation)
  }

  render() {
    if (!OrganizationModel.isLoaded) return <CircularProgress />

    const data = this.editModel.data

    return (
      <div style={{ paddingTop: '15px' }}>
        <Table basic="very" celled collapsing>
          <Table.Body>
            <Table.Row>
              <Table.Cell width="4">Email</Table.Cell>
              <Table.Cell width="8">{data.email}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell width="4">Organization</Table.Cell>
              <Table.Cell width="8">
                {data.organisation ? OrganizationModel.nameGet : ''}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell width="4">Role</Table.Cell>
              <Table.Cell width="8">
                {data.role !== undefined && data.organisation ? (
                  <RoleSelectComponent
                    id={this.editModel.user}
                    value={data.role}
                    onChange={(id, value) => {
                      this.editModel.setRole(value)
                    }}
                  />
                ) : (
                  'User without organization'
                )}
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </div>
    )
  }
}

export default InfoComponent
