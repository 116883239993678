import { observable, computed } from 'mobx'
import mainMenu from 'src/config/menu'
import adminMenu from 'src/config/menu/admin'
import leaderMenu from 'src/config/menu/leader'
import authModel from 'src/Models/AuthModel'
import userModel from 'src/Models/UserModel'

class Header {
  @observable opened = false
  @observable itemSelected = '1'

  @computed get menuOpened() {
    return this.opened
  }

  set menuOpened(value) {
    this.opened = value
  }

  get menuItems() {
    let menu = []

    if (authModel.isAdmin) return adminMenu
    if (userModel.isOwner) menu = menu.concat(leaderMenu)

    return menu.concat(mainMenu)
  }

  @computed get menuItemSelected() {
    return this.itemSelected
  }

  set menuItemSelected(value) {
    this.itemSelected = value
  }

  get title() {
    if (authModel.isAdmin) return 'Admin Center'

    return 'DCS-Unity'
  }
}

export default new Header()
