import React, { Component } from 'react'
import UserComponent from './UserComponent'
import UserModel from 'src/Models/UserModel'
import Role from 'src/config/role'
import { Header } from 'semantic-ui-react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { observer } from 'mobx-react'
import ListModel from 'src/Models/Admin/User/ListModel'

export default
@observer
class ListComponent extends Component {
  constructor(props) {
    super(props)

    this.orgModel = new ListModel()
  }

  componentDidMount() {
    this.orgModel.update(UserModel.organisationId)
  }

  render() {
    if (!this.orgModel.isLoaded) {
      return <CircularProgress />
    }

    let content = ''
    const role = UserModel.role

    if (role === Role.owner.id || role === Role.deviceManager.id) {
      const users = this.orgModel.users
      content = users.map((item) => {
        return (
          <div key={item.key} style={{ paddingBottom: '30px' }}>
            <Header as="h3">User: {item.email}</Header>
            <UserComponent
              userID={item.key}
              isOwn={UserModel.userId === item.key}
            />
          </div>
        )
      })
    } else {
      content = <UserComponent userID={UserModel.userId} isOwn={true} />
    }

    return (
      <div>
        <Header as="h1">DCSLinkStream Licenses Page</Header>
        {content}
      </div>
    )
  }
}
