import React from 'react'
import Component from './Component'
import UserModel from '../../Models/UserModel'
import { observer } from 'mobx-react'
import StudiesListModel from 'src/Models/Study/ListModel'
import EnhancedTableToolbar from './StudyListToolbar/EnchancedTableToolbar'
import EnchancedRemoveTableToolbar from './StudyListInsideCollectionToolbar/EnchancedRemoveTableToolbar'
import CollectionListToolbar from './CollectionListToolbar/CollectionListToolbar'
import StudyRow from './StudyRow'
import CollectionRow from './CollectionRow'
import CollectionListModel from 'src/Models/Collection/ListModel'
import { Redirect } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'
import { Button, Modal } from 'semantic-ui-react'

@observer
class StudyAndCollectionList extends React.Component {
  state = {
    modalHasBeenClosedOnce: false,
    redirectTo: null,
  }

  constructor(props) {
    super(props)
    if (props.isCollectionList) {
      this.listModel = CollectionListModel
    } else {
      this.listModel = new StudiesListModel()
      this.listModel.collection = props.collection
    }
  }

  componentDidMount() {
    this.listModel.update()
  }

  render() {
    const canShare =
      UserModel.organisationId && UserModel.stripeCustomer.hasActiveAccount
    const columnData = []
    let data = []
    let enhancedTableToolbar = null
    let customRow = null
    let handleClickRow = null

    if (this.props.isCollectionList) {
      data = this.listModel.collections
      customRow = CollectionRow
      handleClickRow = (collectionId) =>
        this.setState({
          ...this.state,
          redirectTo: `/studies/collection/${collectionId}`,
        })
      enhancedTableToolbar = CollectionListToolbar

      columnData.push(
        {
          id: 'name',
          key: 'name',
          numeric: false,
          label: 'Name',
          hasSort: true,
        },
        {
          id: 'numStudies',
          key: 'numStudies',
          numeric: false,
          label: '# of studies',
          hasSort: true,
        },
        {
          id: 'numRecords',
          key: 'numRecords',
          numeric: false,
          label: '# of records',
        },
        {
          id: 'creationDate',
          key: 'creationDate',
          numeric: false,
          label: 'Creation date',
          hasSort: true,
        }
      )
    } else {
      data = this.listModel.studies

      customRow = StudyRow
      handleClickRow = (studyId) =>
        this.setState({ ...this.state, redirectTo: `/studies/${studyId}` })

      if (this.props.collection) {
        enhancedTableToolbar = EnchancedRemoveTableToolbar
      } else {
        enhancedTableToolbar = EnhancedTableToolbar
      }
      columnData.push(
        {
          id: 'name',
          key: 'name',
          numeric: false,
          label: 'Name',
          hasSort: true,
        },
        {
          id: 'numRecords',
          key: 'numRecords',
          numeric: false,
          label: '# of records',
        }
      )

      if (canShare) {
        columnData.push({
          id: 'share',
          key: 'share',
          numeric: false,
          label: 'Share Across Organization',
        })
      }
    }

    return (
      <div style={{ marginLeft: '15px', marginRight: '15px' }}>
        <Modal
          open={
            this.listModel.loadedStudiesRequireUpdate === true &&
            this.state.modalHasBeenClosedOnce === false
          }
        >
          <Modal.Header>DCSUnity Upgrade Notice</Modal.Header>
          <Modal.Content>
            DCSUnity has been undergone significant improvements and some of
            your studies need to be updated in order to become available in your
            DCSUnity account. To update your studies:
            <ul>
              <li>
                Make sure you have an internet connection on your tablet on
                which you run DCSLinkstream
              </li>
              <li>Update DCSLinkstream to Version 1.9 or later.</li>
              <li>
                Launch DCSLinkstream and wait for the auto-update process to
                complete.
              </li>
            </ul>
            Questions? Please write to: support@bigfinscientific.com
          </Modal.Content>
          <Modal.Actions>
            <Button
              onClick={() => this.setState({ modalHasBeenClosedOnce: true })}
            >
              Ok
            </Button>
          </Modal.Actions>
        </Modal>
        <ReactTooltip />
        <Component
          data={data}
          canShare={canShare}
          columnData={columnData}
          listModel={this.listModel}
          enhancedTableToolbar={enhancedTableToolbar}
          CustomRow={customRow}
          handleClickRow={handleClickRow}
        />
        {this.state.redirectTo && <Redirect push to={this.state.redirectTo} />}
      </div>
    )
  }
}

export default StudyAndCollectionList
