/**
 * Roles object.
 *
 * @type {{owner: {id: number, title: string}, billingManager: {id: number, title: string}, dataManager: {id: number, title: string}, deviceManager: {id: number, title: string}, user: {id: number, title: string}}}
 */
class Role {
  owner = {
    id: 0,
    title: 'Owner'
  };
  billingManager = {
    id: 1,
    title: 'Org Manager'
  };
  dataManager = {
    id: 2,
    title: 'Data Manager'
  };
  deviceManager = {
    id: 3,
    title: 'Device Manager'
  };
  user = {
    id: 4,
    title: 'Standard user'
  };

  getRoleById(id)
  {
    switch(id)
    {
      case 0:
        return 'owner';
      case 1:
        return 'billingManager';
      case 2:
        return 'dataManager';
      case 3:
        return 'deviceManager';
      case 4:
      default:
        return 'user';
    }
  }

  getTitleById(id)
  {
    const role = this.getRoleById(id);
    return this[role].title;
  }
}

export default new Role();