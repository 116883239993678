import { observable, transaction, computed } from 'mobx'
import { firebaseApp } from 'src/config/firebase'

import userModel from '../UserModel'
import FreeUserAccess from '../../config/freeUserAccess'

class ListModel {
  _collection = null
  @observable _loaded = false
  @observable _studies = []
  @observable _loadedStudiesRequireUpdate = false

  set collection(collection) {
    this._collection = collection
  }

  get collection() {
    return this._collection
  }

  @computed get loadedStudiesRequireUpdate() {
    return this._loadedStudiesRequireUpdate
  }

  @computed get isLoaded() {
    return this._loaded
  }

  @computed get studies() {
    return this._studies.slice()
  }

  get studiesCount() {
    return this._studies.slice().length
  }

  async update() {
    this._loaded = false
    try {
      await firebaseApp
        .database()
        .ref(`/studies/${userModel.userId}/${userModel.databaseId}`)
        .on('value', (snapshot) => {
          let studiesList = []

          snapshot.forEach((childSnapshot) => {
            const childData = childSnapshot.val()
            const collection = childData.belongsToCollection || null
            if (this._collection !== collection) return

            const studySchemaVersion = childData.version
            if (studySchemaVersion !== 2) {
              this._loadedStudiesRequireUpdate = true
            }

            childData.key = childSnapshot.key
            studiesList.push(childData)
          })

          if (!userModel.stripeCustomer.hasActiveAccount) {
            studiesList = studiesList.slice(0, FreeUserAccess.studies)
          }

          transaction(() => {
            this._studies = studiesList
            this._loaded = true
          })
        })
    } catch (err) {
      console.error(err)
      throw new Error(err)
    }
  }

  /**
   * Changes study data.
   *
   * @param userId User ID to change study for.
   * @param databaseId User database name in which study will be changed.
   * @param {String} key Key of study.
   * @param {Object} data Study data.
   * @returns {firebaseApp.Promise.<void>}
   */
  change(userId, databaseId, key, data) {
    return firebaseApp
      .database()
      .ref(`/studies/${userId}/${databaseId}/${key}`)
      .update(data)
  }
}

export default ListModel
