import { observable, transaction, computed } from 'mobx'
import { firebaseApp } from 'src/config/firebase'

import userModel from '../UserModel'

class ViewModel {
  _collectionKey = null
  @observable _loaded = false
  @observable _name = ''

  @computed get isLoaded() {
    return this._loaded
  }

  @computed get name() {
    return this._name
  }

  set collectionKey(key) {
    return (this._collectionKey = key)
  }

  update() {
    this._loaded = false

    return firebaseApp
      .database()
      .ref(
        '/collections/' +
          userModel.userId +
          '/' +
          userModel.databaseId +
          '/' +
          this._collectionKey
      )
      .once('value')
      .then((snapshot) => {
        let val = snapshot.val()

        transaction(() => {
          this._name = val.name
          this._loaded = true
        })
      })
  }
}

export default ViewModel
