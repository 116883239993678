import React, { Component } from 'react'
import { observer } from 'mobx-react'
import CircularProgress from '@material-ui/core/CircularProgress'

import Dialog from '@material-ui/core/Dialog'
import Button from '@material-ui/core/Button'
import Chip from '@material-ui/core/Chip'
import { Form, Grid } from 'semantic-ui-react'
import Snackbar from '@material-ui/core/Snackbar'
import { Link } from 'react-router-dom'
import BatchesListComponent from './BatchesListComponent'
import { history } from 'src/config/routing/history'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import { Switch, FormControlLabel } from '@material-ui/core'
import { Button as OldButton } from 'semantic-ui-react'
@observer
class FilterComponent extends Component {
  constructor(props) {
    super(props)

    this.variableListModel = props.variableListModel

    this.state = {
      open: false,
      openImport: false,
      isAlert: false,
    }

    this.styles = {
      chip: {
        margin: 4,
      },
      wrapper: {
        display: 'inline-flex',
        position: 'absolute',
      },
    }
  }

  handleAlertOpen = () => {
    this.setState({
      isAlert: true,
    })
  }

  handleAlertClose = () => {
    this.setState({
      isAlert: false,
    })
  }

  handleRequestDelete = (key) => {
    this.variableListModel.setFilterVariable(key, '')
    this.variableListModel.chipsSet()
    this.props.onFilterFieldChange()
  }

  renderChip(data) {
    return (
      <Chip
        key={data.key}
        label={data.label}
        onClick={() => this.handleRequestDelete(data.key)}
        style={this.styles.chip}
      />
    )
  }

  handleOpen = () => {
    this.setState({ open: true })
  }

  handleOpenImport = () => {
    this.setState({ openImport: true })
  }

  handleOpenImportNew = () => {
    history.push(`/studies/${this.props.studyID}/import`)
  }

  handleCloseImport = () => {
    this.setState({ openImport: false })
  }

  handleClose = () => {
    this.setState({
      open: false,
    })

    this.props.onFilterFieldChange()

    this.variableListModel.chipsSet()
  }

  handleSet = (name, isSelect) => {
    this.variableListModel.setSelectedVariable(name, isSelect)
  }

  render() {
    if (!this.variableListModel.isLoaded) return <CircularProgress />

    const selected = this.variableListModel.selected

    const rows = Object.keys(this.variableListModel.variables).map((key) => {
      const item = this.variableListModel.variables[key]

      let input
      if (item.selections) {
        let options = [{ text: 'Filter by ' + item.displayedName, value: '' }]

        // eslint-disable-next-line
        item.selections.map((value) => {
          options.push({
            text: 'Filter by ' + value,
            value: value.toLowerCase(),
          })
        })

        input = (
          <Form.Select
            options={options}
            onChange={(event, select) => {
              this.variableListModel.setFilterVariable(item.name, select.value)
            }}
            defaultValue={this.variableListModel.getFilterVariable(item.name)}
            placeholder={'Filter by ' + item.displayedName}
            style={{ width: '300px' }}
          />
        )
      } else {
        input = (
          <Form.Input
            onChange={(event) => {
              const value = !event.target.value
                ? ''
                : event.target.value.toLowerCase()
              this.variableListModel.setFilterVariable(item.name, value)
            }}
            defaultValue={this.variableListModel.getFilterVariable(item.name)}
            placeholder={'Filter by ' + item.displayedName}
            style={{ width: '300px' }}
          />
        )
      }

      return (
        <div key={item.name} style={{ minHeight: '40px' }}>
          <FormControlLabel
            label={item.displayedName}
            control={
              <Switch
                color="primary"
                checked={selected[item.name]}
                onChange={(event, isSelect) =>
                  this.handleSet(item.name, isSelect)
                }
              />
            }
          />

          <div style={{ float: 'right' }}>{input}</div>
        </div>
      )
    })

    let chips = ''
    const chipList = this.variableListModel.chips
    if (chipList.length) {
      chips = (
        <div style={this.styles.wrapper}>
          <span style={{ paddingTop: '10px' }}>Filters: </span>
          {chipList.map(this.renderChip, this)}
        </div>
      )
    }

    return (
      <div>
        <Grid columns="equal">
          <Grid.Column>
            <Snackbar
              open={this.state.isAlert}
              message="CSV file will be downloaded automatically"
              autoHideDuration={4000}
              onClose={this.handleAlertClose}
            />

            <div
              style={{
                display: 'inline-block',
                verticalAlign: 'inherit',
                marginTop: '8px',
              }}
            >
              <Link to="/studies">&#8592; Go back</Link>
            </div>

            <OldButton
              icon="list"
              content="Fields"
              color="blue"
              labelPosition="right"
              onClick={this.handleOpen}
              style={{
                marginLeft: '5px',
              }}
            />

            <OldButton
              icon="download"
              content={
                this.props.isBatchFields
                  ? 'Download Batch Records (CSV)'
                  : 'Download Records Datasheet (CSV)'
              }
              color="green"
              labelPosition="right"
              onClick={() => {
                this.handleAlertOpen()
                this.props.onExport()
              }}
              style={{
                marginLeft: '5px',
                marginBottom: '8px',
              }}
            />

            {/* <Modal
              trigger={
                <Button disabled={!this.props.fetchData.isShareEdit} animated='vertical' basic color='purple' onClick={() => { this.handleOpenImport();}} style={{
                  "marginLeft": "5px"
                }}>
                  <Button.Content visible>
                    <Icon name='upload' />
                  </Button.Content>
                  <Button.Content hidden>
                    Import
                  </Button.Content>
                </Button>
              }
              open={this.state.openImport}
              onClose={this.handleCloseImport}
            >
              <Modal.Header>List of imports</Modal.Header>
              <Modal.Content image scrolling>
                <Modal.Description>
                  <ImportListComponent studyID={this.props.studyID} />
                </Modal.Description>
              </Modal.Content>
              <Modal.Actions>
                <Button onClick={this.handleCloseImport}>Close</Button>
                <Button positive labelPosition='right' icon='add' content='Add new import' onClick={this.handleOpenImportNew} />
              </Modal.Actions>
            </Modal> */}

            {chips}
          </Grid.Column>
          
          <Grid.Column>
            {!this.props.isBatchFields && (
              <BatchesListComponent
                batchesModel={this.props.batchesModel}
                isSingle={this.props.isBatchFields}
              />
            )}
          </Grid.Column>
        </Grid>

        <Dialog open={this.state.open} onClose={this.handleClose}>
          <DialogTitle>Select Fields to Display</DialogTitle>
          <DialogContent>
            <Form size="small">{rows}</Form>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose}>Close</Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

export default FilterComponent
