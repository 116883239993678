import { observable, computed } from 'mobx'
import { firebaseApp } from 'src/config/firebase'

class Store {
  @observable _selected = []
  batchesModel = null

  constructor(fetchData, isBatchFields) {
    this.fetchData = fetchData
    this.isBatchFields = isBatchFields
  }

  @computed
  get count() {
    return this.selected.length
  }

  selectedGet(id) {
    return computed(() => this._selected.slice().indexOf(id) >= 0)
  }

  @computed
  get selected() {
    return this._selected.slice()
  }

  selectedSet(id, value) {
    const index = this.selected.indexOf(id)
    if (index >= 0 && !value) {
      this._selected.splice(index, 1)
    } else if (index < 0 && value) {
      this._selected.push(id)
    }
  }

  clearAll() {
    this._selected = []
  }

  _deleteSelected(query) {
    const updates = {}
    this.selected.forEach((item) => {
      updates[item] = null
    })
    return firebaseApp.database().ref(`${query}`).update(updates)
  }

  deleteSelected() {
    if (!this.isBatchFields) {
      const batchesList = this.batchesModel.batchesList

      const promises = batchesList.map((item) => {
        return this._deleteSelected(
          `/batchRecords/${this.fetchData.userId}/${this.fetchData.databaseId}/${this.fetchData.study}/${item.id}`
        )
      })

      promises.push(
        this._deleteSelected(
          `/records/${this.fetchData.userId}/${this.fetchData.databaseId}/${this.fetchData.study}`
        )
      )

      Promise.all(promises).then(() => this.clearAll())
    }
  }
}

export default Store
