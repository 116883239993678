import React, { Component } from 'react'
import { Link } from 'react-router-dom'

export default class ResetCompleteComponent extends Component {
  render() {
    const divStyle = {
      width: '500px',
      height: '350px',
      position: 'absolute',
      top: '0',
      bottom: '0',
      left: '0',
      right: '0',
      margin: 'auto',
      textAlign: 'center',
    }

    const spanStyle = {
      fontSize: '2em',
      verticalAlign: '10px',
    }

    return (
      <div style={divStyle}>
        <span style={spanStyle}>
          A link to reset your password has been sent to your email.
          <br />
        </span>
        <div style={{ paddingTop: '10px', textAlign: 'center' }}>
          <Link to="/login">Back to Sign-in</Link>
        </div>
      </div>
    )
  }
}
