import React from 'react'
import UserModel from 'src/Models/UserModel'
import { Typography, Paper, Button } from '@material-ui/core'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import { makeStyles } from '@material-ui/core/styles'
import AccountBoxIcon from '@material-ui/icons/AccountBox'
const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: theme.spacing(1),
    padding: `${theme.spacing(1)}px 0`,
  },
}))

export const ProfilePopup = ({ isAdmin, logout, goToAccount }) => {
  const styles = useStyles()
  const firstName = UserModel.firstName || ''
  const lastName = UserModel.lastName || ''
  const name = firstName + ' ' + lastName

  return (
    <Paper className={styles.paper}>
      <Typography variant="h5">{UserModel.userEmail}</Typography>
      <Typography variant="subtitle1">
        {name.length > 1 ? name : 'Unnamed'}
      </Typography>
      <div className={styles.actions}>
        <Button
          onClick={logout}
          color="secondary"
          startIcon={<ExitToAppIcon />}
        >
          Logout
        </Button>
        {!isAdmin && (
          <Button
            onClick={goToAccount}
            variant="contained"
            color="primary"
            startIcon={<AccountBoxIcon />}
          >
            My Account
          </Button>
        )}
      </div>
    </Paper>
  )
}
