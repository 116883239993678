import { observable, transaction, computed } from 'mobx'
import { firebaseApp } from 'src/config/firebase'
import userModel from 'src/Models/UserModel'

class ShareModel {
  @observable _loaded = false
  @observable _isShareEdit = false
  @observable _isShareView = false
  _shareId = null
  _study = null

  @computed get isLoaded() {
    return this._loaded
  }

  @computed get isShareEdit() {
    return this._isShareEdit
  }

  @computed get isShareView() {
    return this._isShareView
  }

  set study(value) {
    this._study = value
  }

  update() {
    this._loaded = false

    firebaseApp
      .database()
      .ref('/shareStudies/' + userModel.organisationId)
      .on('value', (snapshot) => {
        const sharedList = snapshot.val()
        if (!sharedList) {
          this._loaded = true
          return
        }

        snapshot.forEach((childSnapshot) => {
          const item = childSnapshot.val()
          if (
            item.userId === userModel.userId &&
            item.databaseId === userModel.databaseId &&
            item.study === this._study
          ) {
            transaction(() => {
              this._shareId = childSnapshot.key
              this._isShareEdit = item.isShareEdit
              this._isShareView = item.isShareView
              this._loaded = true
            })
          }
        })

        this._loaded = true
      })
  }

  setShare(isShareEditClick) {
    this._loaded = false

    const refBase = '/shareStudies/' + userModel.organisationId

    if (!this._shareId) {
      this._shareId = firebaseApp.database().ref(refBase).push().key
    }

    const isShareView = isShareEditClick ? false : !this._isShareView
    const isShareEdit = isShareEditClick ? !this._isShareEdit : false

    const updates = {
      '/study': this._study,
      '/databaseId': userModel.databaseId,
      '/userId': userModel.userId,
      '/isShareView': isShareView,
      '/isShareEdit': isShareEdit,
    }

    firebaseApp
      .database()
      .ref(refBase + '/' + this._shareId)
      .update(updates)
      .then(() => {
        this.update()
      })
    if (!isShareEdit && !isShareView) {
      firebaseApp
        .database()
        .ref(refBase + '/' + this._shareId)
        .remove()
    }
  }
}

export default ShareModel
