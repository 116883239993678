import { observable, transaction, computed } from 'mobx'
import { firebaseApp } from 'src/config/firebase'

export default class BatchesVariableModel {
  _batchVariables = {}
  _study = null
  @observable _loaded = false
  fetchData = {}

  set study(value) {
    this._study = value
  }

  @computed get isLoaded() {
    return this._loaded
  }

  get batchVariables() {
    return this._batchVariables
  }

  update() {
    this._loaded = false

    firebaseApp
      .database()
      .ref(
        `/batchVariables/${this.fetchData.userId}/${this.fetchData.databaseId}/${this.fetchData.study}`
      )
      .once('value')
      .then((snapshot) => {
        transaction(() => {
          snapshot.forEach((childSnapshot) => {
            this._batchVariables[childSnapshot.key] = childSnapshot.val()
          })

          this._loaded = true
        })
      })
  }
}
