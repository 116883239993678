// @flow

import React, { Component } from 'react'
import { Link } from 'react-router-dom'

/**
 * Final step of registration process.
 */
class RegisterCompleteComponent extends Component {
  render() {
    const isPromo = this.props.location.pathname.includes('/register-promo')

    const divStyle = {
      width: '500px',
      height: '350px',
      position: 'absolute',
      top: '0',
      bottom: '0',
      left: '0',
      right: '0',
      margin: 'auto',
      textAlign: 'center',
    }

    const spanStyle = {
      fontSize: '1.4em',
      verticalAlign: '10px',
    }

    const signStyle = {
      fontSize: '0.8em',
      fontWeight: 'bold',
      float: 'right',
      marginRight: '16px',
    }

    const backStyle = {
      paddingTop: '10px',
      textAlign: 'center',
      clear: 'both',
      fontSize: '1.4em',
    }

    return (
      <div style={divStyle}>
        <span style={spanStyle}>
          Thank you for registering for DCSUnity!
          <br />
          <br />
          Before you can log in, we need to verify your email address. Click the
          link in the email we just sent you to complete the verification.
          <br />
          <br />
          See you soon!
          <br />
        </span>
        <div style={signStyle}>– Big Fin Scientific</div>
        <div style={backStyle}>
          {isPromo || <Link to="/login">&larr; Back to Sign-in</Link>}
        </div>
      </div>
    )
  }
}

export default RegisterCompleteComponent
