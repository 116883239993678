import React from 'react'
import { Route, Switch, Redirect } from 'react-router'

import LoginComponent from 'src/Components/Login/LoginComponent'
import RegisterComponent from 'src/Components/Login/RegisterComponent'
import RegisterCompleteComponent from 'src/Components/Login/RegisterCompleteComponent'
import ResetComponent from 'src/Components/Login/ResetComponent'
import ResetCompleteComponent from 'src/Components/Login/ResetCompleteComponent'

export const UnauthenticatedRoutes = () => (
  <Switch>
    <Route path="/login" component={LoginComponent} />
    <Route path="/register" component={RegisterComponent} />
    <Route path="/register" component={RegisterComponent} />
    <Route path="/register/complete" component={RegisterCompleteComponent} />
    <Route path="/register-promo" component={RegisterCompleteComponent} />
    <Route
      path="/register-promo/complete"
      component={RegisterCompleteComponent}
    />
    <Route path="/reset/complete" component={ResetCompleteComponent} />

    <Route path="/reset" component={ResetComponent} />
    <Redirect push to="/login" />
  </Switch>
)
