// @flow

import React, { Component } from 'react'
import { Button, Dimmer, Header, Loader, Table } from 'semantic-ui-react'
import { observer } from 'mobx-react'
import UsersListModel from '../../../Models/Admin/User/ListModel'
import { deleteOrganization } from '../../../Models/Admin/User/DeleteModel'
import CircularProgress from '@material-ui/core/CircularProgress'
import _ from 'lodash'

const styles = {
  wrapper: {
    padding: '10px',
  },
}

class OrganizationItem extends Component {
  render() {
    const { item } = this.props

    return (
      <Table.Row>
        <Table.Cell>{item.name}</Table.Cell>
        <Table.Cell>{item.members}</Table.Cell>
        <Table.Cell>
          <Button
            inverted
            color="orange"
            onClick={() => this.props.handleDeleteOrganization(item.key)}
          >
            Delete org
          </Button>
          <Button
            inverted
            color="red"
            onClick={() =>
              this.props.handleDeleteOrganizationWithAllUsers(item.key)
            }
          >
            Delete org with all users and data
          </Button>
        </Table.Cell>
      </Table.Row>
    )
  }
}

class OrganizationList extends Component {
  state = {
    column: null,
    direction: null,
    isDimmer: false,
  }

  organizations: Array<Object>

  constructor(props) {
    super(props)
    this.organizations = props.organizations
    this.handleDeleteOrganization = this.handleDeleteOrganization.bind(this)
    this.handleDeleteOrganizationWithAllUsers = this.handleDeleteOrganizationWithAllUsers.bind(
      this
    )
  }

  handleDeleteOrganization = (organizationId) => {
    if (
      !window.confirm(
        'Do you really want to delete organization? All users will left in the system.'
      )
    )
      return

    this.setState({
      isDimmer: true,
    })

    deleteOrganization(organizationId, false).then(() => {
      this.setState({
        isDimmer: false,
      })

      this.props.handleUpdate()
    })
  }

  handleDeleteOrganizationWithAllUsers = (organizationId) => {
    if (
      !window.confirm(
        'Do you really want to delete organization and ALL USERS? Users and data will not left in the system'
      )
    )
      return

    this.setState({
      isDimmer: true,
    })

    deleteOrganization(organizationId, true).then(() => {
      this.setState({
        isDimmer: false,
      })

      this.props.handleUpdate()
    })
  }

  handleSort = (clickedColumn) => () => {
    const { column, direction } = this.state

    if (column !== clickedColumn) {
      if (clickedColumn === 'name')
        this.organizations = _.sortBy(this.organizations, [
          (org) => org[clickedColumn].toLowerCase(),
        ])
      else
        this.organizations = _.sortBy(this.organizations, [
          (org) => org[clickedColumn],
        ])

      this.setState({
        column: clickedColumn,
        direction: 'ascending',
      })

      return
    }

    this.organizations.reverse()

    this.setState({
      direction: direction === 'ascending' ? 'descending' : 'ascending',
    })
  }

  render() {
    const { column, direction } = this.state

    const organizationItems = this.organizations.map((item) => {
      return (
        <OrganizationItem
          key={item.key}
          item={item}
          handleDeleteOrganization={this.handleDeleteOrganization}
          handleDeleteOrganizationWithAllUsers={
            this.handleDeleteOrganizationWithAllUsers
          }
        />
      )
    })

    return (
      <div>
        <Dimmer active={this.state.isDimmer}>
          <Loader size="big">Deleting organization</Loader>
        </Dimmer>
        <Table sortable celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell
                sorted={column === 'name' && direction}
                onClick={this.handleSort('name')}
              >
                Name
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === 'members' && direction}
                onClick={this.handleSort('members')}
              >
                Members
              </Table.HeaderCell>
              <Table.HeaderCell>
                <div />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>{organizationItems}</Table.Body>
        </Table>
      </div>
    )
  }
}

@observer
class OrganizationListComponent extends Component {
  listModel: UsersListModel

  constructor(props: {}) {
    super(props)
    this.listModel = new UsersListModel()
  }

  componentDidMount() {
    this.listModel.update()
  }

  render() {
    if (!this.listModel.isLoaded) return <CircularProgress />

    const organizations = this.listModel.organization

    return (
      <div style={styles.wrapper}>
        <Header as="h2">Organization list</Header>
        <OrganizationList
          organizations={organizations}
          handleUpdate={() => this.listModel.update()}
        />
      </div>
    )
  }
}

export default OrganizationListComponent
