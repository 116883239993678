import { firebaseApp } from 'src/config/firebase'
import RoleManager from '../../../config/role'

const deleteOrganisationRecord = (organisationId) => {
  return firebaseApp
    .database()
    .ref('/organisations/' + organisationId)
    .remove()
}

const userListPromise = (organisationId) => {
  return new Promise((resolve) => {
    firebaseApp
      .database()
      .ref('/organisations/' + organisationId + '/users')
      .once('value')
      .then((snapshot) => {
        let userList = []
        snapshot.forEach((childSnapshot) => {
          userList.push(childSnapshot.key)
        })
        resolve(userList)
      })
  })
}

const deleteUser = (userId) => {
  let list = []

  list.push(
    firebaseApp
      .database()
      .ref('/users/' + userId)
      .remove()
  )
  list.push(
    firebaseApp
      .database()
      .ref('/autofills/' + userId)
      .remove()
  )
  list.push(
    firebaseApp
      .database()
      .ref('/collections/' + userId)
      .remove()
  )
  list.push(
    firebaseApp
      .database()
      .ref('/records/' + userId)
      .remove()
  )
  list.push(
    firebaseApp
      .database()
      .ref('/studies/' + userId)
      .remove()
  )
  list.push(
    firebaseApp
      .database()
      .ref('/variables/' + userId)
      .remove()
  )
  list.push(
    firebaseApp
      .database()
      .ref('/batches/' + userId)
      .remove()
  )
  list.push(
    firebaseApp
      .database()
      .ref('/batchRecords/' + userId)
      .remove()
  )

  list.push(deleteUser(userId))

  return Promise.all(list)
}

const removeFromOrganization = (userId, organisationId) => {
  const list = [
    firebaseApp
      .database()
      .ref('/organisations/' + organisationId + '/users/' + userId)
      .remove(),
    firebaseApp
      .database()
      .ref('/users/' + userId + '/role')
      .remove(),
    firebaseApp
      .database()
      .ref('/users/' + userId + '/organisation')
      .remove(),
  ]

  return Promise.all(list)
}

export const deleteOrganization = (organisationId, isDeleteUsers = false) => {
  const funcDelete = isDeleteUsers ? deleteUser : removeFromOrganization

  return new Promise((resolve) => {
    userListPromise(organisationId).then((userList) => {
      const promises = userList.map((userId) => {
        return funcDelete(userId, organisationId)
      })

      promises.push(deleteOrganisationRecord(organisationId))

      Promise.all(promises).then(() => {
        resolve()
      })
    })
  })
}

export default class DeleteModel {
  _userId = null
  _organisationId = null

  constructor(userId, organisationId) {
    this._userId = userId
    this._organisationId = organisationId
  }

  _isLeaderPromise(userId) {
    return new Promise((resolve) => {
      firebaseApp
        .database()
        .ref('/users/' + userId)
        .once('value')
        .then((snapshot) => {
          const val = snapshot.val()
          if (val) {
            const result = val.role === RoleManager.owner.id
            resolve(result)
          } else {
            resolve(false)
          }
        })
    })
  }

  _userListPromise() {
    return userListPromise(this._organisationId)
  }

  deleteFromOrganisation() {
    return firebaseApp
      .database()
      .ref('/organisations/' + this._organisationId + '/users/' + this._userId)
      .remove()
  }

  removeFromOrganisation() {
    return removeFromOrganization(this._userId, this._organisationId)
  }

  deleteUser() {
    return deleteUser(this._userId)
  }

  isLeaderPromise() {
    return this._isLeaderPromise(this._userId)
  }

  isOrganisationWithSingleLeaderPromise() {
    return new Promise((resolve) => {
      this._userListPromise().then((userList) => {
        let leaderCount = 0

        const leaderPromises = userList.map((userId) => {
          return this._isLeaderPromise(userId).then((resultPromise) => {
            if (resultPromise) leaderCount++
          })
        })

        Promise.all(leaderPromises).then(() => {
          resolve(leaderCount <= 1)
        })
      })
    })
  }
}
