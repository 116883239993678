import { observable, transaction, computed } from 'mobx'
import { firebaseApp } from 'src/config/firebase'

class SettingsModel {
  @observable _loaded = false
  @observable _isSync = false
  @observable _hasMenu = false
  _userId = null

  @computed get isLoaded() {
    return this._loaded
  }

  @computed get isSync() {
    return this._isSync
  }

  @computed get hasMenu() {
    return this._hasMenu
  }

  update(userId) {
    this._loaded = false
    this._userId = userId

    firebaseApp
      .database()
      .ref('/users/' + userId)
      .once('value')
      .then((snapshot) => {
        const val = snapshot.val()

        transaction(() => {
          if (val && val.hasOwnProperty('sync')) {
            this._isSync = val.sync
          }

          if (val && val.hasOwnProperty('hasMenu')) {
            this._hasMenu = val.hasMenu
          }

          this._loaded = true
        })
      })
  }

  set isSync(value) {
    firebaseApp
      .database()
      .ref('/users/' + this._userId)
      .update({
        sync: value,
      })
      .then(() => (this._isSync = value))
  }

  set hasMenu(value) {
    firebaseApp
      .database()
      .ref('/users/' + this._userId)
      .update({
        hasMenu: value,
      })
      .then(() => (this._hasMenu = value))
  }
}

export default SettingsModel
