import React, { Component } from 'react'
import { observer } from 'mobx-react'
import Dialog from '@material-ui/core/Dialog'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import { firebaseApp } from 'src/config/firebase'

import CollectionListModel from 'src/Models/Collection/ListModel'
import userModel from 'src/Models/UserModel'
import AddIcon from '@material-ui/icons/Add'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
@observer
class AddToCollectionComponent extends Component {
  constructor(props) {
    super(props)

    this.state = {
      errorText: '',
      open: false,
      value: '',
    }
  }

  handleOpen = () => {
    this.setState({ open: true })
  }

  handleClose = () => {
    this.setState({
      errorText: '',
      open: false,
      value: '',
    })
  }

  handleChange = (event) => {
    this.setState({
      errorText: '',
      value: event.target.value,
    })
  }

  handleCreate = () => {
    if (!this.state.value.length) {
      this.setState({
        errorText: 'You should enter this field',
      })
      return
    }

    let newCollectionRef = firebaseApp
      .database()
      .ref('/collections/' + userModel.userId + '/' + userModel.databaseId)
      .push()
    newCollectionRef
      .set({
        dateCreate: new Date().getTime(),
        name: this.state.value,
      })
      .then(() => {
        CollectionListModel.update()
      })

    this.handleClose()
  }

  render() {
    return (
      <div style={{ display: 'inline' }}>
        <Button
          onClick={() => {
            this.handleOpen()
          }}
        >
          <AddIcon /> Add new collection
        </Button>

        <Dialog open={this.state.open} onClose={this.handleClose}>
          <DialogTitle>Enter name of new collection</DialogTitle>
          <DialogContent>
            <TextField
              helperText={this.state.errorText}
              label="Collection name"
              onChange={this.handleChange}
              value={this.state.value}
            />
          </DialogContent>

          <DialogActions>
            <Button onClick={this.handleClose}>Cancel</Button>
            <Button
              color="primary"
              variant="contained"
              onClick={this.handleCreate}
            >
              Create
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

export default AddToCollectionComponent
