import Typography from '@material-ui/core/Typography'
import React, { Component } from 'react'
import { Dropdown } from 'semantic-ui-react'

export default class BatchesListComponent extends Component {
  render() {
    const { batchesModel, isSingle } = this.props

    const selected = batchesModel.selectedBatches
    const batches = batchesModel.batchesList
      .map((element) => {
        return {
          key: element.buid.toString(),
          value: element.buid.toString(),
          text: element.name,
        }
      })
      .concat([{ key: 'Select All', value: 'Select All', text: 'Select All' }])

    return (
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: 16 }}>
        <Typography
          variant="subtitle1"
          style={{ minWidth: 100, marginRight: 8 }}
        >
          Type to Search
        </Typography>
        <Dropdown
          defaultValue={selected}
          placeholder={isSingle ? 'Select Batch' : 'Select Batches'}
          multiple={!isSingle}
          onChange={(event, data) => {
            batchesModel.selectedBatches = data.value
            batchesModel.saveToStorage(data.value)
          }}
          search={true}
          selection={true}
          options={batches}
          style={{
            width: '100%',
          }}
        />
      </div>
    )
  }
}
