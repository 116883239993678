// @flow
import {observable, computed} from 'mobx';

class AbstractStore
{
  @observable _isLoading : bool = false;

  @computed
  get isLoading() : bool {
    return this._isLoading;
  }

  set isLoading(value : bool) {
    this._isLoading = value;
  }
}

export default AbstractStore;
