import React from 'react'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { toolbarStyles } from '../toolbarStyles'
import DeleteFeature from '../DeleteFeature'
import AddToCollectionComponent from './AddToCollectionComponent'

const EnhancedTableToolbar = (props) => {
  const { selected, classes, listModel } = props

  const numSelected = selected.length

  return (
    <Toolbar
      className={classNames(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.title}>
        {numSelected > 0 ? (
          <div style={{ color: 'white', fontSize: '1.2em' }}>
            {numSelected} selected
          </div>
        ) : (
          <Typography variant="h5">Collections</Typography>
        )}
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        {numSelected > 0 ? (
          <div style={{ width: '100px' }}>
            <DeleteFeature
              selectedItems={selected}
              listModel={listModel}
              isCollectionList={true}
            />
          </div>
        ) : (
          <div style={{ width: '200px' }}>
            <AddToCollectionComponent />
          </div>
        )}
      </div>
    </Toolbar>
  )
}

export default withStyles(toolbarStyles)(EnhancedTableToolbar)
