import React, { Component } from 'react'
import Typography from '@material-ui/core/Typography'
import { observer } from 'mobx-react'
import UsersListComponent from '../../../Admin/User/List/ListComponent'

/**
 * Displays users list from database in table view with header title.
 */
@observer
class ViewComponent extends Component {
  render() {
    return (
      <div>
        <Typography variant="h4">Registered users</Typography>
        <UsersListComponent />
      </div>
    )
  }
}

export default ViewComponent
