import React, { Component } from 'react'
import { Redirect, Link } from 'react-router-dom'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import authModel from 'src/Models/AuthModel'
import { observer } from 'mobx-react'
import CircularProgress from '@material-ui/core/CircularProgress'
import UserModel from 'src/Models/UserModel'
import { firebaseApp } from 'src/config/firebase'
import { history } from 'src/config/routing/history'
import MuiLink from '@material-ui/core/Link'

/**
 * Login form component.
 * Used to authenticate user in system.
 * Accepts email and password as credentials.
 */

export default
@observer
class LoginComponent extends Component {
  constructor(props) {
    super(props)

    this.submitStateDefault = {
      label: 'Login',
      disabled: false,
    }

    this.state = {
      email: {
        value: '',
        error: '',
      },
      password: {
        value: '',
        error: '',
      },
      submit: this.submitStateDefault,
      redirectToApp: false,
    }
  }

  /**
   * Handles email input change.
   * @param event
   */
  handleEmailChange = (event) => {
    const emailState = {
      error: '',
      value: event.target.value,
    }

    if (!event.target.value.length) {
      emailState.error = 'Email is required'
    }

    this.setState({
      email: emailState,
    })
  }

  /**
   * Handles password input change.
   * @param event
   */
  handlePasswordChange = (event) => {
    const passwordState = {
      error: '',
      value: event.target.value,
    }

    if (!event.target.value.length) passwordState.error = 'Password is required'

    this.setState({
      password: passwordState,
    })
  }

  /**
   * Handles credentials form submit.
   * Tries to authenticate user.
   * @param event
   */
  handleSubmit = (event) => {
    event.preventDefault()

    this.setState({
      submit: {
        label: <CircularProgress size={30} color="primary" />,
        disabled: true,
      },
    })

    const email = this.state.email.value
    const password = this.state.password.value

    // This is used to check that user exists not only in firebase auth record
    // But at least in 'users' or 'admins' tables
    // This should be moved inside AuthModel
    const unsubscribe = authModel.onAuthStateChanged((firebaseUser) => {
      if (firebaseUser) {
        firebaseApp
          .database()
          .ref('/admins/' + UserModel.userId)
          .once('value')
          .then((snapshot) => {
            if (!snapshot.val()) {
              firebaseApp
                .database()
                .ref('/users/' + UserModel.userId)
                .once('value')
                .then((snapshot) => {
                  if (firebaseUser.emailVerified === false) {
                    firebaseUser.sendEmailVerification()

                    throw new Error(
                      'Before you can log in, you need to confirm your email address.'
                    )
                  }

                  if (!snapshot.val()) {
                    throw new Error('This user does not exist')
                  } else {
                    this.setState({ redirectToApp: true })
                  }
                })
                .catch((error) => {
                  this.setState({
                    email: {
                      error: error.message,
                      value: email,
                    },
                    submit: {
                      label: 'Login',
                      disabled: false,
                    },
                  })

                  authModel.signOut()
                  unsubscribe()
                })
            } else {
              this.setState({ redirectToApp: true })
            }
          })
      }
    })

    authModel.signIn(email, password).catch((error) => {
      console.error(error)
      this.setState({
        submit: this.submitStateDefault,
      })

      switch (error.code) {
        case 'auth/wrong-password':
          this.setState({
            password: {
              error: error.message,
              value: this.state.password.value,
            },
          })
          break
        default:
          this.setState({
            email: {
              error: error.message,
              value: this.state.email.value,
            },
          })
          break
      }
    })
  }

  render() {
    const divStyle = {
      width: '300px',
      height: '580px',

      position: 'absolute',
      top: '0',
      bottom: '0',
      left: '0',
      right: '0',

      margin: 'auto',
    }

    const logoStyle = {
      position: 'relative',
      left: '-50px',
    }

    const logoBfsStyle = {
      position: 'fixed',
      right: '20px',
      bottom: '20px',
    }

    return (
      <div style={divStyle}>
        <img
          style={logoBfsStyle}
          src="logo-bfs.png"
          width="80"
          height="80"
          alt="Logo BFS"
        />
        <img
          style={logoStyle}
          src="logo.png"
          width="400"
          height="244"
          alt="Logo"
        />
        <form onSubmit={this.handleSubmit}>
          <TextField
            fullWidth
            margin="dense"
            error={!!this.state.email.error}
            defaultValue={this.state.email.value}
            helperText={this.state.email.error}
            label="Email"
            onBlur={this.handleEmailChange}
            onChange={this.handleEmailChange}
          />
          <TextField
            fullWidth
            margin="dense"
            error={!!this.state.password.error}
            helperText={this.state.password.error}
            defaultValue={this.state.password.value}
            label="Password"
            onBlur={this.handlePasswordChange}
            onChange={this.handlePasswordChange}
            type="password"
          />
          <Button
            size="large"
            fullWidth
            variant="contained"
            color="primary"
            className="button-submit"
            disabled={this.state.submit.disabled}
            type="submit"
          >
            {this.state.submit.label}
          </Button>
          <div style={{ paddingTop: '10px', textAlign: 'center' }}>
            <Button
              size="large"
              fullWidth
              variant="contained"
              className="button-submit"
              disabled={this.state.submit.disabled}
              onClick={() => history.push('/register')}
            >
              Sign up &amp; create organization
            </Button>
          </div>
          <div style={{ paddingTop: '10px', textAlign: 'center' }}>
            <MuiLink component={Link} to="/reset">
              Forgot password?
            </MuiLink>
          </div>
        </form>
        {this.state.redirectToApp && <Redirect push to="/" />}
      </div>
    )
  }
}
