import React, { Component } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Input, Button } from 'semantic-ui-react'
import { observer } from 'mobx-react'
import OrganizationModel from 'src/Models/Organization/OrganizationModel'
import Snackbar from '@material-ui/core/Snackbar'
import { Link } from 'react-router-dom'
/**
 * Displays information about current subscription.
 */

export default
@observer
class OrganizationComponent extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isAlert: false,
    }

    this.saveToData = this.saveToData.bind(this)
    this.getFromData = this.getFromData.bind(this)

    this._data = {}
  }

  componentDidMount() {
    OrganizationModel.update()
  }

  saveAllData = () => {
    const { handleSave } = this.props
    const handleSaveBind = handleSave.bind(this)
    handleSaveBind().then(() => {
      this.handleAlertOpen()
    })
  }

  saveToData(key, value) {
    this._data[key] = value
  }

  getFromData(key) {
    return this._data[key]
  }

  handleAlertOpen = () => {
    this.setState({
      isAlert: true,
    })
  }

  handleAlertClose = () => {
    this.setState({
      isAlert: false,
    })
  }

  render() {
    if (!OrganizationModel.isLoaded) return <CircularProgress />

    const styles = {
      wrap: {
        height: '37px',
        marginBottom: '20px',
      },
      'row-one': {
        float: 'left',
        marginTop: '8px',
        width: '150px',
      },
      'row-two': {
        float: 'left',
        width: '400px',
      },
    }

    let rows = []

    rows.push(
      <RowComponent
        saveToData={this.saveToData}
        getFromData={this.getFromData}
        key="name"
        keyRow="name"
        defaultValue={this._data['name'] || OrganizationModel.nameGet}
        rowName="Organization Name"
        handleAlertOpen={this.handleAlertOpen}
        styles={styles}
      />
    )
    rows.push(
      <RowComponent
        saveToData={this.saveToData}
        getFromData={this.getFromData}
        key="address"
        keyRow="address"
        defaultValue={this._data['address'] || OrganizationModel.addressGet}
        rowName="Address"
        handleAlertOpen={this.handleAlertOpen}
        styles={styles}
      />
    )
    rows.push(
      <RowComponent
        saveToData={this.saveToData}
        getFromData={this.getFromData}
        key="contact"
        keyRow="contact"
        defaultValue={this._data['contact'] || OrganizationModel.contactGet}
        rowName="Organization Contact"
        handleAlertOpen={this.handleAlertOpen}
        styles={styles}
      />
    )
    rows.push(
      <RowComponent
        saveToData={this.saveToData}
        getFromData={this.getFromData}
        key="phone"
        keyRow="phone"
        defaultValue={this._data['phone'] || OrganizationModel.phoneGet}
        rowName="Contact Phone"
        handleAlertOpen={this.handleAlertOpen}
        styles={styles}
      />
    )

    let backButton = ''
    if (this.props.isEdit) {
      backButton = (
        <Link to="/organization" style={{ marginLeft: '20px' }}>
          Go Back Without Saving
        </Link>
      )
    }

    return (
      <div style={{ margin: 20 }}>
        {rows}
        <div style={styles.wrap}>
          <div style={styles['row-one']} />
          <div style={styles['row-two']}>
            <Button
              icon="save"
              content="Save all fields"
              color="blue"
              labelPosition="right"
              onClick={this.saveAllData}
            />
            {backButton}
          </div>
        </div>
        <Snackbar
          open={this.state.isAlert}
          message="Changes have been saved"
          autoHideDuration={4000}
          onClose={this.handleAlertClose}
        />
      </div>
    )
  }
}

class RowComponent extends Component {
  render() {
    const { saveToData, rowName, defaultValue, keyRow, styles } = this.props
    saveToData(keyRow, defaultValue)

    return (
      <div style={styles.wrap}>
        <div style={styles['row-one']}>{rowName}</div>
        <div style={styles['row-two']}>
          <Input
            onChange={(event, inputProps) => {
              saveToData(keyRow, inputProps.value)
            }}
            defaultValue={defaultValue}
            style={{
              width: '100%',
            }}
          />
        </div>
      </div>
    )
  }
}
