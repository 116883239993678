import React, { Component } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import Table from '@material-ui/core/Table'
import { observer } from 'mobx-react'
import StudiesListModel from '../../../Models/Study/ShareListModel'
import { history } from 'src/config/routing/history'
import RecordsNumFeature from '../../../Features/RecordsNum'
import Typography from '@material-ui/core/Typography'

@observer
class ShareListComponent extends Component {
  constructor(props) {
    super(props)
    this.listModel = new StudiesListModel()
  }

  handleClick(shareKey) {
    history.push('/studies-share/' + shareKey)
  }

  componentDidMount() {
    this.listModel.update()
  }

  render() {
    if (!this.listModel.isLoaded) return <CircularProgress />

    const studiesKeys = Object.keys(this.listModel.studies)
    const rows = studiesKeys.map((key) => {
      const item = this.listModel.studies[key]
      return (
        <TableRow key={item.suid}>
          <TableCell>
            <div
              onClick={() => {
                this.handleClick(item.shareKey)
              }}
            >
              {item.name}
            </div>
          </TableCell>
          <TableCell>
            <RecordsNumFeature shareId={item.shareKey} />
          </TableCell>
          <TableCell>
            <div
              onClick={() => {
                this.handleClick(item.shareKey)
              }}
            >
              {item.isShareEdit ? 'View & Edit' : 'View only'}
            </div>
          </TableCell>
        </TableRow>
      )
    })

    if (rows.length === 0) {
      return <div />
    }

    return (
      <div>
        <Typography variant="h4">Studies Shared with Me</Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell># of records</TableCell>
              <TableCell>Share mode</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{rows}</TableBody>
        </Table>
      </div>
    )
  }
}

export default ShareListComponent
