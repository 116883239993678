import { observable, transaction, computed } from 'mobx'
import { firebaseApp } from 'src/config/firebase'

import userModel from '../UserModel'

class ShareStudyModel {
  @observable _loaded = false
  @observable _data = {}
  _shareId = null
  _studyId = null

  @computed get isLoaded() {
    return this._loaded
  }

  @computed get data() {
    return this._data
  }

  set shareId(value) {
    this._shareId = value
  }

  set studyId(value) {
    this._studyId = value
  }

  update() {
    this._loaded = false

    if (!this._shareId) {
      transaction(() => {
        this._loaded = true
        this._data = {
          databaseId: userModel.databaseId,
          isShareEdit: true,
          isShareView: true,
          study: this._studyId,
          userId: userModel.userId,
        }
      })
      return Promise.resolve()
    }

    return firebaseApp
      .database()
      .ref(`/shareStudies/${userModel.organisationId}/${this._shareId}`)
      .once('value')
      .then((snapshot) => {
        transaction(() => {
          this._loaded = true
          const data = snapshot.val()
          this._data = {
            ...data,
            shareId: this._shareId,
          }
        })
      })
  }
}

export default ShareStudyModel
