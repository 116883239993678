import React, { Component } from 'react'
import { Header, Table, Button } from 'semantic-ui-react'
import DeviceElementComponent from './DeviceElementComponent'
import UserComponent from '../../../../Licenses/UserComponent'
import Container from './SwapLicense/Container'

class DeviceComponent extends Component {
  constructor(props) {
    super(props)

    this.editModel = props.editModel
  }

  render() {
    const data = this.editModel.data

    const devicesKeys = Object.keys(data.devices)
    const devices = devicesKeys
      .filter((key) => !!data.devices[key].id)
      .map((key) => {
        return (
          <DeviceElementComponent
            editModel={this.editModel}
            key={key}
            keyProp={key}
            value={data.devices[key]}
          />
        )
      })

    let table = ''
    if (devices.length > 0) {
      table = (
        <Table basic="very" celled collapsing>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Database</Table.HeaderCell>
              <Table.HeaderCell>Device ID</Table.HeaderCell>
              <Table.HeaderCell>Activation Code</Table.HeaderCell>
              <Table.HeaderCell> </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>{devices}</Table.Body>
        </Table>
      )
    }

    return (
      <div style={{ paddingTop: '15px' }}>
        <Header as="h2">DCSLinkStream Instances</Header>
        <Button
          onClick={() => this.editModel.addDevice()}
          icon="plus"
          color="green"
          content="Add New Instance"
          labelPosition="left"
          size="tiny"
        />
        {table}
        <Header as="h3">DCSLinkStream Licenses</Header>
        <div style={{ width: '90%', float: 'left' }}>
          <UserComponent userID={this.props.userID} />
        </div>
        <div
          style={{
            width: '8%',
            float: 'right',
            marginTop: '46px',
            marginLeft: '10px',
          }}
        >
          <Container editModel={this.editModel} />
        </div>
        <div style={{ clear: 'both' }} />
      </div>
    )
  }
}

export default DeviceComponent
