import React from 'react'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Checkbox from '@material-ui/core/Checkbox'
import RecordsNumFeature from '../RecordsNum'

const dateOptions = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
}

const CollectionRow = (props) => (
  <TableRow
    hover
    onClick={(event) => props.handleClick(event, props.n.key)}
    onKeyDown={(event) => props.handleKeyDown(event, props.n.key)}
    role="checkbox"
    aria-checked={props.isSelected}
    tabIndex={-1}
    key={props.n.key}
    selected={props.isSelected}
  >
    <TableCell>
      <Checkbox checked={props.isSelected} />
    </TableCell>
    <TableCell onClick={() => props.handleClickCell(props.n.key)}>
      {props.n.name}
    </TableCell>
    <TableCell>{props.n.numStudies}</TableCell>
    <TableCell>
      <RecordsNumFeature collectionId={props.n.key} />
    </TableCell>
    <TableCell>
      {new Date(props.n.dateCreate).toLocaleString('en-US', dateOptions)}
    </TableCell>
  </TableRow>
)

export default CollectionRow
