import React, { Component } from 'react'
import { Table, Button, Modal, Form } from 'semantic-ui-react'
import transactionType from 'src/config/transactionType'

import dayjs from 'dayjs'
import { DatePicker } from '@material-ui/pickers'

class ListComponent extends Component {
  state = { open: false, startDate: '', endDate: '', typeId: '' }

  constructor(props) {
    super(props)
    const data = props.model.data.transactions[props.keyValue]
    this.state = {
      startDate: new Date(data?.startDate),
      endDate: new Date(data?.endDate),
      typeId: data?.typeId,
    }

    this.onStartDateChange = this.onStartDateChange.bind(this)
    this.onEndDateChange = this.onEndDateChange.bind(this)
  }

  show = () => this.setState({ open: true })
  close = () => this.setState({ open: false })

  onStartDateChange(e) {
    // value is formatted as yyyy-mm-dd
    this.setState({
      startDate: e.currentTarget.value,
    })
  }

  onEndDateChange(e) {
    this.setState({
      endDate: e.currentTarget.value,
    })
  }

  render() {
    const { model, keyValue } = this.props

    const options = transactionType.getAll().map((type) => {
      return {
        key: type,
        text: transactionType.getTitleById(transactionType[type].id),
        value: type,
      }
    })

    return (
      <Table.Row>
        <Table.Cell>
          {dayjs(this.state.startDate).format('MMMM D, YYYY, h:mmA')}
        </Table.Cell>
        <Table.Cell>
          {dayjs(this.state.endDate).format('MMMM D, YYYY, h:mmA')}
        </Table.Cell>
        <Table.Cell>
          {transactionType.getTitleById(this.state.typeId)}
        </Table.Cell>
        <Table.Cell>
          <Button onClick={this.show} color="blue">
            Edit
          </Button>
          <Button onClick={() => model.deleteTransaction(keyValue)} color="red">
            Delete
          </Button>
          <Modal dimmer="inverted" open={this.state.open}>
            <Modal.Header>Edit transaction</Modal.Header>
            <Modal.Content image>
              <Form>
                <Form.Field inline>
                  <DatePicker
                    format="MM/DD/YYYY"
                    label="Start Date"
                    onChange={(val) =>
                      this.setState({ startDate: val.toDate() })
                    }
                    value={this.state.startDate}
                  />
                </Form.Field>
                <Form.Field inline>
                  <DatePicker
                    format="MM/DD/YYYY"
                    label="End Date"
                    onChange={(val) => this.setState({ endDate: val.toDate() })}
                    value={this.state.endDate}
                  />
                </Form.Field>
                <Form.Field inline>
                  <Form.Select
                    label="Type"
                    options={options}
                    placeholder="Type"
                    defaultValue={transactionType.getTypeById(
                      this.state.typeId
                    )}
                    onChange={(event, select) =>
                      this.setState({
                        typeId: transactionType[select.value].id,
                      })
                    }
                  />
                </Form.Field>
              </Form>
            </Modal.Content>
            <Modal.Actions>
              <Button onClick={this.close}>Cancel</Button>
              <Button
                onClick={() =>
                  model.updateTransaction(keyValue, {
                    // save time in milliseconds
                    startDate: dayjs(this.state.startDate).toDate().getTime(),
                    endDate: dayjs(this.state.endDate).toDate().getTime(),
                    typeId: this.state.typeId,
                  })
                }
                positive
                icon="save"
                labelPosition="right"
                content="Save"
              />
            </Modal.Actions>
          </Modal>
        </Table.Cell>
      </Table.Row>
    )
  }
}

export default ListComponent
