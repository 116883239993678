import React, { Component } from 'react'
import { firebaseApp } from 'src/config/firebase'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Input } from 'semantic-ui-react'
import Button from '@material-ui/core/Button'
import { observer } from 'mobx-react'
import UserModel from '../../../Models/UserModel'
import AccountModel from '../../../Models/Account/AccountModel'
import Snackbar from '@material-ui/core/Snackbar'
import SaveIcon from '@material-ui/icons/Save'
/**
 * Displays information about current subscription.
 */

export default
@observer
class EditComponent extends Component {
  constructor() {
    super()

    this.state = {
      isAlert: false,
      isAlertReset: false,
    }
    this.AccountModel = new AccountModel()

    this.saveToData = this.saveToData.bind(this)
    this.saveAllData = this.saveAllData.bind(this)

    this._data = {}
  }

  saveAllData() {
    firebaseApp
      .database()
      .ref('/users/' + UserModel.userId)
      .update(this._data)
      .then(() => {
        this.AccountModel.nameSet(this._data.firstName, this._data.lastName)
        this.handleAlertOpen()
      })
  }

  saveToData(key, value) {
    this._data[key] = value
  }

  componentDidMount() {
    this.AccountModel.update()
  }

  handleAlertOpen = () => {
    this.setState({
      isAlert: true,
    })
  }

  handleAlertClose = () => {
    this.setState({
      isAlert: false,
    })
  }

  handleAlertResetOpen = () => {
    this.setState({
      isAlertReset: true,
    })
  }

  handleAlertResetClose = () => {
    this.setState({
      isAlertReset: false,
    })
  }

  handleReset = () => {
    firebaseApp
      .auth()
      .sendPasswordResetEmail(UserModel.userEmail)
      .then(() => {
        this.handleAlertResetOpen()
      })
  }

  render() {
    if (!this.AccountModel.isLoaded) return <CircularProgress />

    const styles = {
      parent: {
        marginLeft: '20px',
      },
      wrap: {
        height: '37px',
        marginBottom: '20px',
      },
      'row-one': {
        float: 'left',
        marginTop: '8px',
        width: '100px',
      },
      'row-two': {
        float: 'left',
        width: '400px',
      },
    }

    let rows = []

    rows.push(
      <RowComponent
        key="firstName"
        keyRow="firstName"
        defaultValue={this.AccountModel.firstNameGet}
        rowName="First name"
        handleAlertOpen={this.handleAlertOpen}
        saveToData={this.saveToData}
        styles={styles}
      />
    )
    rows.push(
      <RowComponent
        key="lastName"
        keyRow="lastName"
        defaultValue={this.AccountModel.lastNameGet}
        rowName="Last name"
        handleAlertOpen={this.handleAlertOpen}
        saveToData={this.saveToData}
        styles={styles}
      />
    )

    return (
      <div style={styles.parent}>
        {rows}
        <div style={styles.wrap}>
          <div style={styles['row-one']} />
          <div style={styles['row-two']}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<SaveIcon />}
              onClick={this.saveAllData}
            >
              Save All Fields
            </Button>
            <Button
              color="secondary"
              onClick={this.handleReset}
              style={{ marginLeft: '20px' }}
            >
              Reset password
            </Button>
          </div>
        </div>
        <Snackbar
          open={this.state.isAlert}
          message="Changes have been saved"
          autoHideDuration={4000}
          action={
            <Button color="primary" onClick={this.handleAlertClose}>
              Close{' '}
            </Button>
          }
        />
        <Snackbar
          open={this.state.isAlertReset}
          message="Email with instructions has been sent"
          autoHideDuration={4000}
          action={
            <Button color="primary" onClick={this.handleAlertResetClose}>
              Close{' '}
            </Button>
          }
        />
      </div>
    )
  }
}

class RowComponent extends Component {
  render() {
    const { keyRow, defaultValue, saveToData, styles, rowName } = this.props
    saveToData(keyRow, defaultValue || '')

    return (
      <div key={keyRow} style={styles.wrap}>
        <div style={styles['row-one']}>{rowName}</div>
        <div style={styles['row-two']}>
          <Input
            onChange={(event) => saveToData(keyRow, event.target.value)}
            defaultValue={this.props.defaultValue}
            style={{
              width: '100%',
            }}
          />
        </div>
      </div>
    )
  }
}
