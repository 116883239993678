import { observable, transaction, computed } from 'mobx'
import { firebaseApp } from 'src/config/firebase'
import userModel from '../UserModel'

class SyncModel {
  @observable _loaded = false
  @observable _isSync = true
  _studyId = null

  @computed get isLoaded() {
    return this._loaded
  }

  @computed get isSync() {
    return this._isSync
  }

  update() {
    this._loaded = false

    firebaseApp
      .database()
      .ref(
        `/studies/${userModel.userId}/${userModel.databaseId}/${this._studyId}`
      )
      .once('value')
      .then((snapshot) => {
        const val = snapshot.val()

        transaction(() => {
          if (val && val.hasOwnProperty('syncable')) {
            this._isSync = val.syncable
          }

          this._loaded = true
        })
      })
  }

  set isSync(value) {
    firebaseApp
      .database()
      .ref(
        `/studies/${userModel.userId}/${userModel.databaseId}/${this._studyId}`
      )
      .update({
        syncable: value,
      })
      .then(() => (this._isSync = value))
  }
}

export default SyncModel
