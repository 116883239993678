import React from 'react'
import { Route, Switch, Redirect } from 'react-router'

import AccountComponent from 'src/Components/Account/View/ViewComponent'
import LicensesComponent from 'src/Components/Licenses/LicensesComponent'
import StudiesComponent from 'src/Components/Study/StudiesComponent'
import CollectionViewComponent from 'src/Components/Collection/View/ViewComponent'
import RecordViewComponent from 'src/Components/Record/View/ViewComponent'
import BatchViewComponent from 'src/Components/Record/Batch/ViewComponent'
import StudyViewNewComponent from 'src/Components/Study/View/ViewNewComponent'
import IndexComponent from 'src/Components/Study/IndexComponent'
import DeviceListComponent from 'src/Components/Devices/List/ListComponent'
import { DeviceDetailsComponent } from 'src/Components/Devices/DeviceDetailsComponent'
import OrganizationViewComponent from 'src/Components/Organization/ViewComponent'
import OrganizationEditComponent from 'src/Components/Organization/EditComponent'

export const UserRoutes = () => (
  <Switch>
    <Route path="/account" component={AccountComponent} />
    <Route path="/organization/edit" component={OrganizationEditComponent} />
    <Route exact path="/organization" component={OrganizationViewComponent} />

    <Route exact path="/studies" component={IndexComponent} />
    <Route
      path="/studies/collection/:collection"
      component={CollectionViewComponent}
    />
    <Route
      path="/studies/:study/batch/:batchId"
      component={BatchViewComponent}
    />
    <Route
      path="/studies/:study/record/:record"
      component={RecordViewComponent}
    />
    <Route path="/studies/:studyID/import" component={StudyViewNewComponent} />
    <Route exact path="/studies/:study" component={StudyViewNewComponent} />

    <Route exact path="/studies-share" component={IndexComponent} />

    <Route exact path="/studies/:shareId" component={StudiesComponent} />
    <Route
      exact
      path="/studies/:shareId/record/:record"
      component={RecordViewComponent}
    />
    <Route
      exact
      path="/studies/:shareId/batch/:batchId"
      component={BatchViewComponent}
    />
    <Route exact path="/licenses" component={LicensesComponent} />

    <Route exact path="/devices" component={DeviceListComponent} />
    <Route exact path="/devices/:device" component={DeviceDetailsComponent} />

    <Redirect push to="/studies" />
  </Switch>
)
