import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { DropTarget } from 'react-dnd'
import { Icon } from 'semantic-ui-react'

const style = {
  height: '42px',
  width: '42px',
  color: 'white',
  padding: '0.8rem 0.9rem',
  fontSize: '1rem',
  lineHeight: 'normal',
}

const boxTarget = {
  drop(props) {
    return { name: props.name }
  },
}

class NewBoxComponent extends Component {
  static propTypes = {
    connectDropTarget: PropTypes.func.isRequired,
    isOver: PropTypes.bool.isRequired,
    canDrop: PropTypes.bool.isRequired,
  }

  render() {
    const { canDrop, isOver, connectDropTarget } = this.props
    const isActive = canDrop && isOver

    let backgroundColor = '#ffffff'
    if (isActive) {
      backgroundColor = '#ff9f5f'
    } else if (canDrop) {
      backgroundColor = '#b7ffa2'
    }

    return connectDropTarget(
      <div style={{ ...style, backgroundColor }}>
        <Icon name="circle outline" color="black" />
      </div>
    )
  }
}

export default DropTarget('BOX', boxTarget, (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
  canDrop: monitor.canDrop(),
}))(NewBoxComponent)
