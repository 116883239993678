import React, { Component } from 'react'
import { Breadcrumb } from 'semantic-ui-react'
import { Redirect } from 'react-router-dom'
import { observer } from 'mobx-react'
import EditModel from '../../../../Models/Admin/User/EditModel'
import CircularProgress from '@material-ui/core/CircularProgress'
import DeviceComponent from './Device/DeviceComponent'
import InfoComponent from './InfoComponent'
import TransactionComponent from './Transaction/TransactionComponent'
import SettingsComponent from '../../Settings/SettingsComponent'
import MenuComponent from '../../Settings/MenuComponent'

@observer
class EditComponent extends Component {
  state = {
    redirect: false,
  }

  constructor(props) {
    super(props)

    this.editModel = new EditModel()
    this.editModel.user = props.user

    this.handleClickUsers = this.handleClickUsers.bind(this)
  }

  componentDidMount() {
    this.editModel.update()
  }

  handleClickUsers() {
    this.setState({ redirect: true })
  }

  render() {
    if (!this.editModel.isLoaded) {
      return <CircularProgress />
    }

    const user = this.props.user

    return (
      <div style={{ margin: 24 + 'px' }}>
        <Breadcrumb size="massive">
          <Breadcrumb.Section link onClick={this.handleClickUsers}>
            Users
          </Breadcrumb.Section>
          <Breadcrumb.Divider icon="right chevron" />
          <Breadcrumb.Section active>User edit</Breadcrumb.Section>
        </Breadcrumb>
        <InfoComponent editModel={this.editModel} />
        <SettingsComponent userId={user} />
        <MenuComponent userId={user} />
        <DeviceComponent editModel={this.editModel} userID={user} />
        <TransactionComponent editModel={this.editModel} />
        {this.state.redirect && <Redirect push to="/admin/users/" />}
      </div>
    )
  }
}

export default EditComponent
