import React, { Component } from 'react'
import SubscriptionComponent from '../../Payment/Subscription/SubscriptionComponent'
import CreateComponent from '../../Payment/Subscription/Button/CreateComponent'
import CancelComponent from '../../Payment/Subscription/Button/CancelComponent'
import UpdateComponent from '../../Payment/Subscription/Button/UpdateComponent'
import { Button } from 'semantic-ui-react'
/**
 * Yearly subscription component for Android devices.
 */
export default class YearlyComponent extends Component {
  render() {
    const { planId, subscription, callback } = this.props

    let subscriptionId = null

    if (subscription !== null && subscription.hasOwnProperty('id')) {
      subscriptionId = subscription.id
    }

    const cancelButton = (
      <CancelComponent subscriptionId={subscriptionId} callback={callback} />
    )

    const updateButton = (
      <UpdateComponent
        header="$360 / year"
        subscriptionId={subscriptionId}
        planId={planId}
        callback={callback}
      >
        {({ onOpen }) => (
          <Button
            onClick={onOpen}
            color="purple"
            content="$360 / year"
            icon="cart"
            label={{
              basic: true,
              color: 'purple',
              pointing: 'left',
              content: 'Update subscription',
            }}
            size="massive"
          />
        )}
      </UpdateComponent>
    )

    const createButton = (
      <CreateComponent planId={planId} callback={callback} header="$360 / month">
        <Button
          color="blue"
          content="$360 / year"
          icon="cart"
          label={{
            basic: true,
            color: 'blue',
            pointing: 'left',
            content: 'Purchase subscription',
          }}
          size="massive"
        />
      </CreateComponent>
    )

    return (
      <div>
        <SubscriptionComponent
          planId={planId}
          subscription={subscription}
          cancelButton={cancelButton}
          createButton={createButton}
          updateButton={updateButton}
        />
      </div>
    )
  }
}
