import React, { Component } from 'react'
import { observer } from 'mobx-react'

import CircularProgress from '@material-ui/core/CircularProgress'
import SyncModel from '../../../Models/Study/SyncModel'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'

@observer
class SyncComponent extends Component {
  constructor(props) {
    super(props)

    this.syncModel = new SyncModel()
    this.syncModel._studyId = props.studyId
  }

  componentDidMount() {
    this.syncModel.update()
  }

  render() {
    if (!this.syncModel.isLoaded) {
      return <CircularProgress />
    }

    const styles = {
      block: {
        maxWidth: 170,
        minWidth: '170px',
        position: 'absolute',
        right: 0,
        top: '84px',
      },
    }

    return (
      <div style={styles.block}>
        <FormControlLabel
          label="Syncable Study"
          control={
            <Switch
              color="primary"
              checked={this.syncModel.isSync}
              onChange={(event, value) => {
                this.syncModel.isSync = value
              }}
            />
          }
        />
      </div>
    )
  }
}

export default SyncComponent
