import CircularProgress from '@material-ui/core/CircularProgress'
import { observer } from 'mobx-react'
import React from 'react'
import { Tab } from 'semantic-ui-react'
import { loadStudy } from '../../../data/studies'
import ShareStudyModel from '../../../Models/Study/ShareStudyModel'
import UserModel from '../../../Models/UserModel'
import VariableStudyComponent from '../../Variable/StudyComponent'
import SyncComponent from './SyncComponent'
import ViewComponent from './ViewComponent'
import { history } from 'src/config/routing/history'

@observer
class ViewNewComponent extends React.Component {
  // TODO(chava): Make this component pass down study info instead of share models
  state = {
    study: null,
    loadingStudy: true,
    activeIndex: 0,
  }

  constructor(props) {
    super(props)

    this.shareStudyModel = new ShareStudyModel()
    this.shareStudyModel.shareId = props.match.params.shareId
      ? props.match.params.shareId
      : null
    this.shareStudyModel.studyId = props.match.params.study

    const activeIndexStorage =
      localStorage.getItem(this.activeIndexStorageKey()) || 2
    this.state = {
      ...this.state,
      activeIndex: activeIndexStorage,
    }
  }

  activeIndexStorageKey = () =>
    `StudyViewNewComponent::${this.props.match.params.study}::${this.props.match.params.shareId}`

  componentDidMount() {
    this.shareStudyModel.update()
    // TODO(chava): replace with url params
    loadStudy({
      userId: UserModel.userId,
      studyId: this.props.match.params.study,
      databaseId: UserModel.databaseId,
    }).then((study) => this.setState({ study, loadingStudy: false }))
  }

  setActiveTab = (activeIndex) => {
    this.setState({ ...this.state, activeIndex })
    localStorage.setItem(this.activeIndexStorageKey(), activeIndex)
  }

  render() {
    if (!this.shareStudyModel.isLoaded || this.state.loadingStudy) {
      return <CircularProgress />
    }

    if (!this.state.loadingStudy && this.state.study == null) {
      history.push('/studies')
      return null
    }

    const props = {
      ...this.props,
      fetchData: this.shareStudyModel.data,
    }

    const panes = [
      {
        menuItem: 'Study Settings',
        render: () => (
          <Tab.Pane attached={false}>
            <VariableStudyComponent {...props} />
          </Tab.Pane>
        ),
      },
      {
        menuItem: 'Batches',
        render: () => (
          <Tab.Pane attached={false}>
            <ViewComponent {...props} isBatchFields key="Batch_Fields" />
          </Tab.Pane>
        ),
      },
      {
        menuItem: 'Individual Records',
        render: () => (
          <Tab.Pane attached={false}>
            <ViewComponent {...props} key="Fish_Records" />
          </Tab.Pane>
        ),
      },
    ]

    return (
      <div>
        {!props.match.params.shareId && (
          <SyncComponent studyId={props.match.params.study} />
        )}
        <h1 style={{ padding: 16 }}>{this.state.study.name}</h1>
        <Tab
          menu={{ secondary: true }}
          activeIndex={this.state.activeIndex}
          onTabChange={(event, { activeIndex }) =>
            this.setActiveTab(activeIndex)
          }
          panes={panes}
          style={{ margin: '10px' }}
        />
      </div>
    )
  }
}

export default ViewNewComponent
