import React, { Component } from 'react'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { Link } from 'react-router-dom'
import { history } from 'src/config/routing/history'
import CircularProgress from '@material-ui/core/CircularProgress'
import { firebaseApp } from 'src/config/firebase'

export default class ResetComponent extends Component {
  constructor(props) {
    super(props)

    this.email = ''

    this.state = {
      email: {
        value: '',
        error: '',
      },
      submit: {
        label: 'Reset password',
        disabled: false,
      },
    }

    this.captcha = null
  }

  handleEmailChange = (event) => {
    this.setState({
      email: this.emptyCheck(event.target.value, 'Email is required'),
    })
  }

  emptyCheck(value, errorText) {
    if (typeof errorText === 'undefined') errorText = 'This field is required'

    const state = {
      error: '',
      value: value,
    }

    if (!value.length) state.error = errorText

    return state
  }

  handleSubmit = (event) => {
    event.preventDefault()

    // this.captcha.execute()

    this.setState({
      submit: {
        label: <CircularProgress size={30} />,
        disabled: true,
      },
    })

    this.setState({
      email: this.emptyCheck(this.state.email.value),
    })

    const email = this.state.email.value

    if (!email) {
      this.setState({
        submit: {
          label: 'Reset password',
          disabled: false,
        },
      })
      return
    }

    firebaseApp
      .auth()
      .sendPasswordResetEmail(email)
      .then(
        () => {
          history.push('/reset/complete')
        },
        (error) => {
          this.setState({
            email: {
              error: error.message,
              value: email,
            },
            submit: {
              label: 'Reset password',
              disabled: false,
            },
          })
        }
      )
      .catch((err) => {
        this.setState({
          email: {
            error: err.message,
            value: email,
          },
          submit: {
            label: 'Reset password',
            disabled: false,
          },
        })
      })
  }

  render() {
    const divStyle = {
      width: '300px',
      height: '250px',

      position: 'absolute',
      top: '0',
      bottom: '0',
      left: '0',
      right: '0',

      margin: 'auto',
    }

    return (
      <form onSubmit={this.handleSubmit}>
        {/* <ReCAPTCHA
          ref={(el) => {
            this.captcha = el
          }}
          size="invisible"
          sitekey="6LctqCEUAAAAAKSGGL3JMzlLNevEbK4wyv4IcG8N"
          onChange={this.onChangeCaptcha}
        /> */}
        <div style={divStyle}>
          <div>
            <TextField
              fullWidth
              label="Email"
              helperText={this.state.email.error}
              onBlur={this.handleEmailChange}
              onChange={this.handleEmailChange}
              defaultValue={this.state.email.value}
            />
          </div>
          <div style={{ marginTop: 16 }}>
            <Button
              fullWidth
              size="large"
              variant="contained"
              color="primary"
              disabled={this.state.submit.disabled}
              type="submit"
              label={this.state.submit.label}
            >
              {this.state.submit.label}
            </Button>
          </div>
          <div style={{ paddingTop: '10px', textAlign: 'center' }}>
            <Link to="/login">Back to Sign-in</Link>
          </div>
        </div>
      </form>
    )
  }
}
