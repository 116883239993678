import React, { Component } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import TextField from '@material-ui/core/TextField'
import InvitationModel from '../../../Models/Account/InvitationModel'
import Snackbar from '@material-ui/core/Snackbar'
import authModel from '../../../Models/AuthModel'
import userModel from '../../../Models/UserModel'
import { firebaseApp } from 'src/config/firebase'
import role from '../../../config/role'
import RoleSelectComponent from './RoleSelectComponent'

import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import AuthModel from '../../../Models/AuthModel'
import { getUser } from 'src/services/firebase'

/**
 * Component used to add new invitation for users.
 */
class AddItemComponent extends Component {
  constructor(props) {
    super(props)

    this.state = {
      dialog: {
        open: false,
      },
      snackbar: {
        open: false,
        message: '',
      },
      email: {
        error: '',
        value: '',
      },
      password: {
        error: '',
        value: '',
        visible: false,
      },
      role: {
        value: role.user.id,
      },
    }

    this.invitationModel = new InvitationModel()
  }

  /**
   * Handles user role change.
   *
   * @param id User ID to whom role change is applied.
   * @param event Event.
   * @param key The index of the selected menu item.
   * @param value The value of the selected menu item.
   */
  handleRoleChange = (id, value) => {
    this.setState({
      role: {
        value: value,
      },
    })
  }

  /**
   * Opens modal dialog for adding new invitation.
   */
  handleDialogOpen = () => {
    this.setState({
      dialog: {
        open: true,
      },
      snackbar: {
        open: false,
        message: '',
      },
    })
  }

  /**
   * Closes modal dialog for adding new invitation.
   */
  handleDialogClose = () => {
    this.setState({
      dialog: {
        open: false,
      },
      email: {
        value: '',
        error: '',
      },
      password: {
        error: '',
        value: '',
        visible: false,
      },
      snackbar: {
        open: false,
        message: '',
      },
    })
  }

  /**
   * Handles new invitation.
   * Sends request to add new invitation to database.
   *
   * @param event
   */
  handleInvitationSubmit = async (event) => {
    event.preventDefault()
    try {
      const { data } = await getUser(this.state.email.value)
      this.inviteExistingUser(data.uid)
    } catch (err) {
      // api returns 404 when user doesn't exist
      if (err.response && err.response.status === 404) {
        if (this.state.password.value.length >= 6) {
          return this.inviteNewUser()
        }
        this.setState({
          password: {
            value: this.state.password.value,
            error:
              'User not found. Please create a password for a new user. Passwords are case sensitive and must have at least 6 characters.',
            visible: true,
          },
          snackbar: {
            open: false,
            message: '',
          },
        })
      }
    }
  }

  inviteExistingUser = (uid) => {
    firebaseApp
      .database()
      .ref('/users/' + uid + '/organisation')
      .once('value')
      .then((snapshot) => {
        const val = snapshot.val()
        if (val === null) {
          this.handleDialogClose()

          const invitationUpdate = {
            ['/users/' + uid + '/organisation']: userModel.organisationId,
            ['/users/' + uid + '/role']: this.state.role.value,
            ['/users/' + userModel.userId + '/invitations/' + uid]: true,
            ['/organisations/' +
            userModel.organisationId +
            '/users/' +
            uid]: true,
          }

          firebaseApp.database().ref().update(invitationUpdate)
        } else if (val === userModel.organisationId) {
          this.setState({
            snackbar: {
              open: true,
              message: 'That user is already part of your organization.',
            },
          })
        } else {
          this.setState({
            snackbar: {
              open: true,
              message: 'That user is already part of an organization.',
            },
          })
        }
      })
  }

  inviteNewUser = () => {
    const invitationPromise = authModel.signUp(
      this.state.email.value,
      this.state.password.value,
      firebaseApp
    )

    invitationPromise.then((firebaseUser) => {
      this.invitationModel.create(firebaseUser.uid)

      this.handleDialogClose()

      firebaseApp.database().ref('/users').child(firebaseUser.uid).set({
        devices: '',
        email: firebaseUser.email,
        role: this.state.role.value,
        organisation: userModel.userId,
      })

      this.setState({
        role: {
          value: role.user.id,
        },
        snackbar: {
          open: true,
          message: 'User created successfully',
        },
      })

      firebaseUser.sendEmailVerification()
      AuthModel.signOut()
    })

    invitationPromise.catch((error) => {
      this.setState({
        snackbar: {
          open: true,
          message: error.message,
        },
      })
    })
  }

  /**
   * Handles email input change.
   *
   * @param event
   */
  handleEmailChange = (event) => {
    const emailState = {
      error: '',
      value: event.target.value,
    }

    if (!event.target.value.length) emailState.error = 'Email is required'

    this.setState({
      email: emailState,
      snackbar: {
        open: false,
        message: '',
      },
    })
  }

  handlePasswordChange = (event) => {
    this.setState({
      password: {
        error: '',
        value: event.target.value,
        visible: true,
      },
      snackbar: {
        open: false,
        message: '',
      },
    })
  }

  render() {
    return (
      <div>
        <Dialog
          title="Invite new user"
          open={this.state.dialog.open}
          onClose={this.handleDialogClose}
        >
          <DialogTitle>Invite New User</DialogTitle>
          <DialogContent>
            <RoleSelectComponent
              id={null}
              value={this.state.role.value}
              onChange={this.handleRoleChange}
            />
            <TextField
              fullWidth
              label="Email"
              helperText={this.state.email.error}
              onBlur={this.handleEmailChange}
              onChange={this.handleEmailChange}
              defaultValue={this.state.email.value}
            />
            {this.state.password.visible && (
              <TextField
                fullWidth
                type="password"
                label="Password"
                helperText={this.state.password.error}
                onBlur={this.handlePasswordChange}
                onChange={this.handlePasswordChange}
                defaultValue={this.state.password.value}
              />
            )}
          </DialogContent>

          <DialogActions>
            <Button onClick={this.handleDialogClose}>Cancel</Button>
            <Button
              variant="contained"
              color="primary"
              onClick={this.handleInvitationSubmit}
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>
        {/* <Button
          variant="outlined"
          onClick={() => {
            this.handleDialogOpen()
          }}
          style={{ marginTop: '10px' }}
        >
          <AddIcon /> Invite new user
        </Button> */}
        <Snackbar
          open={this.state.snackbar.open}
          message={this.state.snackbar.message}
          autoHideDuration={4000}
        />
      </div>
    )
  }
}

export default AddItemComponent
