import React, { Component } from 'react'
import { observer } from 'mobx-react'
import userModel from 'src/Models/UserModel'
import ListComponent from '../Account/List/ListComponent'
import AddItemComponent from '../Account/List/AddItemComponent'
import EditComponent from './Item/EditComponent'
import { Header } from 'semantic-ui-react'
import OrganizationUserModel from 'src/Models/Organization/OrganizationUserModel'
import CircularProgress from '@material-ui/core/CircularProgress'

export default
@observer
class EditAComponent extends Component {
  componentDidMount() {
    OrganizationUserModel.update()
  }

  render() {
    if (!OrganizationUserModel.isLoaded) return <CircularProgress />

    return (
      <div style={{ margin: 20 }}>
        <Header as="h1">Editing: {OrganizationUserModel.orgName}</Header>

        {userModel.canManageOrganization && <EditComponent />}

        <Header as="h3">
          User list
          <AddItemComponent />
        </Header>
        <ListComponent />
      </div>
    )
  }
}
