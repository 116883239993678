import React, {Component} from 'react';

export default class SubscriptionComponent extends Component {
  render() {
    const {planId, subscription, createButton, cancelButton, updateButton} = this.props;

    let actionButton = createButton;

    if(subscription !== null && subscription.hasOwnProperty('items')) {
      const subscriptionPlanExists = subscription.items.data.some(item => item.plan.id === planId);

      if(subscriptionPlanExists && !subscription['cancel_at_period_end']) {
        actionButton = cancelButton;
      }
      else {
        actionButton = updateButton;
      }
    }

    return (
      <div>
        {actionButton}
      </div>
    )
  }
}