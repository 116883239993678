import { observable, transaction, computed } from 'mobx'
import { firebaseApp } from 'src/config/firebase'

class ListModel {
  _study = null
  @observable _loaded = false
  @observable _variables = []
  _name = ''
  fetchData = {}

  @computed get isLoaded() {
    return this._loaded
  }

  get name() {
    return this._name
  }

  @computed get variables() {
    return this._variables.slice()
  }

  async update() {
    this._loaded = false
    try {
      const studySnapshot = await firebaseApp
        .database()
        .ref(
          '/studies/' +
            this.fetchData.userId +
            '/' +
            this.fetchData.databaseId +
            '/' +
            this.fetchData.study
        )
        .once('value')
      const studyVal = studySnapshot.val()
      this._name = studyVal.name
      const snapshot = await firebaseApp
        .database()
        .ref(
          `/variables/${this.fetchData.userId}/${this.fetchData.databaseId}/${this.fetchData.study}`
        )
        .orderByChild('recorded')
        .equalTo(false)
        .once('value')

      let list = []

      snapshot.forEach((childSnapshot) => {
        let childData = childSnapshot.val()
        childData.key = childSnapshot.key
        if (!childData.forBatch) {
          list.push(childData)
        }
      })

      transaction(() => {
        this._variables = list
        this._loaded = true
      })
    } catch (err) {
      console.error(err)
      throw new Error(err)
    }
  }
}

export default ListModel
