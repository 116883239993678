import React, { Component } from 'react'
import { Header, Table, Button } from 'semantic-ui-react'

import TransactionElementComponent from './TransactionElementComponent'

class TransactionComponent extends Component {
  constructor(props) {
    super(props)

    this.editModel = props.editModel
  }

  render() {
    const data = this.editModel.data

    const transactionsKeys = data.transactions
      ? Object.keys(data.transactions)
      : []
    const transactions = transactionsKeys.map((key) => {
      return (
        <TransactionElementComponent
          model={this.editModel}
          keyValue={key}
          key={key}
        />
      )
    })

    let table = ''
    if (transactionsKeys.length > 0) {
      table = (
        <Table basic="very" celled collapsing>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Start date</Table.HeaderCell>
              <Table.HeaderCell>End date</Table.HeaderCell>
              <Table.HeaderCell>Type</Table.HeaderCell>
              <Table.HeaderCell> </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>{transactions}</Table.Body>
        </Table>
      )
    }

    return (
      <div style={{ paddingTop: '15px' }}>
        <Header as="h2">DCSUnity Manual Transactions</Header>
        <Button
          onClick={() => this.editModel.createDefaultTransaction()}
          icon="plus"
          color="green"
          content="Add new transaction"
          labelPosition="left"
          size="tiny"
        />
        {table}
      </div>
    )
  }
}

export default TransactionComponent
