import React, { useEffect, useState } from 'react'
import Typography from '@material-ui/core/Typography'
import StudiesSharedListComponent from './List/ShareListComponent'
import SettingsComponent from '../Admin/Settings/SettingsComponent'
import UserModel from 'src/Models/UserModel'
import { Button } from 'semantic-ui-react'
import StudyAndCollectionList from '../../Features/StudyAndCollectionList'
import { history } from 'src/config/routing/history'
import { makeStyles } from '@material-ui/core/styles'
import Snackbar from '@material-ui/core/Snackbar'
import Alert from '@material-ui/lab/Alert'

const useStyles = makeStyles((theme) => ({
  dashboardHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(3),
  },
  upgradeMessage: {
    display: 'flex',
    alignItems: 'center',
  },
  messageText: {
    paddingRight: theme.spacing(1),
  },
}))

const UpgradeNotification = ({ open, onClose }) => {
  const styles = useStyles()
  const goToAccount = () => history.push('/account')
  return (
    <Snackbar open={open} onClose={onClose}>
      <Alert
        elevation={6}
        variant="filled"
        onClose={onClose}
        severity="warning"
        className={styles.notification}
      >
        <div className={styles.upgradeMessage}>
          <Typography variant="subtitle1" className={styles.messageText}>
            Free Accounts have limited collections, studies and records. Upgrade
            to a paid account to see all of your data.
          </Typography>

          <Button onClick={goToAccount} inverted>
            Upgrade
          </Button>
        </div>
      </Alert>
    </Snackbar>
  )
}

const ListComponent = () => {
  const styles = useStyles()
  const [isNotificationOpen, setIsNotificationOpen] = useState(false)

  const onNotificationClose = () => {
    setIsNotificationOpen(false)
  }

  useEffect(() => {
    if (!UserModel.stripeCustomer.hasActiveAccount) {
      setIsNotificationOpen(true)
    }
  }, [])

  return (
    <div>
      <UpgradeNotification
        open={isNotificationOpen}
        onClose={onNotificationClose}
      />
      <div className={styles.dashboardHeader}>
        <Typography variant="h4">Data</Typography>
        <SettingsComponent userId={UserModel.userId} />
      </div>
      {UserModel.organisationId && <StudiesSharedListComponent />}
      <StudyAndCollectionList isCollectionList collection={null} />
      <StudyAndCollectionList collection={null} />
    </div>
  )
}

export default ListComponent
