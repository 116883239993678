import { observable, transaction, computed } from 'mobx'
import { firebaseApp } from 'src/config/firebase'
import TransactionType from '../../../config/transactionType'
import DeviceInfo from '../../../Components/Admin/User/Edit/Device/DeviceInfo'

class EditModel {
  _user = null
  @observable _loaded = false
  @observable _data = null

  @computed get isLoaded() {
    return this._loaded
  }

  @computed get data() {
    return this._data
  }

  set user(user) {
    return (this._user = user)
  }

  get user() {
    return this._user
  }

  addDevice() {
    const data = {
      databaseId: '',
      id: '12345',
      type: 'ANDROID',
      activationCode:
        'YxEhYkoRhR1lCiCinNqMH7n3g4hHBBCMX6/W6QtJqVy594OIRwQQjF+v1ukLSalcBfK8MOBpi+zlbpvZ2xfQXA==',
    }

    const newRef = firebaseApp
      .database()
      .ref('/users/' + this._user + '/devices/')
      .push()

    newRef.set(data).then(() => {
      this.update()
    })
  }

  update() {
    this._loaded = false

    return firebaseApp
      .database()
      .ref('/users/' + this._user)
      .once('value')
      .then((snapshot) => {
        transaction(() => {
          let val = snapshot.val()
          val.devices = Object.assign({}, val.devices)

          this._data = val
          this._loaded = true
        })
      })
  }

  setRole(role) {
    let updates = {}
    updates['/users/' + this._user + '/role'] = role

    firebaseApp.database().ref().update(updates)

    this._data.role = role
  }

  async setDeviceData(key, code, id) {
    let updates = {}
    updates[
      '/users/' + this._user + '/devices/' + key + '/activationCode'
    ] = code
    updates['/users/' + this._user + '/devices/' + key + '/id'] = id

    await firebaseApp.database().ref().update(updates)

    this.update()
  }

  deleteDevice(key) {
    const ref = firebaseApp
      .database()
      .ref('/users/' + this._user + '/devices/' + key)
    ref.remove().then(() => this.update())
  }

  createTransaction(data) {
    const newRef = firebaseApp
      .database()
      .ref('/users/' + this._user + '/transactions/')
      .push()
    newRef.set(data).then(() => this.update())
  }

  createDefaultTransaction() {
    const dateNow = new Date()
    dateNow.getTime()

    const dateNext = new Date()
    dateNext.setMonth(dateNext.getMonth() + 1)

    const data = {
      startDate: dateNow.getTime(),
      endDate: dateNext.getTime(),
      typeId: TransactionType.manualCC.id,
    }

    this.createTransaction(data)
  }

  deleteTransaction(key) {
    const ref = firebaseApp
      .database()
      .ref('/users/' + this._user + '/transactions/' + key)
    ref.remove().then(() => this.update())
  }

  updateTransaction(key, data) {
    const ref = firebaseApp
      .database()
      .ref('/users/' + this._user + '/transactions/' + key)
    ref.update(data).then(() => this.update())
  }

  swapSubscription(deviceIdOld, deviceIdNew) {
    let updates = {}

    const deviceOld = this._data.devices[deviceIdOld]
    const deviceNew = this._data.devices[deviceIdNew]
    const subscriptionIdOld = deviceOld.subscriptionId
    const deviceInfoOld = new DeviceInfo(deviceOld.activationCode, deviceOld.id)
    const untilOld = deviceInfoOld.until_activated

    // Set date to 1971.
    deviceInfoOld.setData(
      62157456000000,
      deviceInfoOld.activation_date,
      deviceInfoOld.trial_date,
      deviceInfoOld.activated
    )

    updates[
      '/users/' + this._user + '/devices/' + deviceIdOld + '/activationCode'
    ] = deviceInfoOld.activationCode
    updates[
      '/users/' + this._user + '/devices/' + deviceIdOld + '/subscriptionId'
    ] = ''

    const deviceInfoNew = new DeviceInfo(deviceNew.activationCode, deviceNew.id)

    // Set old date.
    deviceInfoNew.setData(
      untilOld,
      deviceInfoNew.activation_date,
      deviceInfoNew.trial_date,
      deviceInfoNew.activated
    )

    updates[
      '/users/' + this._user + '/devices/' + deviceIdNew + '/activationCode'
    ] = deviceInfoNew.activationCode
    updates[
      '/users/' + this._user + '/devices/' + deviceIdNew + '/subscriptionId'
    ] = subscriptionIdOld

    firebaseApp
      .database()
      .ref()
      .update(updates)
      .then(() => this.update())
  }
}

export default EditModel
