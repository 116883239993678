import React, { Component } from 'react'
import Typography from '@material-ui/core/Typography'
import { observer } from 'mobx-react'
import CollectionViewModel from 'src/Models/Collection/ViewModel'
import { Link } from 'react-router-dom'
import StudyAndCollectionList from 'src/Features/StudyAndCollectionList'

export default
@observer
class ViewComponent extends Component {
  constructor(props) {
    super(props)

    this.viewModel = new CollectionViewModel()
    this.viewModel.collectionKey = props.match.params.collection
  }

  componentDidMount() {
    this.viewModel.update()
  }

  render() {
    return (
      <div>
        <div style={{ marginTop: '8px', marginLeft: '24px' }}>
          <Typography variant="h4">
            <Link
              style={{
                display: 'inline-block',
                verticalAlign: 'inherit',
                marginTop: '15px',
                marginRight: '15px',
                fontSize: '18px',
              }}
              to="/studies"
            >
              &#8592; Go back
            </Link>
          </Typography>
          <Typography variant="h4">
            Collection: {this.viewModel.name}
          </Typography>
        </div>

        <StudyAndCollectionList
          collection={this.props.match.params.collection}
        />
      </div>
    )
  }
}
