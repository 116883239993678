import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Icon, Label, Menu, Modal, Header, Button } from 'semantic-ui-react';

export default
@observer
class FixedBox extends Component {
  state = {
    modalOpen: false,
  };

  handleOpen = () => this.setState({ modalOpen: true });

  handleClose = () => this.setState({ modalOpen: false });

  handleDelete() {
    const { store } = this.props;
    this.handleClose();
    store.deleteSelected();
  }

  render() {
    const { store } = this.props;

    const count = store.count;
    const display = count > 0 ? 'block' : 'none';

    return (
      <div
        style={{
          position: 'fixed',
          backgroundColor: '#03A9F4',
          height: '80px',
          width: '300px',
          zIndex: '1000',
          top: 'calc(100% - 110px)',
          left: 'calc(100% - 310px)',
          padding: '10px',
          display,
        }}
      >
        <Modal
          open={this.state.modalOpen}
          onClose={this.handleClose}
          size="small"
        >
          <Header icon="trash" content="Delete" />
          <Modal.Content>
            <h3>Are you sure you want to delete selected items?</h3>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={this.handleClose}>Close</Button>
            <Button
              color="red"
              onClick={() => {
                this.handleDelete();
              }}
              inverted
            >
              <Icon name="trash" /> Delete
            </Button>
          </Modal.Actions>
        </Modal>

        <Menu
          compact
          style={{
            marginLeft: '9px',
            marginTop: '8px',
          }}
        >
          <Menu.Item as="a" onClick={this.handleOpen}>
            <Icon name="trash" /> Delete items
            <Label color="orange" floating>
              {count}
            </Label>
          </Menu.Item>
          <Menu.Item
            as="a"
            onClick={() => {
              store.clearAll();
            }}
          >
            Clear selections
          </Menu.Item>
        </Menu>
      </div>
    );
  }
}
