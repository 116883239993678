// @flow

/**
 * Representation of menu item.
 * @typedef {Object} MenuItemObject
 * @property {string} path - The path on which user will be moved on after selecting this menu item.
 * @property {string} text - Text representation of menu item to be displayed in user interface.
 */

/**
 * Array of menu items for user part of system.
 *
 * @type Array.<MenuItemObject>
 */
const userMenu = [
  {
    requirePay: false,
    path: '/studies',
    text: 'Data',
  },
  // {
  //   requirePay: false,
  //   path: '/devices',
  //   text: 'Devices'
  // },
  // {
  //   requirePay: true,
  //   requireAllMenu: true,
  //   path: '/processes',
  //   text: 'Processes'
  // },
  // {
  //   requirePay: true,
  //   requireAllMenu: true,
  //   path: '/qc',
  //   text: 'Quality Controls'
  // },
  // {
  //   requirePay: true,
  //   requireAllMenu: true,
  //   path: '/rstudio',
  //   text: 'Analysis'
  // },
  // {
  //   requirePay: true,
  //   requireAllMenu: true,
  //   path: '/reports',
  //   text: 'Reports',
  // },
  {
    requirePay: false,
    path: '/organization',
    text: 'Organization',
  },
  // {
  //   requirePay: false,
  //   path: '/account',
  //   text: 'DCSUnity Account',
  // },
  {
    requirePay: false,
    path: '/licenses',
    text: 'DCSLinkStream Licenses',
  },
  {
    requirePay: false,
    path: '/logout',
    text: 'Log Out',
  },
]

export default userMenu
