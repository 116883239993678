export const toolbarStyles = theme => ({
  root: {
    paddingRight: 2,
  },
  highlight: {
    color: theme.palette.secondary.A100,
    backgroundColor: "#8cd200",
  },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
});
