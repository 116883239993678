import React from 'react'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import SelectedRemoveComponent from './RemoveFromCollectionComponent'
import { toolbarStyles } from '../toolbarStyles'
import DeleteFeature from '../DeleteFeature'

const EnhancedTableToolbar = (props) => {
  const { selected, classes, listModel } = props

  const numSelected = selected.length

  return (
    <Toolbar
      className={classNames(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.title}>
        {numSelected > 0 ? (
          <div style={{ color: 'white', fontSize: '1.2em' }}>
            {numSelected} selected
          </div>
        ) : (
          <Typography variant="h5">Studies</Typography>
        )}
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        {numSelected > 0 ? (
          <div style={{ width: '300px' }}>
            <DeleteFeature selectedItems={selected} listModel={listModel} />
            <SelectedRemoveComponent
              selectedItems={selected}
              listModel={listModel}
            />
          </div>
        ) : null}
      </div>
    </Toolbar>
  )
}

export default withStyles(toolbarStyles)(EnhancedTableToolbar)
