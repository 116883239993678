import React, { Component } from 'react';

export default class StudiesComponent extends Component
{
  render()
  {
    return (
      <div>
        {this.props.children}
      </div>
    );
  }
}