// @flow
import React from "react";
import { Provider } from "mobx-react";
import RecordsNumStudyStore from './RecordsNumStudyStore';
import RecordsNumCollectionStore from './RecordsNumCollectionStore';
import RecordsNumStudyShareStore from './RecordsNumStudyShareStore';
import RecordsNumComponent from './RecordsNumComponent';

type PropTypes = {
  studyId: ?string,
  collectionId: ?string,
  shareId: ?string,
};

const map = new Map();

const defineStoreByProps = (props : PropTypes) => {
  if(props.studyId) {
    if(!map.has(`study::${props.studyId}`)) {
      const store = new RecordsNumStudyStore(props.studyId);
      map.set(`study::${props.studyId}`, store);
      return store;
    } else {
      return map.get(`study::${props.studyId}`);
    }
  }

  if(props.collectionId) {
    if(!map.has(`collection::${props.collectionId}`)) {
      const store = new RecordsNumCollectionStore(props.collectionId);
      map.set(`collection::${props.collectionId}`, store);
      return store;
    } else {
      return map.get(`collection::${props.collectionId}`);
    }
  }

  if(props.shareId) {
    if(!map.has(`share::${props.shareId}`)) {
      const store = new RecordsNumStudyShareStore(props.shareId);
      map.set(`share::${props.shareId}`, store);
      return store;
    } else {
      return map.get(`share::${props.shareId}`);
    }
  }
};

const RecordsNumFeature = (props : PropTypes) => (
  <Provider recordsNumStore={defineStoreByProps(props)} >
    <RecordsNumComponent />
  </Provider>
);

export default RecordsNumFeature;
