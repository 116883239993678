// @flow
import React from 'react';
import {Label, Loader} from "semantic-ui-react";
import { observer, inject } from "mobx-react";

@inject('recordsNumStore')
@observer
class RecordsNumComponent extends React.Component
{
  componentDidMount() {
    this.props.recordsNumStore.update();
  }

  render () {
    const { recordsNumStore } = this.props;

    if(recordsNumStore.isLoading) {
      return <Loader active inline size="tiny" />;
    }

    return (
      <Label as='a' basic color='blue'>
        {recordsNumStore.recordsNum}
      </Label>
    );
  }
}

export default RecordsNumComponent;
