import { observable, transaction, computed } from 'mobx'
import { firebaseApp } from 'src/config/firebase'

/**
 * Model which loads registered users list from database.
 */
class ListModel {
  @observable _isLoaded = false
  @observable _organization = []
  @observable _users = []

  _sort = ''
  _desc = false

  /**
   * Whether data was loaded from database.
   * @returns {boolean} `true` if data has been already loaded from database, `false` otherwise.
   */
  @computed get isLoaded() {
    return this._isLoaded
  }

  /**
   * Gets users loaded from database.
   *
   * @returns {Object[]} Array with loaded users.
   */
  @computed get organization() {
    return this._organization.slice()
  }

  /**
   * Sorts user list.
   *
   * @param {String} field Field to sort.
   */
  sort(field) {
    if (field === this._sort) {
      this._desc = !this._desc
    } else {
      this._sort = field
      this._desc = false
    }

    const multiple = this._desc ? -1 : 1

    const orgs = this.organization
    this._users.replace(
      this._users.sort((a, b) => {
        let ac = a[field]
        let bc = b[field]

        if (field === 'organisation') {
          const orgA = orgs.find((item) => item.key === ac)
          const orgB = orgs.find((item) => item.key === bc)

          ac = orgA ? orgA.name : ''
          bc = orgB ? orgB.name : ''
        }

        ac = ac === undefined ? -1 : ac
        bc = bc === undefined ? -1 : bc

        return ac > bc ? multiple : -multiple
      })
    )
  }

  /**
   * Gets users loaded from database.
   *
   * @returns {Object[]} Array with loaded users.
   */
  @computed get users() {
    return this._users.slice()
  }

  /**
   * Fetches registered users data from database.
   */
  update(organisationId) {
    this._isLoaded = false

    firebaseApp
      .database()
      .ref('/users')
      .once('value')
      .then((snapshot) => {
        const usersList = []

        snapshot.forEach((childSnapshot) => {
          const childData = childSnapshot.val()
          if (!organisationId || childData.organisation === organisationId) {
            childData.key = childSnapshot.key
            usersList.push(childData)
          }
        })

        firebaseApp
          .database()
          .ref('/organisations')
          .once('value')
          .then((snapshotOrg) => {
            const orgList = []

            snapshotOrg.forEach((childSnapshot) => {
              const childData = childSnapshot.val()
              childData.members = childSnapshot.child('users').numChildren()
              childData.key = childSnapshot.key
              orgList.push(childData)
            })

            transaction(() => {
              this._users = usersList
              this._organization = orgList
              this._isLoaded = true
            })
          })
      })
  }
}

export default ListModel
