// @flow
import axiosCore from 'axios'

const API_URL = process.env.REACT_APP_API_URL
  ? process.env.REACT_APP_API_URL
  : '/api'

const axios = axiosCore.create({
  timeout: 30000,
  baseURL: API_URL,
})

axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem('token')
  config.headers.Authorization = token
  return config
})

export default axios
