import React, { Component } from 'react'
import { observer } from 'mobx-react'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import CircularProgress from '@material-ui/core/CircularProgress'
import SettingsModel from '../../../Models/Admin/Settings/SettingsModel'

@observer
class SettingsComponent extends Component {
  constructor(props) {
    super(props)

    this.settingsModel = new SettingsModel()
  }

  componentDidMount() {
    this.settingsModel.update(this.props.userId)
  }

  render() {
    if (!this.settingsModel.isLoaded) {
      return <CircularProgress />
    }

    const styles = {
      block: {
        maxWidth: 310,
      },
    }

    return (
      <div>
        <div style={styles.block}>
          <FormControlLabel
            label="LinkStream Sync On"
            control={
              <Switch
                color="primary"
                checked={this.settingsModel.isSync}
                onChange={(event, value) => {
                  this.settingsModel.isSync = value
                  window.location.reload()
                }}
              ></Switch>
            }
          />
        </div>
      </div>
    )
  }
}

export default SettingsComponent
