import { computed, observable } from 'mobx'
import { firebaseApp } from 'src/config/firebase'

import UserModel from 'src/Models/UserModel'

/**
 * Model for user authentication management.
 */
class AuthModel {
  /**
   * Whether current user has admin rights.
   * Equals 'null' until value is loaded from database.
   *
   * @type {boolean|null}
   * @private
   */
  _isAdmin = null

  @observable
  _isLoggedIn = false

  @observable
  _firebaseUser = null

  // used to know when a user's authentication status has been checked (for loading screen)
  @observable
  _initialized = false

  constructor() {
    // listen to auth state changes and update user & auth data when user is logged in
    firebaseApp.auth().onAuthStateChanged(async (firebaseUser) => {
      if (firebaseUser) {
        try {
          const token = await firebaseUser.getIdToken()
          localStorage.setItem('token', token)
          await this.permissionLoad()
          await UserModel.updateData()

          this._isLoggedIn = true
        } catch (err) {
          console.error(err)
        } finally {
          this._initialized = true
        }
      } else {
        this.reset()
        this._initialized = true
      }
    })
  }

  /**
   * Logs in user with email and password in firebaseApp.
   *
   * @param email - Email for authentication.
   * @param password - Password for authentication.
   * @returns {firebaseApp.Promise<any>} Firebase promise.
   */
  signIn(email, password) {
    return firebaseApp.auth().signInWithEmailAndPassword(email, password)
  }

  /**
   * Creates new user in firebaseApp.
   *
   * @param email - Email for authentication.
   * @param password - Password for authentication.
   * @param firebaseInstance - Firebase instance in which to create new user.
   * Use instance that differs from current because firebase kicks out current user on new user creation.
   * {@link http://stackoverflow.com/questions/37517208/firebase-kicks-out-current-user}
   * @returns {firebaseApp.Promise<any>} Firebase promise.
   */
  signUp(email, password, firebaseInstance) {
    const database = firebaseInstance || firebaseApp
    return database.auth().createUserWithEmailAndPassword(email, password)
  }

  /**
   * Whether user is logged in.
   *
   * @returns {boolean}
   */
  @computed get isLoggedIn() {
    return this._isLoggedIn
  }

  /**
   * Whether user is logged in.
   *
   * @returns {boolean}
   */
  @computed get initialized() {
    return this._initialized
  }

  /**
   * Whether user email is verified.
   *
   * @returns {boolean}
   */
  get isEmailVerified() {
    return firebaseApp.auth().currentUser.emailVerified
  }

  /**
   * Returns an email of currently logged in user.
   * 'null' if there is no logged in user.
   *
   * @returns {String|null}
   */
  get userEmail() {
    return this.isLoggedIn ? firebaseApp.auth().currentUser.email : null
  }

  /**
   * Whether current user has admin rights.
   * 'null' if value is hasn't been loaded yet from database.
   *
   * @returns {boolean|null}
   */
  get isAdmin() {
    return this._isAdmin
  }

  /**
   * Add authentication state observer.
   *
   * @param cb Callback to be called on authentication state changes.
   * @returns {function()} Unsubscribe function.
   */
  onAuthStateChanged(cb) {
    return firebaseApp.auth().onAuthStateChanged(cb)
  }

  /**
   * Loads current user permissions from database.
   *
   * @returns {!firebaseApp.Promise.<*>} Promise which resolves after loading process completes.
   */
  async permissionLoad() {
    const snapshot = await firebaseApp
      .database()
      .ref('/admins/' + firebaseApp.auth().currentUser.uid)
      .once('value')
    this._isAdmin = !!snapshot.val()
  }

  reset() {
    localStorage.removeItem('token')
    this._isAdmin = null
    this._isLoggedIn = false
  }

  /**
   * Signs out current user from firebaseApp.
   *
   * @returns {!firebaseApp.Promise.<void>|firebaseApp.Promise<any>}
   */
  signOut() {
    firebaseApp.auth().signOut()
  }
}

export default new AuthModel()
