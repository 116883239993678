import React, { Component } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import DevicesListObjectModel from 'src/Models/Device/ListObjectModel'
import { observer } from 'mobx-react'
import { history } from 'src/config/routing/history'
import { Button, Table } from 'semantic-ui-react'
import dateFormat from 'dateformat'
import CustomerModel from 'src/Models/Payment/Stripe/CustomerModel'
import { firebaseApp } from 'src/config/firebase'
import DeviceInfo from '../Admin/User/Edit/Device/DeviceInfo'

export default
@observer
class UserComponent extends Component {
  customerModel = null
  deviceModel = null
  hasCustomerId = true

  constructor(props) {
    super(props)

    this.customerModel = new CustomerModel()
    this.deviceModel = new DevicesListObjectModel()
  }

  componentDidMount() {
    const { userID } = this.props

    this.deviceModel.update(userID)

    firebaseApp
      .database()
      .ref('/users/' + userID + '/stripeCustomerId/')
      .once('value')
      .then((snapshot) => {
        const val = snapshot.val()

        if (val) {
          this.customerModel.loadData(val)
        } else {
          this.hasCustomerId = false
          this.forceUpdate()
        }
      })
  }

  handleClickRow(key) {
    if (this.props.isOwn) {
      history.push(`/devices/${key}`)
    }
  }

  render() {
    if (
      !this.deviceModel.isLoaded ||
      (!this.customerModel.isLoaded && this.hasCustomerId)
    ) {
      return <CircularProgress />
    }

    const { isOwn } = this.props
    const devices = this.deviceModel.devices

    if (devices.length === 0) {
      return <div>No licenses</div>
    }

    const rows = devices.map((device) => {
      const deviceInfo = new DeviceInfo(device.activationCode, device.id)
      const trialDate = deviceInfo.trial_date
      const untilActivatedDate = deviceInfo.until_activated

      let isTrial = false
      let isManual = false

      if (trialDate) {
        isTrial = trialDate > new Date().getTime()
      }

      if (untilActivatedDate) {
        isManual = untilActivatedDate > new Date().getTime()
      }

      let status = 'Not-paid'
      let interval = ''
      let periodText = ''
      const subscription =
        this.hasCustomerId &&
        device.subscriptionId &&
        this.customerModel.getSubscriptionById(device.subscriptionId)

      if (subscription) {
        status = subscription.status
        interval = subscription.plan.interval

        periodText = dateFormat(
          new Date(subscription['current_period_end'] * 1000),
          'dd mmmm yyyy'
        )

        if (subscription['cancel_at_period_end']) {
          periodText += ' (Will be cancelled)'
        }
      } else if (isTrial) {
        interval = 'Trial'
        periodText = dateFormat(new Date(trialDate), 'dd mmmm yyyy')
      } else if (isManual) {
        interval = 'Set Manually'
        periodText = dateFormat(new Date(untilActivatedDate), 'dd mmmm yyyy')
      }

      return (
        <Table.Row
          key={device.key}
          positive={!!subscription || !!isTrial}
          onClick={() => this.handleClickRow(device.key)}
          style={{ cursor: 'pointer' }}
        >
          <Table.Cell>{device.id}</Table.Cell>
          <Table.Cell>{status}</Table.Cell>
          <Table.Cell>{interval}</Table.Cell>
          <Table.Cell>{periodText}</Table.Cell>
          {isOwn && (
            <Table.Cell>
              <Button primary>Manage License</Button>
            </Table.Cell>
          )}
        </Table.Row>
      )
    })

    return (
      <div>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Device ID</Table.HeaderCell>
              <Table.HeaderCell>Status</Table.HeaderCell>
              <Table.HeaderCell>Current plan</Table.HeaderCell>
              <Table.HeaderCell>Expiration date</Table.HeaderCell>
              {isOwn && (
                <Table.HeaderCell>
                  <div />
                </Table.HeaderCell>
              )}
            </Table.Row>
          </Table.Header>

          <Table.Body>{rows}</Table.Body>
        </Table>
      </div>
    )
  }
}
