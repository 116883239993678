// @flow
import {observable, computed, transaction} from 'mobx';
import AbstractStore from "../AbstractStore";
import { getRecordsNumFromCollection } from '../helpers';

class RecordsNumCollectionStore extends AbstractStore
{
  @observable _recordsNum : number = 0;
  _collectionId : string;

  constructor(collectionId : string) {
    super();
    this._collectionId = collectionId;
  }

  @computed
  get recordsNum() : number {
    return this._recordsNum;
  }

  async update() {
    this.isLoading = true;

    const num = await getRecordsNumFromCollection(this._collectionId);

    transaction(() => {
      this.isLoading = false;
      this._recordsNum = num;
    });
  }
}

export default RecordsNumCollectionStore;
