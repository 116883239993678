import { observable, transaction, computed } from 'mobx'
import { firebaseApp } from 'src/config/firebase'

class ListModel {
  @observable _loaded = false
  @observable _promos = []

  @computed get isLoaded() {
    return this._loaded
  }

  @computed get promos() {
    return this._promos.slice()
  }

  update() {
    this._loaded = false

    firebaseApp
      .database()
      .ref('/promos')
      .once('value')
      .then((snapshot) => {
        let promosList = []

        snapshot.forEach((childSnapshot) => {
          let childData = childSnapshot.val()
          childData.key = childSnapshot.key
          promosList.push(childData)
        })

        transaction(() => {
          this._promos = promosList
          this._loaded = true
        })
      })
  }

  change(key, data) {
    const ref = firebaseApp.database().ref('/promos/' + key)
    ref.update(data).then(() => this.update())
  }

  create(data) {
    const newRef = firebaseApp.database().ref('/promos').push()
    newRef.set(data).then(() => this.update())
  }

  createDefault() {
    const data = {
      code: 'promocode',
      discountAmount: '1.01',
      discountPercent: '5',
      maxApply: '10',
      endDate: 0,
    }

    this.create(data)
  }
}

export default ListModel
