import React, { Component } from 'react'
import Typography from '@material-ui/core/Typography'
import { Button, Table } from 'semantic-ui-react'
import { observer } from 'mobx-react'
import ElementComponent from './ElementComponent'
import PromoListModel from '../../../Models/Promo/PromoModel'
import CircularProgress from '@material-ui/core/CircularProgress'

@observer
class ListComponent extends Component {
  constructor(props) {
    super(props)
    this.listModel = new PromoListModel()
  }

  componentDidMount() {
    this.listModel.update()
  }

  createDefault() {
    this.listModel.createDefault()
  }

  render() {
    if (!this.listModel.isLoaded) return <CircularProgress />

    return (
      <div>
        <Typography variant="h4">Promo management page</Typography>

        <div style={{ margin: '16px' }}>
          <Button
            onClick={() => this.createDefault()}
            icon="plus"
            color="green"
            content="Add new promo-code"
            labelPosition="left"
          />
          <Table columns={5}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Code</Table.HeaderCell>
                <Table.HeaderCell>$ Discount</Table.HeaderCell>
                <Table.HeaderCell>% Discount</Table.HeaderCell>
                <Table.HeaderCell>Expiration</Table.HeaderCell>
                <Table.HeaderCell># max</Table.HeaderCell>
                {/*<Table.HeaderCell># applied</Table.HeaderCell>*/}
                {/*<Table.HeaderCell>$ total</Table.HeaderCell>*/}
                {/*<Table.HeaderCell>Status</Table.HeaderCell>*/}
                <Table.HeaderCell>&nbsp;</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {this.listModel.promos.map((data) => (
                <ElementComponent
                  key={data.key}
                  data={data}
                  model={this.listModel}
                />
              ))}
            </Table.Body>

            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell>
                  {this.listModel.promos.length} Codes
                </Table.HeaderCell>
                <Table.HeaderCell />
                <Table.HeaderCell />
                <Table.HeaderCell />
                <Table.HeaderCell />
                <Table.HeaderCell />
              </Table.Row>
            </Table.Footer>
          </Table>
        </div>
      </div>
    )
  }
}

export default ListComponent
