import { observable, transaction, computed } from 'mobx'
import UserModel from '../../UserModel'
import { firebaseApp } from 'src/config/firebase'
import transactionType from '../../../config/transactionType'
import {
  getCustomerInvoices,
  getUpcomingCustomerInvoices,
} from 'src/services/stripe'

/**
 * Represents Stripe customer object.
 *
 * @see http://stripe.com
 */
class InvoiceModel {
  /**
   * Whether data was loaded form Stripe.
   *
   * @type {boolean}
   * @private
   */
  @observable _loaded = false

  /**
   * Whether data was loaded form Stripe.
   *
   * @type {String|null}
   * @private
   */
  @observable _subscriptionId = null

  /**
   * Stripe invoices object.
   *
   * @type {[{}]|null}
   * @private
   * @see https://stripe.com/docs/api#invoice_object
   */
  _invoiceList = []

  /**
   * Stripe upcoming invoice.
   *
   * @type {[{}]|null}
   * @private
   * @see https://stripe.com/docs/api#upcoming_invoice
   */
  _upcomingInvoiceList = []

  /**
   * A list of manual transaction for the current user.
   *
   * @type {[{}]}
   * @private
   */
  _manualTransactionList = []

  set subscriptionId(subscriptionId) {
    this._subscriptionId = subscriptionId
  }

  /**
   * Loads manual transactions to {@link InvoiceModel._manualTransactionList}.
   *
   * @returns {firebaseApp.Promise<any>}
   */
  loadManualTransaction() {
    return firebaseApp
      .database()
      .ref('/users/' + UserModel.userId + '/transactions/')
      .once('value')
      .then((snapshot) => {
        const result = []

        snapshot.forEach((childSnapshot) => {
          const childData = childSnapshot.val()

          result.push({
            date: childData.startDate / 1000,
            paid: true,
            type: transactionType.getTitleById(childData.typeId),
          })
        })

        this._manualTransactionList = result
      })
  }

  /**
   * Loads list of invoices for current customer.
   */
  async loadData() {
    this._loaded = false
    try {
      if (this._subscriptionId === null) {
        await this.loadManualTransaction()
        transaction(() => {
          this._invoiceList = []
          this._upcomingInvoiceList = []
          this._loaded = true
        })
        return
      }

      const { data: invoices } = await getCustomerInvoices(
        UserModel.stripeCustomerId
      )
      this._invoiceList = invoices

      const { data: upcomingInvoices } = await getUpcomingCustomerInvoices(
        UserModel.stripeCustomerId
      )
      this._upcomingInvoiceList = Array.isArray(upcomingInvoices)
        ? upcomingInvoices
        : [upcomingInvoices]
    } catch (err) {
      return Promise.reject(err)
    } finally {
      this._loaded = true
    }
  }

  /**
   * List of Stripe invoices for current customer.
   *
   * @returns {[{}]}
   * @see https://stripe.com/docs/api#invoice_object
   */
  get invoiceList() {
    return this._invoiceList || []
  }

  /**
   * List of Stripe upcoming invoices for current customer.
   *
   * @returns {[{}]}
   * @see https://stripe.com/docs/api#upcoming_invoice
   */
  get upcomingInvoiceList() {
    return this._upcomingInvoiceList || []
  }

  /**
   * List of manual transaction for the current user.
   *
   * @returns {[{}]}
   */
  get manualTransactionList() {
    return this._manualTransactionList
  }

  /**
   * Whether data was loaded form Stripe.
   *
   * @returns {boolean}
   */
  @computed get isLoaded() {
    return this._loaded
  }
}

export default new InvoiceModel()
