import { firebaseApp } from 'src/config/firebase'
import authModel from '../Models/AuthModel'
import * as Promise from 'promise/src/es6-extensions'
import role from '../config/role'
import CustomerModel from '../Models/Payment/Stripe/CustomerModel'

/**
 * Represents currently logged in user
 */
class UserModel {
  /**
   * ID of user database.
   * Equals 'null' until value is loaded from database.
   * If user doesn't have database it will be empty string.
   *
   * @type {string|null}
   * @private
   */
  _databaseId = null

  /**
   * Current user firebase record.
   *
   * @type {{}|null}
   * @private
   */
  _userData = null

  /**
   * Stripe customer model.
   *
   * @type {CustomerModel}
   * @private
   */
  _stripeCustomer = null

  /**
   * Whether current user is administrator.
   *
   * @returns {boolean}
   */
  get isAdmin() {
    return authModel.isAdmin
  }

  /**
   * Whether current user is owner.
   *
   * @returns {boolean}
   */
  get isOwner() {
    return this._userData !== null && this._userData.role === role.owner.id
  }

  /**
   * Whether current user is organisation manager.
   *
   * @returns {boolean}
   */
  get isOrgManager() {
    return (
      this._userData !== null && this._userData.role === role.billingManager.id
    )
  }

  /**
   * Whether current user can manage organization.
   *
   * @returns {boolean}
   */
  get canManageOrganization() {
    return this.isOwner || this.isOrgManager
  }

  /**
   * Whether current user has all menu items.
   *
   * @returns {boolean}
   */
  get hasAllMenu() {
    // TODO temporary promo
    return true
    // return this._userData !== null && this._userData.hasMenu
  }

  /**
   * Gets current user role ID.
   *
   * @returns {number|null} Role ID.
   */
  get role() {
    return this._userData && this._userData.role
  }

  /**
   * Sets current user role ID.
   *
   * @param {number|null} id Role ID.
   */
  set role(id) {
    this._userData.role = id
  }

  constructor() {
    this._stripeCustomer = new CustomerModel()
  }

  /**
   * Gets database ID for current user.
   * If user doesn't have database it will return an empty string.
   * 'null' if value is hasn't been loaded yet from database.
   *
   * @returns {string|null}
   */
  get databaseId() {
    return this._databaseId
  }

  /**
   * Gets Stripe customer model.
   *
   * @returns {CustomerModel}
   */
  get stripeCustomer() {
    return this._stripeCustomer
  }

  /**
   * Gets organisation id.
   *
   * @returns {String|null}
   */
  get organisationId() {
    return this._userData?.organisation || null
  }

  /**
   * Returns first name.
   *
   * @returns {String}
   */
  get firstName() {
    return (this._userData && this._userData.firstName) || ''
  }

  /**
   * Returns last name.
   *
   * @returns {String}
   */
  get lastName() {
    return (this._userData && this._userData.lastName) || ''
  }

  get isSync() {
    if (!this._userData) return false
    if (!this._userData.hasOwnProperty('sync')) return false
    return this._userData.sync
  }

  /**
   * Sets organisation id.
   *
   * @param {String|null} id
   */
  set organisationId(id) {
    this._userData.organisation = id
  }

  /**
   * Loads all data.
   * - user data from firebaseApp   * - stripe customer data
   *
   * @returns {firebaseApp.Promise<any>}
   */
  async loadData() {
    // if (!authModel.isLoggedIn) {
    //   return Promise.resolve()
    // }

    const snapshot = await firebaseApp
      .database()
      .ref('/users/' + this.userId)
      .once('value')

    this._userData = snapshot.val()
    await this.stripeCustomer.loadData()
  }

  /**
   * Gets current user ID.
   * Returns 'null' if there is no user authenticated.
   *
   * NOTE: It is allowed substitute UID via ENV variable - REACT_APP_FIREBASE_UID.
   * This allows to work with DB backup on custom firebase instance either from production or test server DB
   *
   * @returns {string|null}
   */
  get userId() {
    return (
      process.env.REACT_APP_FIREBASE_UID ||
      (firebaseApp.auth().currentUser && firebaseApp.auth().currentUser.uid)
    )
  }

  /**
   * Gets current user email.
   * Returns 'null' if there is no user authenticated.
   *
   * @returns {string|null}
   */
  get userEmail() {
    return (
      firebaseApp.auth().currentUser && firebaseApp.auth().currentUser.email
    )
  }

  /**
   * Sets stripe customer id.
   *
   * In case when used is newly registered there is no customer ID loaded from firebase,
   * so we need capability to update it manually to get rid of useless data reload form firebaseApp.
   *
   * @param {String|null} stripeCustomerId Stripe customer id.
   */
  set stripeCustomerId(stripeCustomerId) {
    this._userData['stripeCustomerId'] = stripeCustomerId
    firebaseApp
      .database()
      .ref('/users/' + this.userId)
      .update({ stripeCustomerId })
  }

  set subscriptionId(subscriptionId) {
    firebaseApp
      .database()
      .ref('/users/' + this.userId)
      .update({ subscriptionId })
      .then(() => {
        this._userData['subscriptionId'] = subscriptionId
      })
  }

  get subscriptionId() {
    return (this._userData && this._userData.subscriptionId) || null
  }

  /**
   * Returns stripe customer ID.
   *
   * @return {String|null}
   */
  get stripeCustomerId() {
    if (
      this._userData !== null &&
      this._userData.hasOwnProperty('stripeCustomerId')
    )
      return this._userData.stripeCustomerId

    return null
  }

  /**
   * Loads database id for current user.
   *
   * @returns {!firebaseApp.Promise.<*>} Promise which resolves after loading process completes.
   */
  async databaseIdLoad() {
    // if (!authModel.isLoggedIn) return Promise.resolve()

    try {
      const snapshot = await firebaseApp
        .database()
        .ref('/users/' + this.userId + '/devices')
        .once('value')

      this._databaseId = ''

      snapshot.forEach((childSnapshot) => {
        const device = childSnapshot.val()
        if (device.type === 'ANDROID') {
          this._databaseId = device.databaseId
        }
      })
    } catch (err) {
      this._databaseId = null
      console.error(err)
      throw new Error(err)
    }
  }

  /**
   * Updates user data with actual values from database.
   *
   * @returns {Promise} Promise which resolves when all data is loaded.
   */
  updateData() {
    const promises = []

    promises.push(this.loadData())
    promises.push(this.databaseIdLoad())

    return Promise.all(promises)
  }

  /**
   * Resets user data.
   */
  reset() {
    this._databaseId = null
    this._userData = null
    this._stripeCustomer = new CustomerModel()
  }
}

export default new UserModel()
