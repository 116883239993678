// @flow

import CryptoJs from 'crypto-js';

/**
 * Class to encrypt and decrypt the device code.
 */
class Crypt {
  /**
   * AES options.
   *
   * @type {{mode: *, padding: *}}
   * @private
   */
  static _options = {
    mode: CryptoJs.mode.ECB,
    padding: CryptoJs.pad.Pkcs7
  };

  /**
   * Converts device ID to SHA256.
   *
   * @param {String} deviceId
   * @returns {*}
   * @private
   */
  static _deviceIdSha(deviceId) {
    return CryptoJs.SHA256(deviceId);
  }

  /**
   * Encrypt code via next rules: BASE64( AES( string, SHA(deviceId) ) ).
   *
   * @param {String} string
   * @param {String} deviceId
   * @returns {string}
   */
  static encrypt(string,deviceId) {
    const AESResult = CryptoJs.AES.encrypt(string,Crypt._deviceIdSha(deviceId),this._options);
    return AESResult.toString();
  }

  /**
   * Decrypts code to special string with activation parameters.
   *
   * @param {String} code
   * @param {String} deviceId
   * @returns {string}
   */
  static decrypt(code,deviceId) {
    const decryptResult = CryptoJs.AES.decrypt(code,Crypt._deviceIdSha(deviceId),this._options);
    return decryptResult.toString(CryptoJs.enc.Utf8);
  }
}

/**
 * Contains information about device and activation code.
 */
export default class DeviceInfo {
  /**
   * Until activated date in milliseconds.
   *
   * @type {number|null}
   */
  until_activated = null;

  /**
   * Activation date in milliseconds.
   *
   * @type {number|null}
   */
  activation_date = null;

  /**
   * Trial date in milliseconds.
   *
   * @type {number|null}
   */
  trial_date = null;

  /**
   * Determines current device is activated.
   *
   * @type {boolean|null}
   */
  activated = null;

  /**
   * Final activation code.
   *
   * @type {string}
   */
  activationCode = '';

  /**
   * Device ID.
   *
   * @type {string}
   */
  deviceId = '';

  /**
   * Decrypts activation code and parse parameters.
   *
   * @param {string} activationCode
   * @param {string} deviceId
   */
  constructor(activationCode:string,deviceId:string) {
    this.activationCode = activationCode;
    this.deviceId = deviceId;
    const decrypt = Crypt.decrypt(activationCode,this.deviceId);

    const data = decrypt.split('|');

    if(data.length !== 4)
      return;

    this.until_activated = Number(data[0]);
    this.activation_date = Number(data[1]);
    this.trial_date = Number(data[2]);
    this.activated = data[3]==="True" || data[3]==="true";
  }

  /**
   * Encrypts parameters and saves new activation code.
   */
  encrypt() {
    const data = [
      this.until_activated && this.until_activated.toExponential(9),
      this.activation_date && this.activation_date.toExponential(9),
      this.trial_date && this.trial_date.toExponential(9),
      this.activated ? "True" : "False"
    ];

    this.activationCode = Crypt.encrypt(data.join('|'),this.deviceId);
  }

  /**
   * Sets new parameters and generates an activation code.
   *
   * @param {number} until_activated
   * @param {number} activation_date
   * @param {number} trial_date
   * @param {boolean} activated
   */
  setData(until_activated:number,activation_date:number,trial_date:number,activated:boolean) {
    this.until_activated = until_activated;
    this.activation_date = activation_date;
    this.trial_date = trial_date;
    this.activated = activated;

    this.encrypt();
  }

  /**
   * Sets new device ID and generates an activation code.
   *
   * @param {string} value
   */
  setDeviceId(value:string) {
    this.deviceId = value;
    this.encrypt();
  }
}

